import { useState, useEffect } from 'react';
import { ReactComponent as CoinsIcon } from '../../images/Icons/coins.svg';
import { ReactComponent as ModalIcon } from '../../images/Icons/happycoins_won.svg';
import HappyShopItemRowElement from '../../components/Coins/HappyShopItemRowElement';
import * as happyShopItemService from '../../api/happyShopItemService';
import React from 'react';
import { getUserTotalCoinsForCompany } from '../../api/coinService';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';


export default function HappyShopContainer(props) {
    const [rowElementItems, setRowElementItems] = useState([]);
    const [hasFinishedLoadingItems, setHasFinishedLoadingItems] = useState(false);
    const [areItemsAvailable, setAreItemsAvailable] = useState(false);
    const [userCoinAmount, setUserCoinAmount] = useState(null);

    useEffect(() => {

        getUserTotalCoinsForCompany(setUserCoinAmount)
            .then(coins => {
                setUserCoinAmount(coins);
            });
    }, []);


    useEffect(() => {
        if (userCoinAmount == null) {
            return;
        }

        happyShopItemService.getAllAvailableItems(handleItemsFill)
            .then(itemList => {
                handleItemsFill(itemList);
                setHasFinishedLoadingItems(true);
            });
    }, [userCoinAmount]);



    function handleItemsFill(itemList) {
        if (itemList == null || itemList.length === 0) {
            setAreItemsAvailable(false);
        } else {
            setAreItemsAvailable(true);
            renderItemRowElements(itemList);
        }
    }

    function renderItemRowElements(itemList) {

        if (itemList != null && itemList.length) {
            let itemRowElementList = [];
            itemList.map(item => {
                let isInStock = item.stockQuantity > 0;
                let canUserAfford = userCoinAmount >= item.price;

                itemRowElementList.push(
                    <HappyShopItemRowElement
                        key={item.id}
                        isInStock={isInStock}
                        userCoinAmount={userCoinAmount}
                        canUserAfford={canUserAfford}
                        itemId={item.id}
                        image={item.image}
                        name={item.name}
                        price={item.price}
                        shortDescription={item.shortDescription}
                    />);
            });
            setRowElementItems(itemRowElementList);
        }
    }

    function renderItemsCatalog() {
        return (
            hasFinishedLoadingItems
                ? <div className="CatalogList">
                    {rowElementItems}
                    {/* If there are no items available, show a message */}
                    {areItemsAvailable
                        ? null
                        : <div className="tempContent">
                            <div>
                                <span><ModalIcon /></span>
                                <div className="title">Trocar happycoins</div>
                                <div className="text">De momento não existem benefícios para poderes usar as tuas Happycoins</div>
                                <div className="soon">Continua a participar para ganhares mais Happycoins</div>
                            </div>
                        </div>}
                </div>
                : null
        );
    }

    return (
        <div className="HappyShop">
            {userCoinAmount != null
                ? <div className="MyHappyCoins">
                    <div className="txt">{props.translateFunc("GLOBAL_YOU_HAVE")}</div>
                    <div className="title">{userCoinAmount}</div>
                    <div><CoinsIcon /></div>
                </div>
                : null}

            {hasFinishedLoadingItems ? renderItemsCatalog() : <LoadingComponent />}

        </div>
    );
}