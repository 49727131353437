import React, { useEffect, useState } from 'react';
import MedalsHighlightsCategoryContainer from './MedalsHighlightsCategoryContainer';
import { getMedalCategoryHighlights, getMedalHighlightsThresholdTimeDays } from '../../api/userService';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import { generateRandomNumber } from '../../utils/utils';
import Carousel from 'react-elastic-carousel'
import { replaceURL } from '../../utils/utils';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


export default function MedalsHighlightsContainer(props) {
  const [medalCategoryHighlightData, setMedalCategoryHighlightData] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(-1);
  const [carouselComponent, setCarouselComponent] = useState(null);
  const [isTimeInfoVisible, setIsTimeInfoVisible] = React.useState(false);
  const [highlightsThresholdTimeDays, setHighlightsThresholdTimeDays] = React.useState();

  useEffect(() => {

    getMedalCategoryHighlights(handleHighlightData)
      .then(data => {
        handleHighlightData(data);

      });

    getMedalHighlightsThresholdTimeDays(setHighlightsThresholdTimeDays).then(time => {
      setHighlightsThresholdTimeDays(time);
    });


  }, [])

  useEffect(() => {
    if (carouselIndex !== -1) {
      buildCarouselComponent();
    }

  }, [carouselIndex])

  function onCarouselChange(obj) {
    replaceURL(process.env.REACT_APP_ROUTING_MEDALS_HIGHLIGHTS + "/" + obj.item.categoryId);
  }

  function handleHighlightData(data) {
    setMedalCategoryHighlightData(data);

    if (props.categoryId !== 0) {
      let index = data.map(c => c.categoryId).indexOf(parseInt(props.categoryId));

      if (index >= 0) {
        setCarouselIndex(index);
      } else {
        setCarouselIndex(generateRandomNumber(data.length));
      }
    }
    else {

      setCarouselIndex(generateRandomNumber(data.length));
    }
  }

  function renderHighlightComponents() {
    let components = [];
    medalCategoryHighlightData.forEach(medalData => {
      components.push(
        <MedalsHighlightsCategoryContainer
          key={medalData.categoryId}
          categoryId={medalData.categoryId}
          categoryName={medalData.categoryName}
          topUsers={medalData.topUsers} />);
    });
    return components;
  }

  function buildCarouselComponent() {

    let c =
      <Carousel itemsToShow={1}
        initialActiveIndex={carouselIndex}
        onChange={onCarouselChange}
        className="MedalsHighlights">
        {renderHighlightComponents()}
      </Carousel>;
    setCarouselComponent(c);
  }

  function toggleTimeIntervalInfo() {
    setIsTimeInfoVisible(!isTimeInfoVisible);
  }

  return (
    <div className="MedalsHighlightsWrapper">
      {/*
        <div className="Nav">
          <a><ChevronLeftIcon/></a>
          <a><ChevronRightIcon/></a>
        </div>
        */}
      {medalCategoryHighlightData == null || medalCategoryHighlightData.length === 0
        ? <LoadingComponent></LoadingComponent>
        : carouselComponent
      }
      <div className="copy">
        <IconButton onClick={toggleTimeIntervalInfo}>
          <InfoOutlinedIcon />
        </IconButton>
        {isTimeInfoVisible
          ? <span>
              Os destaques apresentam resultados dos últimos <strong> {highlightsThresholdTimeDays} dias</strong>
            </span>
          : null}

      </div>

    </div>
  );
}