import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { generateGUID } from '../../utils/utils';
import { ReactComponent as HappyCoinIcon } from '../../images/Icons/happycoins_won.svg';


export default function MoodQtMultiple(props) {

    const [selectedAnswers, setSelectedAnswers] = React.useState(
        props.preselectedAnswer != null
            ? [...props.preselectedAnswer]
            : []);

    const [chips, setChips] = React.useState();
    //const [isFirstTimeRender, setIsFirstTimeRender] = React.useState(true);
    const [coinRewardOpacity, setCoinRewardOpacity] = React.useState('30%');


    const handleClick = (possibleAnswerId) => {


        // If the current selected answers already contain the clicked answer we remove it
        if (selectedAnswers.map(a => a.possibleAnswerId).includes(possibleAnswerId)) {
            let idIndex = selectedAnswers.map(a => a.possibleAnswerId).indexOf(possibleAnswerId);
            selectedAnswers.splice(idIndex, 1);
        } else {
            // If it does not contain the answer we check first if the original state had the answer.
            // If the BD contains this answer and we clicked we dont want to duplicate.
            let questionAnswerEntryId = 0;

            if (props.preselectedAnswer != null && props.preselectedAnswer.length) {
                if (props.preselectedAnswer.map(a => a.possibleAnswerId).includes(possibleAnswerId)) {
                    let idIndex = props.preselectedAnswer.map(a => a.possibleAnswerId).indexOf(possibleAnswerId);
                    questionAnswerEntryId = props.preselectedAnswer[idIndex].questionAnswerEntryId;
                }
            }

            let newAnswerObj = {
                questionAnswerEntryId: questionAnswerEntryId,
                possibleAnswerId: possibleAnswerId,
                questionId: props.questionId,
            }

            selectedAnswers.splice(0, 0, newAnswerObj);
        }

        let answersSet = new Set(selectedAnswers);
        let finalAnswersArray = [...answersSet];

        if (finalAnswersArray != null && finalAnswersArray.length) {

            setCoinRewardOpacity('100%');
        } else {
            setCoinRewardOpacity('30%');
        }

        setSelectedAnswers(finalAnswersArray);
        props.answerCallback(finalAnswersArray, true);
    };

    useEffect(() => {
        if (props.possibleAnswers) {
            buildChips();
            // TODO JS: reevaluate if bug behaviour still occurs after commenting the code
            // We call the callback on useEffect so that the multiple choices update on redux.
            // This is to fix a bug that happened when posting the array was going empty and thus deleting data.
            // if (isFirstTimeRender) {
            //     setIsFirstTimeRender(false);
            //     props.answerCallback(selectedAnswers, false);
            // }
        }
    }, [selectedAnswers])

    function buildChips() {
        let answers = [];
        if (props.possibleAnswers) {
            var answersPromise = props.possibleAnswers.map(answerObj => {
                answers.push(
                    <Chip
                        key={generateGUID()}
                        variant={selectedAnswers.map(a => a.possibleAnswerId).includes(answerObj.possibleAnswer.id)
                            ? 'outlined'
                            : 'default'}
                        size="medium"
                        icon={selectedAnswers.map(a => a.possibleAnswerId).includes(answerObj.possibleAnswer.id)
                            ? <CheckCircleIcon />
                            : <RadioButtonUncheckedIcon />}
                        label={answerObj.possibleAnswer.value}
                        onClick={() => handleClick(answerObj.possibleAnswer.id)}
                    />
                );
            });

            Promise.all([answersPromise]).then(() => {
                setChips(answers);
            })
        }
    }

    function renderCoinRewardSection() {
        return (
            <div className="Medals" style={{ opacity: coinRewardOpacity }}>
                <HappyCoinIcon />
                <div>{props.coinRewardForAnsweringQuestions}</div>
            </div>);
    }



    return (
        <div className="qtMultiple">
            <div className="txt">{props.questionText}</div>
            {chips}
            {renderCoinRewardSection()}
        </div>
    );
}