import React, { useEffect, useState } from "react";
import MoodInfluenceCategory from '../../components/Mood/MoodInfluenceCategory.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import * as influenceCategoryService from '../../api/influenceCategoryService';
import { connect } from 'react-redux';
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import { trackPromise } from 'react-promise-tracker';
import ModalsComments from '../../containers/_Global/ModalsComments';
import SearchBar from "../../components/_Global/SearchBar.jsx";
import { removeDiacritics } from '../../utils/utils';
import { getAllInfluenceEntriesForCategory, getPreviousInfluenceEntryListClone } from '../../api/influenceEntryService';


function MoodInfluenceCategoryContainer(props) {

    const [staticCategoriesAndInfluences, setStaticCategoriesAndInfluences] = React.useState();
    const [commentModalOpenned, setCommentModalOpenned] = useState(false);
    const [moodCommentData, setMoodCommentData] = useState();

    // The react list of elements built on base of categories and influences
    const [categoriesAndInfluencesElements, setCategoriesAndInfluencesElements] = React.useState([]);


    useEffect(() => {
        loadCategoriesAndInfluences();
    }, [])

    useEffect(() => {
        if (props.selectedDayOfTheWeekIndex != null
            && props.currentWeekMoods != null
            && props.currentWeekMoods[props.selectedDayOfTheWeekIndex] != null) {
            setMoodCommentData(props.currentWeekMoods[props.selectedDayOfTheWeekIndex].mood.moodCommentData);
        }
    }, [props.selectedDayOfTheWeekIndex])

    function buildInfEntryListMapped(infEntries, moodId) {
        let infEntryList = [];
        if (infEntries.length) {

            infEntries.map(ie => {
                if ((ie.mood !== 0 && ie.mood.id === moodId) || ie.mood.id === 0) {

                    let infEntry = {
                        id: ie.id,
                        moodId: ie.mood.id,
                        influenceId: ie.influence.id,
                        factorInfluenceCategoryId: ie.influence.factorCategories[0].influenceCategoryId,
                        rating: ie.rating,
                        commentText: ie.comment != null ? ie.comment.text : null
                    }

                    infEntryList.push(infEntry);
                }
            });
        }
        return infEntryList;
    }


    async function loadInfluenceEntriesForCategory(categoryId, influenceEntriesFromPreviousDay) {

        // Se if we have influence entries that were changed or created (REDUX)
        // So the selected option is showed instead of the value from the DB
        let currentMoodRedux = props.currentWeekMoods[props.selectedDayOfTheWeekIndex];

        let influenceEntriesFromRedux =
            currentMoodRedux != null
                ? currentMoodRedux.influenceEntries
                : [];

        let moodId = 0;
        if (currentMoodRedux != null) {
            moodId = currentMoodRedux.mood.id;
        }

        let influenceEntriesFromDB = [];
        // If the moodId == 0 then it means it's a new mood and we have nothing on the DB
        if (moodId !== 0 && moodId != null) {

            // Get the InfEntries from the DB

            influenceEntriesFromDB = await getAllInfluenceEntriesForCategory(
                categoryId,
                moodId,
                buildInfEntryListMapped
            )
                .then(infEntries => {
                    return buildInfEntryListMapped(infEntries, moodId);
                });
        }

        // Here we merge the infEntries from Redux VS BD
        let mergedInfluences = influenceEntriesFromRedux != null ? [...influenceEntriesFromRedux] : null;


        if (influenceEntriesFromDB.length) {
            let mappedReduxInfEntries = influenceEntriesFromRedux.map(i => i.id);

            // If we have Influence entries saved on the DB we will merge them withe the redux ones.
            // HOWEVER if they are the same InfEntry we prioritize the redux one.            
            for (let index = 0; index < influenceEntriesFromDB.length; index++) {
                const databaseInfEntry = influenceEntriesFromDB[index];

                if (!mappedReduxInfEntries.includes(databaseInfEntry.id)) {
                    mergedInfluences.push(databaseInfEntry);
                }
            }

            // Handle the influences from the previous day.
            // since they are clones and have Id = 0 we need to check for the influenceId
            // and ignore those which already exist
            if (influenceEntriesFromPreviousDay != null && influenceEntriesFromPreviousDay.length) {
                for (let index = 0; index < influenceEntriesFromPreviousDay.length; index++) {
                    const previousInfEntry = influenceEntriesFromPreviousDay[index];

                    if (!mergedInfluences.map(i => i.influenceId).includes(previousInfEntry.influenceId)) {

                        mergedInfluences.push(previousInfEntry);
                    }

                }

            }
        }

        return mergedInfluences;
    }

    function loadCategoriesAndInfluences() {
        if (props.currentUser == null || props.currentUser.currentAssociatedCompany == null) {
            return;
        }

        trackPromise(
            influenceCategoryService.getInfluenceCategoriesForSpecificCompany(
                props.currentUser.currentAssociatedCompany.id)
                .then(categories => {
                    setAndBuildCategories(categories);
                }));
    }

    function setAndBuildCategories(categories) {
        buildCategoriesElements(categories);
        setStaticCategoriesAndInfluences(categories);
    }

    /// when expanding a category we will load the influence entries
    const handleListItemExpandClick = (
        isOpen,
        hasLoadedInfluenceEntries,
        categoryId,
        callbackFunction,
        influenceEntriesFromPreviousDay) => {


        if (!isOpen) {
            if (!hasLoadedInfluenceEntries) {


                // If the InfEntries are not loaded yet we will fetch them.
                let entriesPromise = trackPromise(
                    loadInfluenceEntriesForCategory(categoryId, influenceEntriesFromPreviousDay));

                // Only call callback after the influence entries are loaded
                let moodId = props.currentWeekMoods[props.selectedDayOfTheWeekIndex].mood.id;
                Promise.all([entriesPromise]).then(influenceEntriesArray => {
                    props.updateInfluenceEntries(
                        props.selectedDayOfTheWeekIndex,
                        influenceEntriesArray[0],
                        moodId);

                    if (callbackFunction != null) {
                        callbackFunction(true, influenceEntriesArray[0]);
                    }

                })
            }
            else {
                // Just expand the list, no need to load everything again.
                if (callbackFunction != null) {

                    callbackFunction(!isOpen);
                }
            }
        }
        else {
            if (callbackFunction != null) {

                callbackFunction(!isOpen);
            }
        }
    };

    function buildCategoriesElements(categoriesAndInfluencesFinalList) {
        let mood = props.currentWeekMoods[props.selectedDayOfTheWeekIndex].mood;

        if (mood == null) {
            return;
        }

        if (categoriesAndInfluencesFinalList != null && categoriesAndInfluencesFinalList.length) {
            let influenceEntriesFromPreviousDay = [];

            setElements(categoriesAndInfluencesFinalList, null);

            if (mood == null
                || mood.id == null
                || mood.id === 0
                || mood.diaryDate == null) {
                return;
            } else {
                getPreviousInfluenceEntryListClone(mood.id, mood.diaryDate)
                    .then(infEntryList => {
                        if (infEntryList == null || infEntryList.length === 0) {
                            return;
                        } else {

                            infEntryList.map(i => {
                                influenceEntriesFromPreviousDay.push({
                                    id: i.id,
                                    moodId: i.mood != null ? i.mood.id : 0,
                                    influenceId: i.influence.id,
                                    rating: i.rating,
                                    commentText: i.comment != null ? i.comment.text : null,
                                    factorInfluenceCategoryId: i.influence.factorCategories[0].influenceCategoryId,
                                });
                            });

                            setElements(categoriesAndInfluencesFinalList, influenceEntriesFromPreviousDay);
                        }
                    });
            }
        }
    }

    function setElements(categoriesAndInfluencesFinalList, influenceEntriesFromPreviousDay) {
        setCategoriesAndInfluencesElements(categoriesAndInfluencesFinalList.map((category, index) => {
            return (
                <MoodInfluenceCategory
                    influenceEntriesFromPreviousDay={
                        influenceEntriesFromPreviousDay != null
                            ? influenceEntriesFromPreviousDay
                                .filter(infEntry => infEntry.factorInfluenceCategoryId === category.id)
                            : null}
                    handleListItemExpandClick={handleListItemExpandClick}
                    imageUrl={category.imageUrl}
                    categoryName={category.categoryName}
                    categoryId={category.id}
                    categoryIndex={index}
                    key={category.id}
                    factorCategories={category.factorCategories}
                />);
        }));
    }

    function filterCategoriesAndFactorsBasedOnSearchValue(searchValue) {

        if (!searchValue) {
            buildCategoriesElements(staticCategoriesAndInfluences);
            return;
        }

        let categoriesFilteredBySearch = [];
        searchValue = searchValue.toLowerCase();

        let newArray = staticCategoriesAndInfluences.slice();

        newArray.map((category, index) => {
            if (category != null && category.categoryName != null) {

                // We clone the object so it doesnt maintain a reference to the "staticCategoriesAndInfluences"
                // If we didnt, any change done to the category would reflect on the "staticCategoriesAndInfluences"
                let categoryClone = { ...category };

                let categoryNameWithoutDiacritics = removeDiacritics(categoryClone.categoryName);

                // Matcht the category name without diacritics
                let categoryNameMatchesSearch = categoryNameWithoutDiacritics.toLowerCase()
                    .includes(searchValue);

                let factorCategoriesThatMatchSearch = [];

                if (categoryClone.factorCategories != null && categoryClone.factorCategories.length) {

                    for (let index = 0; index < categoryClone.factorCategories.length; index++) {

                        const influenceFactor = categoryClone.factorCategories[index];
                        let influenceTitleWithoutDiacritics = removeDiacritics(influenceFactor.influence.title);

                        // Match the factor name without diacritics and add it to the array
                        if (influenceTitleWithoutDiacritics.toLowerCase().includes(searchValue)) {

                            factorCategoriesThatMatchSearch.push(influenceFactor);
                        }
                    }
                }

                // We can match against the category name or an influence factor name                
                if (categoryNameMatchesSearch || factorCategoriesThatMatchSearch.length !== 0) {

                    // If there is a match for category but not for a factor
                    // do not update the factors
                    if (!factorCategoriesThatMatchSearch.length === 0) {
                        categoryClone.factorCategories = factorCategoriesThatMatchSearch;
                    }

                    categoriesFilteredBySearch.push(categoryClone);
                }
            }
        });

        buildCategoriesElements(categoriesFilteredBySearch);
        return;
    }

    function handleSearchBarOnChange(value) {

        filterCategoriesAndFactorsBasedOnSearchValue(value);
    }

    const openCommentModal = () => {
        setCommentModalOpenned(true);
    };

    const closeCommentModal = (textValue, isAnonymous) => {
        let commentData = {
            textValue: textValue,
            isCommentAnonymous: isAnonymous
        };

        setMoodCommentData(commentData);

        props.updateMoodComment(props.selectedDayOfTheWeekIndex, commentData);

        setCommentModalOpenned(false);
    };

    return (
        <div>
            {commentModalOpenned
                ? <ModalsComments
                    subtitle={props.translateFunc("MOOD_WHAT_INFLUENCED_MOST")}
                    userName={props.currentUser.name}
                    profilePictureFullPath={props.currentUser.profilePictureFullPath}
                    preFilledValue={moodCommentData != null ? moodCommentData.textValue : ""}
                    isCommentAnonymous={moodCommentData != null ? moodCommentData.isCommentAnonymous : false}
                    onModalClosed={closeCommentModal} />
                : null}
            <SearchBar
                translateFunc={props.translateFunc}
                i18n={props.i18n}
                onChangeSearchBar={handleSearchBarOnChange} >
            </SearchBar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="moodCategoriesList">
                        <List dense={true}>
                            <ListItem className="influenceFirstLine">
                                <ListItemText primary={props.translateFunc("MOOD_WHAT_INFLUENCED_MOST")} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Open" onClick={openCommentModal}>
                                        {moodCommentData != null && moodCommentData.textValue.length
                                            ? <ChatBubbleIcon />
                                            : <ChatBubbleOutlineIcon />}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>

                            {categoriesAndInfluencesElements}
                        </List>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedDayOfTheWeekIndex: state.moodDetailReducer.selectedDayOfTheWeekIndex,
        currentWeekMoods: state.moodDetailReducer.currentWeekMoods,
        currentUser: state.userReducer.currentUser
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        onInfluenceCategorySelected: (categoryId, categoryName) => dispatch(
            moodDetailActions.onInfluenceCategorySelected(categoryId, categoryName)
        ),

        updateInfluenceEntries: (selectedDayOfTheWeekIndex, influenceEntries, moodId) => dispatch(
            moodDetailActions.updateInfluenceEntries(
                selectedDayOfTheWeekIndex,
                influenceEntries,
                moodId)
        ),
        updateInfluenceEntry: (selectedDayOfTheWeekIndex, influenceEntry) => dispatch(
            moodDetailActions.updateInfluenceEntry(selectedDayOfTheWeekIndex, influenceEntry)
        ),
        addInfluenceEntry: (selectedDayOfTheWeekIndex, influenceEntry) => dispatch(
            moodDetailActions.addInfluenceEntry(selectedDayOfTheWeekIndex, influenceEntry)
        ),
        updateMoodComment: (selectedDayOfTheWeekIndex, commentData) => dispatch(
            moodDetailActions.updateMoodcomment(selectedDayOfTheWeekIndex, commentData)
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoodInfluenceCategoryContainer);
