import React from 'react';
import { navIconStyle } from "../../css/globalStyles";

import { ReactComponent as AddCircleOutlineIcon } from '../../images/Icons/add.svg';
import IconButton from '@material-ui/core/IconButton';

export default function AssignAddWhiteIcon(props) {

  return (
    <IconButton edge="end" aria-label="Atribuir mérito" onClick={props.onPraiseAssignClick}>
      <AddCircleOutlineIcon style={{ ...navIconStyle.whiteColorTransparencyNoStroke, ...navIconStyle.svgSizeList }} />
    </IconButton>
  );
}