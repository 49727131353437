import * as actionNames from '../actionNames';

const initialState = {
    selectedNews: { id: 0, title: "", summary: "", messageContent: "", dateCreated: null}
}

const newsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionNames.SELECT_NEWS:
            return { selectedNews: action.newsObject }

        default:
            return state;

    }

};



export default newsReducer;