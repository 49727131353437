
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getUnansweredQuestionsFromActiveQuiz(isToGetAll, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_QUIZ_GET_QUESTIONS_ACTIVE_QUIZ, isToGetAll),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getQuestionEntriesForMood(moodId) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_QUESTION_ENTRY_GET_QUESTION_ENTRIES_SPECIFIC_MOOD, moodId),
        "GET")
        .then(response => response);
}

export function questionsExistForDay(day) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_QUIZ_GET_QUESTIONS_EXIST_TODAY, day),
        "GET")
        .then(response => response);
}


export function getCoinRewardForQuestionAnswered(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_QUESTION_GET_REWARD_QUESTIONS,
        "GET",
        null,
        cacheCallback)
        .then(response => {
            return response;

        });
}

export function submitQuestions(questionAnswerEntries) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_QUESTION_SUBMIT_QUESTIONS,
        "POST",
        questionAnswerEntries)
        .then(response => response);
}

export function hasUserAnsweredQuestionsToday() {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_QUESTION_HAS_USER_ANSWERED_TODAY,
        "GET")
        .then(response => response);
}

