import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import StarsIcon from '@material-ui/icons/Stars';
import PersonIcon from '@material-ui/icons/Person';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import SelectedUserAvatar from '../../images/Icons/NoPhoto.svg';
import { AzureAD, AuthenticationState } from 'react-aad-msal'
import { authProvider } from '../../auth/authProvider';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { calculateUserNameHashColor } from '../../utils/utils';
import { connect } from 'react-redux';
import CompanyLogo from './CompanyLogo';

const useStyles = makeStyles({
    list: {
        width: 280,
    },
    fullList: {
        width: 'auto',
    },
});

let deferredPrompt;

window.addEventListener('beforeinstallprompt', function (event) {

    event.preventDefault();
    deferredPrompt = event;

    return false;
});

function TemporaryDrawer(props) {
    const classes = useStyles();
    //const { t, i18n } = useTranslation();
    let userName;

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [appEnvironment, setAppEnvironment] = React.useState("");

    const history = useHistory();

    useEffect(() => {
        if (navigator.standalone) {
            setAppEnvironment("pwa-ios");

        } else if (matchMedia('(display-mode: standalone)').matches) {
            setAppEnvironment("pwa");
        } else {
            setAppEnvironment("browser");
        }
    }, [])

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    function goToPage(link) {
        history.push(link);
    }

    function showInstallPrompt() {

        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt = null;
        }
    }

    function renderInstallPwaButton() {
        if (appEnvironment === "browser") {

            return (
                <Button
                    className="btInstall"
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => { showInstallPrompt() }}>
                    {props.translateFunc("APP_ADD_HOME_SCREEN_LABEL")}
                </Button>
            );
        } else { return null; }
    }

    function renderAvatar(sourceImage) {
        if (props.currentUser.profilePictureThumbnailPath === null
            || props.currentUser.profilePictureThumbnailPath === '') {
            let hashColor = calculateUserNameHashColor(userName);
            return (
                <Avatar
                    className="NoPhotoAvatar"
                    alt={userName}
                    src={SelectedUserAvatar}
                    style={{
                        background:
                            'linear-gradient(135deg, hsl(' + hashColor + ', 25%, 80%) 0%, hsl(' + hashColor + ', 30%, 50%) 100%)'
                    }} />
            );
        } else {
            return (

                <Avatar alt={userName} src={props.currentUser.profilePictureThumbnailPath} />
            );
        }
    }


    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >

            {/*https://www.npmjs.com/package/react-aad-msal */}
            <AzureAD provider={authProvider} forceLogin={true}>
                {
                    ({ login, logout, authenticationState, error, accountInfo }) => {

                        // https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens

                        const primaryText = accountInfo.account.name || "unknown account name";
                        userName = primaryText;

                        const getSecondaryText = () => {
                            switch (accountInfo.account.idTokenClaims.idp) {

                                default:
                                case "facebook.com":
                                case "linkedin.com":
                                    return accountInfo.account.userName
                                        ? accountInfo.account.userName
                                        : "unknown email";
                            }
                        }

                        //const myorganization = accountInfo.account.idTokenClaims.extension_Organizacao || "organizacao desconhecida";

                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                                return (
                                    <List>
                                        <Paper elevation={0} className="header">
                                            <ListItemIcon>
                                                {renderAvatar(SelectedUserAvatar)}
                                                {/* <Avatar alt="Remy Sharp" src={AvatarImage} className={classes.large} /> */}
                                            </ListItemIcon>

                                            <div className="companyLogo">
                                                <CompanyLogo
                                                    companyLogo={props.currentUser.currentAssociatedCompany.logoURL}>
                                                </CompanyLogo>
                                            </div>

                                            <ListItemText primary={primaryText} secondary={getSecondaryText()} className="headerSpacer" />
                                        </Paper>

                                        {renderInstallPwaButton()}

                                        {/* <div className='onesignal-customlink-container'></div> */}

                                        {/* <ListItem button key='Conquistas' onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_ACHIEVEMENTS) }}>
                                            <ListItemIcon><StarsIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("ACHIEVEMENTS_LABEL")} />
                                        </ListItem> */}

                                        <ListItem button key='Dados Pessoais' onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_PERSONAL_DATA) }}>
                                            <ListItemIcon><PersonIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("PERSONAL_DATA_LABEL")} />
                                        </ListItem>

                                        {/* <ListItem button key='Ajuda' onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_HELP) }}>
                                            <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("HELP_LABEL")} />
                                        </ListItem>

                                        <ListItem button key='configurações' onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_CONFIGURATIONS) }}>
                                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("CONFIGURATIONS_LABEL")} />
                                        </ListItem>

                                        <ListItem button key='Loja' onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_STORE) }}>
                                            <ListItemIcon><StorefrontIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("STORE_LABEL")} />
                                        </ListItem> */}

                                        <ListItem button key='Sobre a App' onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_ABOUT) }}>
                                            <ListItemIcon><SentimentVerySatisfiedIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("ABOUT_APP_LABEL")} />
                                        </ListItem>

                                        <ListItem className="sair" button key='Exit' onClick={() => { logout(); localStorage.clear(); }}>
                                            <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
                                            <ListItemText primary={props.translateFunc("EXIT_LABEL")} />
                                        </ListItem>
                                    </List>
                                );
                            default:
                                break;
                        }
                    }
                }
            </AzureAD>



        </div>
    );

    return (
        <div>
            <IconButton aria-label="Open drawer" color="inherit" onClick={toggleDrawer('left', true)}>
                <MenuIcon />
            </IconButton>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>

                {sideList('left')}
            </Drawer>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser
    }
}

export default connect(mapStateToProps)(TemporaryDrawer);