import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function MedalsGiveStar(props) {
  const classes = useStyles();

  const history = useHistory();

  function handleArrowClick() {
    if (props.isMedalDetailComingFromLocalUser) {
      history.push(process.env.REACT_APP_ROUTING_MEDALS);
    }
    else {
      if (props.routingUserId != null && props.routingUserId != 0) {

        history.push(process.env.REACT_APP_ROUTING_MEDALS_ASSIGN + "/" + props.routingUserId);
      } else {

        history.push(process.env.REACT_APP_ROUTING_MEDALS_ASSIGN);
      }

    }
  }

  return (
    <div className={classes.root}>
      <Fab color="primary" aria-label="add" size="medium" onClick={handleArrowClick}>
        <StarIcon />
      </Fab>
    </div>
  );
}