import React from 'react';
import { ReactComponent as ModalIcon } from '../../images/Icons/new_medal.svg';
import Avatar from '@material-ui/core/Avatar';

export default function ModalsHeaderNewMedal() {

  return (
    <div>
      <div className="ModalsHeader">
        <div>
          <ModalIcon className="new_medal"/>
          <Avatar alt="Nome 1" src={require("../../images/avatars/3.jpg")} className="avatarModals"/>
        </div>
      </div>
      <div className="ModalsHeaderShadow"></div>
    </div>
  );
}
