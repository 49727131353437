import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { store } from '../../redux/reducers/store';
import { confirmAlert } from 'react-confirm-alert';
import NavIcon from '../../components/Home/NavIcon';
import * as coinService from '../../api/coinService';
import * as moodService from '../../api/moodService';
import { navIconStyle } from "../../css/globalStyles";
import * as medalService from '../../api/medalService';
import NavNumber from '../../components/Home/NavNumber';
import * as medalActions from '../../redux/actions/medalActions';
import { formatDate, getColorBasedOnMoodAverage } from '../../utils/utils';
import { ReactComponent as StarsIcon } from '../../images/Icons/stars.svg';
import { ReactComponent as CoinsIcon } from '../../images/Icons/coins.svg';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import { ReactComponent as MoodIconOk } from '../../images/Icons/moodStroke3.svg';
import { ReactComponent as MoodIconGood } from '../../images/Icons/moodStroke4.svg';
import { ReactComponent as MoodIconAwful } from '../../images/Icons/moodStroke1.svg';
import ModalComponentNoButtons from '../../containers/_Global/ModalComponentNoButtons';
import { ReactComponent as MoodIconFantastic } from '../../images/Icons/moodStroke5.svg';
import QuestionCarouselContainer from '../../containers/Questions/QuestionCarouselContainer';
import { getUnansweredQuestionsFromActiveQuiz, getCoinRewardForQuestionAnswered, hasUserAnsweredQuestionsToday } from '../../api/questionService';
import * as questionActions from '../../redux/actions/questionActions';
import ModalImageSimpleConfirm from '../_Global/ModalSimpleConfirmation';
import * as modalUtils from '../../utils/modalUtils';
import ModalsHeaderHappycoinsWon from '../../containers/_Global/ModalsHeaderHappycoinsWon';

function NavOptionContainer(props) {

    const history = useHistory();
    const [userCoins, setUserCoins] = useState(null);
    const [moodAverage, setMoodAverageState] = useState(null);
    const [acquiredMedals, setAcquiredMedalsState] = useState(null);
    const [iconBadgeNumber, setIconBadgeNumber] = React.useState(0);
    const [canShowContent, setCanShowContent] = React.useState(false);
    const [linkAddressNavIcon, setLinkAddressNavIcon] = React.useState();
    const [navNumberBadgeNumber, setNavNumberBadgeNumber] = React.useState(0);
    const [unansweredQuestions, setUnansweredQuestions] = React.useState(null);
    const [coinRewardForAnsweringQuestions, setCoinRewardForAnsweringQuestions] = React.useState();

    useEffect(() => {
        if (props.navType === process.env.REACT_APP_NAVTYPE_COINS) {

            getCoinRewardForQuestionAnswered(
                setCoinRewardForAnsweringQuestions).then(reward => {
                    setCoinRewardForAnsweringQuestions(reward);
                });

            getUnansweredQuestionsFromActiveQuiz(true, questionsCallback)// isToGetAll = true
                .then(questions => {
                    questionsCallback(questions);
                })
        }
    }, [])

    useEffect(() => {
        if (props.navType === process.env.REACT_APP_NAVTYPE_COINS) {

            if (unansweredQuestions != null) {

                setIconBadgeNumber(unansweredQuestions.length);
            }
        }
    }, [unansweredQuestions])


    useEffect(() => {
        if (props.navType === process.env.REACT_APP_NAVTYPE_COINS) {

            coinService.getUserTotalCoinsForCompany(setCoinsAndShowContent)
                .then(coins => {
                    setCoinsAndShowContent(coins);
                });
        }
        else if (props.navType === process.env.REACT_APP_NAVTYPE_MEDALS) {
            if (props.currentUser.id !== 0) {
                setIconBadgeNumber(props.currentUser.availableAppraisalsBeforeAssign);

                medalService.getAcquiredMedals(setMedalsAndShowContent)
                    .then(medals => {
                        setMedalsAndShowContent(medals);
                    });

                medalService.getUserAcquiredMedalsInCompanyForCurrentDay(setNavNumberBadgeNumber)
                    .then(count => {
                        setNavNumberBadgeNumber(count);
                    });

            }
        }
        else if (props.navType === process.env.REACT_APP_NAVTYPE_MOOD) {

            // TODO JS: remove hardcoded dates from here
            let beginDate = formatDate(new Date(2018, 1, 1));
            let endDate = formatDate(new Date(2035, 1, 1));

            moodService.getMoodAverage(beginDate, endDate, setMoodAndShowContent)
                .then(moodAverage => {
                    setMoodAndShowContent(moodAverage);
                });

            moodService.getUnfilledMoodsForCurrentWeek(setIconBadgeNumber).then(count => {
                setIconBadgeNumber(count <= 0 ? 0 : count);
            });
        }

        getLinkAddressNavIcon();
    }, [])

    useEffect(() => {
        if (props.navType === process.env.REACT_APP_NAVTYPE_MEDALS) {
            setIconBadgeNumber(props.currentUser.availableAppraisalsBeforeAssign);
        }
    }, [props.currentUser])

    function questionsCallback(questions) {

        if (questions == null || questions.length == 0) {
            setIconBadgeNumber(null);
        }

        setUnansweredQuestions(questions);
    }

    function setCoinsAndShowContent(coins) {
        setUserCoins(coins);
        setCanShowContent(true);
    }

    function setMedalsAndShowContent(medals) {
        setAcquiredMedalsState(medals);
        setCanShowContent(true);
    }

    function setMoodAndShowContent(moodAverage) {
        setCanShowContent(true);
        setMoodAverageState(moodAverage);
    }

    function getMoodIconBasedOnMoodAverage(moodAverage) {
        let color = getColorBasedOnMoodAverage(moodAverage);

        if (moodAverage === 0) {
            return (
                <MoodIconGood style={{ ...navIconStyle.whiteColor, ...navIconStyle.svgSize }} />
            );

        }

        if (moodAverage >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_FANTASTIC) {
            return (
                <MoodIconFantastic style={{ ...color, ...navIconStyle.svgSize }} />
            );

        } else if (moodAverage >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_GOOD) {
            return (
                <MoodIconGood style={{ ...color, ...navIconStyle.svgSize }} />
            );

        } else if (moodAverage >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_OK) {
            return (
                <MoodIconOk style={{ ...color, ...navIconStyle.svgSize }} />
            );

        } else if (moodAverage >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_BAD
            || moodAverage > process.env.REACT_APP_MOOD_AVERAGE_LIMIT_AWFUL) {
            return (
                <MoodIconAwful style={{ ...color, ...navIconStyle.svgSize }} />
            );
        }

    }

    function getIconToRender() {
        let iconToRender;

        if (props.navType === process.env.REACT_APP_NAVTYPE_COINS) {

            iconToRender = <CoinsIcon style={{ ...navIconStyle.svgSize }} />

        } else if (props.navType === process.env.REACT_APP_NAVTYPE_MEDALS) {

            let starColor = medalService.getStartColorBasedOnRanking(
                props.currentUser.medalRankPosition,
                acquiredMedals)

            iconToRender = <StarsIcon style={{ ...starColor, ...navIconStyle.svgSize }} />

        } else if (props.navType === process.env.REACT_APP_NAVTYPE_MOOD) {
            iconToRender = getMoodIconBasedOnMoodAverage(moodAverage);
        }

        return iconToRender;
    }

    function getLinkAddressNavIcon() {

        if (props.navType === process.env.REACT_APP_NAVTYPE_MEDALS) {
            setLinkAddressNavIcon(process.env.REACT_APP_ROUTING_MEDALS);

        } else if (props.navType === process.env.REACT_APP_NAVTYPE_MOOD) {
            setLinkAddressNavIcon(process.env.REACT_APP_ROUTING_MOOD);
            moodService.hasUserFilledMoodToday(setNavIconLink)
                .then(userFilledMoodToday => {
                    setNavIconLink(userFilledMoodToday);
                });

        }
    }

    function setNavIconLink(userFilledMoodToday) {
        if (userFilledMoodToday) {
            setLinkAddressNavIcon(process.env.REACT_APP_ROUTING_MOOD_DETAIL);
        } else {
            setLinkAddressNavIcon(process.env.REACT_APP_ROUTING_MOOD);
        }
    }

    function renderNumber() {
        let numberToDisplay = 0;

        let onClickFunction;

        if (props.navType === process.env.REACT_APP_NAVTYPE_COINS) {
            numberToDisplay = userCoins;

            onClickFunction = function () {
                history.push(process.env.REACT_APP_ROUTING_PAGE_COINS);
            }


        } else if (props.navType === process.env.REACT_APP_NAVTYPE_MEDALS) {

            numberToDisplay = acquiredMedals;

            onClickFunction = function () {
                props.setMedalDetailComingFromThisUser(true);
                history.push(process.env.REACT_APP_ROUTING_MEDALS_DETAIL);
            }

        } else if (props.navType === process.env.REACT_APP_NAVTYPE_MOOD) {
            numberToDisplay = moodAverage;
            if (Number.isInteger(moodAverage)) {
                numberToDisplay = moodAverage;
            }
            else {
                let parsedNumber = parseFloat(moodAverage).toFixed(1);
                if (parsedNumber !== "NaN") {
                    numberToDisplay = parsedNumber;
                }
            }


            onClickFunction = function () {
                history.push(process.env.REACT_APP_ROUTING_MOOD);
            }
        }

        return (
            <NavNumber
                numberToDisplay={numberToDisplay}
                badgeContent={navNumberBadgeNumber}
                onClickFunction={onClickFunction} />
        );
    }

    function updateCallback(answersGiven) {
        if (answersGiven != null && answersGiven.length) {

            let remainingQuestions = unansweredQuestions
                .filter(q =>
                    !answersGiven.map(a => a.questionId).includes(q.id));

            setUnansweredQuestions(remainingQuestions);

        }
        coinService.getUserTotalCoinsForCompany(setCoinsAndShowContent)
            .then(coins => {
                setCoinsAndShowContent(coins);
            });
    }

    function handleNavIconClick() {
        if (props.navType === process.env.REACT_APP_NAVTYPE_COINS) {
            if (unansweredQuestions != null && unansweredQuestions.length) {

                showQuestionsPopup(updateCallback);
            } else {
                modalUtils.displayModalImageSimpleConfirm(
                    <ModalsHeaderHappycoinsWon />,
                    "Hoje não temos perguntas...",
                    "os nossos elfos mágicos estão a criar mais",
                    "Ok");
            }
        }
        else {
            props.setMedalDetailComingFromThisUser(false);
            history.push(linkAddressNavIcon);
        }
    }


    function showQuestionsPopup(updateCallback) {
        // Show questions modal                
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Provider store={store}>

                        <ModalComponentNoButtons
                            onModalClosed={onClose}
                            disableBackdropClickFlag={true}
                            confirmationMessage={props.translateFunc("QUESTIONS_ANSWER_AND_WIN")}
                            componentContent={
                                <QuestionCarouselContainer
                                    translateFunc={props.translateFunc}
                                    i18n={props.i18n}
                                    updateCallback={updateCallback}
                                    ignoreMoodSubmit={true}
                                    showNotNowButton={true}
                                    showIndexPagination={false}
                                    isComingFromHomeScreen={true}
                                    goToHomeFunction={onClose}
                                    coinRewardForAnsweringQuestions={coinRewardForAnsweringQuestions}
                                    questionCollection={unansweredQuestions} />}
                        />
                    </Provider>
                );
            }
        });
    }


    const classes = {
        inlineBlock: {
            display: 'inline-block',
            margin: '0 10px',
        }
    };

    function renderContent() {

        if (canShowContent) {
            return (
                <div style={classes.inlineBlock} className="appBts">

                    <NavIcon
                        iconToRender={getIconToRender()}
                        badgeContent={iconBadgeNumber}
                        onClickFunction={handleNavIconClick} />

                    {renderNumber()}
                </div>
            );
        }
        else {
            return (
                <div className="homeLoader">
                    <LoadingComponent />
                </div>
            );
        }
    }

    return (
        renderContent()
    );
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser,
        questionAnswers: state.questionsReducer.questionAnswers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMedalDetailComingFromThisUser: (isComingFromUser) => dispatch(
            medalActions.setMedalDetailComingFromThisUser(isComingFromUser)),
        clearQuestionData: () => dispatch(
            questionActions.clearQuestionData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavOptionContainer);