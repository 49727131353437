// Contains the mood calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getAllUsersForCompany(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_GETALLUSERSFORCOMPANY,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getAllUsersForCompanyWithSearchTerm(searchTerm, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_USER_GETALLUSERSFORCOMPANY_WITH_SEARCH_TERM, searchTerm),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getAllUsersForCompanyByPage(pageToLoad, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_USER_GETALLUSERSFORCOMPANY_BY_PAGE, pageToLoad),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getUserRankingPosition(userId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_USER_RANKING_POSITION, userId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getCurrentUserDetails(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_GETDETAILS,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getSpecificUserDetails(userId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_USER_GET_SPECIFIC_DETAILS, userId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function loginOrCreateUser(userName, email) {
    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_LOGIN_OR_CREATE_USER,
        "POST",
        {
            name: userName,
            email: email
        }
    )
}

export function savePlayerId(playerId, userId) {

    return fetch(
        stringFormat(
            process.env.REACT_APP_URL_API_PLAYERID_SAVE_PLAYER_ID, playerId, userId), {
        method: 'POST'
    });
}


export function saveUserProfilePicture(userProfilePicture) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_UPLOAD_PICTURE,
        "POST",
        {
            data: userProfilePicture.data,
            fileName: userProfilePicture.fileName,
            isThumbnail: userProfilePicture.isThumbnail
        });
}

export function saveSpecificUserProfilePicture(data, userId) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_SPECIFIC_USER_UPLOAD_PICTURE, userId),
        "POST",
        {
            data: data,
            userId: userId
        });
}


export function getProfilePicture(userId) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_USER_GET_PICTURE, userId),
        "GET");
}

export function deleteProfilePicture() {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_DELETE_PICTURE,
        "POST");
}

export function getMedalCategoryHighlights(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_GET_MEDAL_HIGHLIGHTS,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getTotalUserCount(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_GET_TOTAL_USER_COUNT,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getMedalHighlightsThresholdTimeDays(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_USER_GET_HIGHLIGHTS_THRESHOLD_TIME_DAYS,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}
