import React from 'react';
import { navIconStyle } from "../../css/globalStyles";
import Badge from '@material-ui/core/Badge';


export default function NavIcon(props) {

    return (
        <div style={navIconStyle.center}>
                <button style={navIconStyle.button} onClick={props.onClickFunction}>
                    <Badge color="primary" badgeContent={props.badgeContent} style={navIconStyle.iconMargin} overlap="circular">
                        {props.iconToRender}
                    </Badge>
                </button>
        </div>
    );
}