import React from 'react';
import AssignNumber from '../../components/Medals/AssignNumber'
import AssignAddIcon from '../../components/Medals/AssignAddIcon'
import AssignSubtractIcon from '../../components/Medals/AssignSubtractIcon'
import MedalsNumberStar from '../../components/Medals/MedalsNumberStar'
import { navIconStyle } from '../../css/globalStyles';

export default function AssingMedalsAddSubtract(props) {

  return (

    <div className="MedalsNumberStar">

      <MedalsNumberStar
        medalCount={props.assignedAppraisalsCount * props.medalCategoryWeight}
        medalStyle={navIconStyle.whiteColor} />

      <AssignSubtractIcon
        onPraiseAssignClick={props.onPraiseAssignClick}
      />

      <AssignNumber numberToDisplay={props.assignedAppraisalsCount} />

      <AssignAddIcon
        onPraiseAssignClick={props.onPraiseAssignClick}
      />
    </div>
  );
}
