import React, { useEffect, useState } from 'react';
import MedalsHighlightsCategoryBlock from '../../components/Medals/MedalsHighlightsCategoryBlock';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import * as medalActions from '../../redux/actions/medalActions';
import { calculateUserNameHashColor } from '../../utils/utils';


function MedalsHighlightsCategoryContainer(props) {

    let history = useHistory();
    const [categoryColor, setCategoryColor] = useState()

    useEffect(() => {

        let color = calculateUserNameHashColor(props.categoryName);

        setCategoryColor(
            '4px solid hsl(' + color + ',  100%, 60%)');

    }, [])

    function handleUserProfileClick(userDetails) {
        props.onMedalUserDetailClick(userDetails);
        
        props.setMedalDetailComingFromThisUser(userDetails.userId === props.currentUser.id);

        // Set the history path so when the user clicks back he returns to the highlights
        let historyPath = process.env.REACT_APP_ROUTING_MEDALS_HIGHLIGHTS + "/" + props.categoryId;
        props.setMedalHistroyBackSettings(true, historyPath);
        history.push(process.env.REACT_APP_ROUTING_MEDALS_DETAIL);
    }

    function renderUserBlocks() {
        if (!props.topUsers) {
            return;
        }

        let blocks = [];
        props.topUsers.forEach(user => {
            blocks.push(
                <MedalsHighlightsCategoryBlock
                    key={user.id}
                    userId={user.id}
                    handleUserProfileClick={handleUserProfileClick}
                    profilePictureFullPath={user.profilePictureFullPath}
                    receivedMedalCount={user.receivedMedalCount}
                    name={user.name} />)
        });
        return blocks;
    }

    return (
        <div className="MedalsHighlightsCategory">
            <div
                className="title"
                style={{ borderBottom: categoryColor }}
            >

                {props.categoryName}
            </div>
            {renderUserBlocks()}
            {/* <div className="copy">
                <IconButton>
                    <InfoOutlinedIcon />
                </IconButton>
            </div> */}
        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        onMedalUserDetailClick: (userDetails) => dispatch(
            medalActions.onMedalUserDetailSelected(userDetails)
        ),
        setMedalDetailComingFromThisUser: (isComingFromUser) => dispatch(
            medalActions.setMedalDetailComingFromThisUser(isComingFromUser)
        ),
        setMedalHistroyBackSettings: (isMedalDetailComingFromHighlights, highlightHistoryLastPath) => dispatch(
            medalActions.setMedalHistroyBackSettings(isMedalDetailComingFromHighlights,
                highlightHistoryLastPath)
        ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedalsHighlightsCategoryContainer)