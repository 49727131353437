import React, { useEffect } from 'react';
import Carousel from 'react-elastic-carousel'
import { Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';


export default function TutorialCarousel(props) {

  return (
    <div className="MoodQt MoodModal">
      <Carousel
        pagination={props.showIndexPagination}
        itemsToShow={1}
        className="MedalsHighlights">
        {props.cardsContent}
      </Carousel>

      <DialogActions className="btQuestions">

        <Button
          color="primary"
          onClick={() => { props.confirmFunction() }}>
          {props.translateFunc("GLOBAL_OK")}
        </Button>

      </DialogActions>
    </div>
  );
}