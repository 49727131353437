import React, { useEffect, useState } from 'react';
import CoinsHeaderContainer from './CoinsHeaderContainer';
import CoinsTransactionHistoryContainer from './CoinsTransactionHistoryContainer';
import { getCoinTransactionHistory } from '../../api/coinService';

export default function CoinTransactionHistory(props) {
    const [coinTransactionHistoryList, setcoinTransactionHistoryList] = useState([]);

    useEffect(() => {
        getCoinTransactionHistory(setcoinTransactionHistoryList)
            .then(data => {
                setcoinTransactionHistoryList(data);
            });

    }, [])
    return (
        <div>
            <CoinsHeaderContainer
                translateFunc={props.translateFunc}
                i18n={props.i18n}
                linkToRef={process.env.REACT_APP_ROUTING_PAGE_COINS}
                isToHideTransactionHistoryButton={true} />

            <CoinsTransactionHistoryContainer
                i18n={props.i18n}
                translateFunc={props.translateFunc}
                coinTransactionHistoryList={coinTransactionHistoryList} />
        </div>
    );
}