// Contains the influence category calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getInfluenceCategoriesForSpecificCompany(companyId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_INFLUENCECATEGORY_GETALLFORSPECIFICCOMPANY, companyId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);


}