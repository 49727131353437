import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MedalsHeaderContainer from '../../containers/Medals/MedalsHeaderContainer';
import MedalsTabsContainer from '../../containers/Medals/MedalsTabsContainer';

export default function MedalsContainer(props) {


    const { t, i18n } = useTranslation();

    // These variables are set if the user enters with a routing parameter    
    let translateFunc = t;
    let i18nEngine = i18n;

    useEffect(() => {
        if (props.translateFunc == null || props.i18n == null) {
            translateFunc = t;
            i18nEngine = i18n;
        } else {
            translateFunc = props.translateFunc;
            i18nEngine = props.i18n;
        }
    }, [])

    return (
        <div>
            <MedalsHeaderContainer
                i18n={i18nEngine}
                translateFunc={translateFunc} />
            <MedalsTabsContainer
                i18n={i18nEngine}
                translateFunc={translateFunc}
                isToDisplayHighlights={props.isToDisplayHighlights || props.match != null}
                categoryId={props.match != null ? props.match.params.categoryId : 0} />
        </div>
    );
}