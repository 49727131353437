import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import MedalsNumber from '../../components/Medals/MedalsNumber';
import MedalsStar from '../../components/Medals/MedalsStar';

export default function CategoryListContainer() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className="DetailMedalsList"
    >
      <ListItem button>
        <ListItemIcon>
        <Avatar alt="Nome 1" src={require("../../images/avatars/1.jpg")} />
        </ListItemIcon>
        <ListItemText primary="Ana Paula" secondary="Segunda, 10/02/2020" />
        <MedalsNumber medalCount={10} />
        <MedalsStar medalStyle={1} />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
        <Avatar>AP</Avatar>
        </ListItemIcon>
        <ListItemText primary="Ana Paula" secondary="Segunda, 10/02/2020" />
        <MedalsNumber medalCount={10} />
        <MedalsStar medalStyle={1} />
      </ListItem>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
        <Avatar alt="Nome 1" src={require("../../images/avatars/2.jpg")} />
        <ChatBubbleIcon className="svgChat"/>
        </ListItemIcon>
        <ListItemText primary="Ana Paula" secondary="Segunda, 10/02/2020" />
        {open ? <ExpandLess /> : <ExpandMore />}
        <MedalsNumber medalCount={10} />
        <MedalsStar medalStyle={1} />
        <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button>
            <ListItemText>
                <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin eget libero sed elementum. Nunc vitae mattis justo. Maecenas vitae ipsum nisl. Duis venenatis eros sapien, in vehicula massa feugiat et. Sed bibendum lobortis scelerisque. Vivamus augue ipsum, pretium et ipsum eget, pretium luctus odio. Maecenas lacinia convallis ornare. Mauris sit amet ex vitae lorem ullamcorper hendrerit ut et tellus. Quisque nec sem felis. Suspendisse eu interdum sapien. Ut quis ligula sit amet mi viverra accumsan. Praesent euismod libero eget nisi pretium, vel semper ex scelerisque. In condimentum lectus vel tempus dignissim. Integer ultricies neque sit amet dapibus convallis.
                </Typography>
                <Typography className="hour">
                    10:32
                </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
        <Avatar alt="Nome 1" src={require("../../images/avatars/3.jpg")} />
        </ListItemIcon>
        <ListItemText primary="Ana Paula" secondary="Segunda, 10/02/2020" />
        <MedalsNumber medalCount={10} />
        <MedalsStar medalStyle={1} />
      </ListItem>
    </List>
  );
}