import React from 'react';
import List from '@material-ui/core/List';
import { useState, useEffect } from 'react';
import { ReactComponent as CoinsIcon } from '../../images/Icons/coins.svg';
import ConTransactionRowElement from '../../components/Coins/ConTransactionRowElement';
import { getUserTotalCoinsForCompany } from '../../api/coinService';

export default function CoinsTransactionHistoryContainer(props) {
    const [userCoins, setUserCoins] = useState();

    useEffect(() => {
        getUserTotalCoinsForCompany(setUserCoins).then(c => setUserCoins(c));
    }, [])

    function renderListSection() {
        if (props.coinTransactionHistoryList === null || props.coinTransactionHistoryList.length === 0) {
            return;
        }

        var coinRows = [];

        props.coinTransactionHistoryList
            .forEach(c => {
                coinRows.push(
                    <ConTransactionRowElement
                        key={c.coinTransactionId}
                        titleLabel={c.titleLabel}
                        quantity={c.quantity}
                        timeStamp={c.timeStamp}
                        isPurchase={c.isPurchase} />
                );

            });

        return coinRows;
    }
    return (
        <div className="HappyShop CoinsListContainer">
            <div className="MyHappyCoins">
                <div className="txt">{props.translateFunc("COIN_TRANSACTION_HISTORY_YOU_HAVE")}</div>
                <div className="title">{userCoins}</div>
                <div><CoinsIcon /></div>
            </div>
            <List className="CoinsList">
                {renderListSection()}
            </List>

        </div>
    );
}