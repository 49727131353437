import React from 'react';
import MedalsNumber from '../../components/Medals/MedalsNumber';
import MedalsStar from '../../components/Medals/MedalsStar';

export default function MedalsNumberStar(props) { 

  return (
    <div className="MedalsNumberStar">
      <MedalsNumber medalCount={props.medalCount} />
      <MedalsStar badgeContent={props.badgeContent} medalStyle={props.medalStyle} />
    </div>
  );
}