import React from 'react';
import MedalsHeader from '../../components/Medals/MedalsHeader'

export default function MedalsHeaderContainer(props) {

    return (
        <div>
            <MedalsHeader i18n={props.i18n}
                translateFunc={props.translateFunc} />
        </div>
    );
}