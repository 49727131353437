import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));


const PrettoSlider = withStyles({
  track: {
    height: 12,
    borderRadius: 6,
  },
  rail: {
    height: 12,
    borderRadius: 6,
  },
})(Slider);


export default function DiscreteSlider(props) {
  const classes = useStyles();
  const [goalMarks, setGoalMarks] = React.useState();

  useEffect(() => {
    let marks = [];

    for (let index = 0; index <= props.units; index++) {
      if (index !== 0) {
        marks.push({ value: index * props.coinsPerUnit, label: index })
      }
    }

    setGoalMarks(marks);

  }, [props.units, props.coinsPerUnit])


  return (
    <div className={classes.root}>
      <PrettoSlider
        value={props.sliderCurrentValue}
        min={0}
        max={props.sliderMaxValue}
        step={10}
        marks={goalMarks}
        valueLabelDisplay="on"
        disabled
      />
    </div>
  );
}
