import React, { useEffect } from 'react';
import CoinsSlider from './CoinsSlider';

export default function CoinsCampaignObjective(props) {
    const [goal, setGoal] = React.useState(0);
    const [coinsLeftToGoal, setCoinsLeftToGoal] = React.useState(0);
    const [daysLeft, setDaysLeft] = React.useState();
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    useEffect(() => {
        let goalSum = props.units * props.coinsPerUnit
        setGoal(goalSum);
        let coinsLeftToGoalSum = goalSum - props.campaignCurrentCoins;
        setCoinsLeftToGoal(coinsLeftToGoalSum <= 0 ? 0 : coinsLeftToGoalSum);

        if (props.endDate != null) {

            let startDate = new Date();
            let endDate = new Date(props.endDate);

            let diffTime = Math.abs(startDate - endDate);

            setDaysLeft(Math.round(diffTime / oneDay));
        }

    }, [props.units, props.coinsPerUnit, props.campaignCurrentCoins, props.startDate, props.endDate])

    return (
        <div className="objective">
            <span className="txt orangeColor">{props.translateFunc("CAMPAIGN_OBJECTIVE_LABEL")} <span>{goal}</span></span>

            <CoinsSlider
                units={props.units}
                coinsPerUnit={props.coinsPerUnit}
                sliderCurrentValue={props.campaignCurrentCoins}
                sliderMaxValue={goal} />

            <span className="txt blueColor">{props.translateFunc("GLOBAL_MISSING")} <span>{coinsLeftToGoal}</span> | <span>{daysLeft}</span> {props.translateFunc("GLOBAL_DAYS_LABEL")}</span>
        </div >
    );
}