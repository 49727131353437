import React, { useState, useEffect } from 'react';
import HistoryIconCard from '../../components/History/HistoryIconCard';
import HistoryChartCard from '../../components/History/HistoryChartCard';
import { ReactComponent as CoinsIcon } from '../../images/Icons/coins.svg';
import { getAcquiredCoinsLastWeeks, getAcquiredCoisLastDays, getUserTotalCoinsForCompany } from '../../api/coinService';


export default function UserChartCardCoins(props) {
  const [currentUserCoins, setCurrentUserCoins] = useState();
  const [acquiredCoinsLastDaysData, setAcquiredCoinsLastDaysData] = useState();
  const [acquiredCoinsLastWeeksData, setAcquiredCoinsLastWeeksData] = useState();

  let lastDaysAmount = 14;
  let numberOfWeeksToGet = 9;

  useEffect(() => {
    getUserTotalCoinsForCompany(setCurrentUserCoins)
      .then(c => setCurrentUserCoins(c));

    getAcquiredCoisLastDays(lastDaysAmount, buildAcquiredCoinsLastDaysData)
      .then(d => buildAcquiredCoinsLastDaysData(d));

    getAcquiredCoinsLastWeeks(numberOfWeeksToGet, buildAcuiredCoinsLastWeeksData)
      .then(d => buildAcuiredCoinsLastWeeksData(d));
  }, [])

  function buildAcquiredCoinsLastDaysData(data) {
    var finalData = [];

    data.forEach(c => {
      finalData.push({
        name: null, uv: c
      });
    });

    setAcquiredCoinsLastDaysData(finalData);
  }


  function buildAcuiredCoinsLastWeeksData(coinData) {
    var finalData = [];

    coinData.forEach(m => {
      var date = m.weekStartAndEndDate.item1 + " - " + m.weekStartAndEndDate.item2;
      finalData.push({
        name: date, uv: m.coinQuantity
      });

    });

    setAcquiredCoinsLastWeeksData(finalData);
  }


  return (
    <div className="HistoryCards">
      <HistoryIconCard
        cardIcon={<CoinsIcon />}
        number={currentUserCoins} />

      <HistoryChartCard
        chartColor={"blueColorBgTransparency"}
        data={acquiredCoinsLastDaysData}
        description={props.translateFunc("CHART_COINS_DAILY_DESCRIPTION")} />

      <HistoryChartCard
        chartColor={"blueColorBgTransparency"}
        data={acquiredCoinsLastWeeksData}
        description={props.translateFunc("CHART_COINS_WEEKLY_DESCRIPTION")} />
      <div className="divSpacer" />
    </div>
  );
}
