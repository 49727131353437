import React from 'react';
import { ReactComponent as StarsIcon } from '../../images/Icons/stars.svg';
import { navIconStyle } from "../../css/globalStyles";
import Badge from '@material-ui/core/Badge';

export default function MedalsStar(props) {
  const medalStyle = props.medalStyle != null ? props.medalStyle : navIconStyle.whiteColorTransparency;

  return (
    <Badge color="secondary" badgeContent={props.badgeContent} overlap="circular">
      <StarsIcon style={{ ...medalStyle, ...navIconStyle.svgSizeList }} />
    </Badge>
  );
}