import React, { useEffect } from 'react';
import MedalsDetailHeader from '../../components/Medals/MedalsDetailHeader'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MedalsDetailHeaderContainer(props) {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    let translateFunc = t;
    let i18nEngine = i18n;

    useEffect(() => {
        if (props.translateFunc == null || props.i18n == null) {
            translateFunc = t;
            i18nEngine = i18n;
        } else {
            translateFunc = props.translateFunc;
            i18nEngine = props.i18n;
        }
    }, [])

    function getHeaderTitle() {
        if (props.isMedalDetailComingFromLocalUser === true) {
            return translateFunc("MEDALS_YOUR_MEDALS");
        }
        else {
            if (props.historyType === "GENERAL") {
                return translateFunc("MEDALS_HISTORY_GENERAL");

            } else if (props.historyType === "COMMON") {
                return translateFunc("MEDALS_HISTORY_COMMON");
            }
        }
    }

    function getReturnButtonLink() {
        if (props.isMedalDetailComingFromLocalUser === true) {
            return "/";
        } else if (props.isMedalDetailComingFromHighlights === true) {

            return props.highlightHistoryLastPath;
        }
        else {
            return process.env.REACT_APP_ROUTING_MEDALS;
        }
    }

    function handleOnReturnClick() {
        let link = getReturnButtonLink();
        props.clearHighlightsHistorySettings();
        history.push(link);
    }

    return (
        <div>
            <MedalsDetailHeader
                isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser}
                i18n={props.i18n}
                translateFunc={props.translateFunc}
                headerTitle={getHeaderTitle()}
                onReturnClickFunction={handleOnReturnClick} />
        </div>
    );
}