import * as actionNames from '../actionNames';

export const setCurrentUser = (userDetails) => ({
    type: actionNames.SET_CURRENTUSER,
    userDetails: userDetails   
})

export const setUserLanguage = (lang) => ({
    type: actionNames.SET_USER_LANGUAGE,
    value: lang   
})



export const updateCurrentAvailableAppraisalsToSpend = (appraisalCount) => ({
    type: actionNames.UPDATE_CURRENT_AVAILABLE_APPRAISALS_TO_SPEND,
    currentAvailableAppraisalsToSpend: appraisalCount   
})

export const setUserGivenAppraisalsThisWeek = (value) => ({
    type: actionNames.SET_USER_GIVEN_APPRAISALS_THIS_WEEK,
    hasUserGivenAppraisalsThisWeek: value   
})


export const setAvailableAppraisalsBeforeAssign = (value) => ({
    type: actionNames.SET_USER_AVAILABLE_APPRAISALS_BEFORE_ASSIGN,
    availableAppraisalsBeforeAssign: value   
})

export const setPendingChangesFlag = (value) => ({
    type: actionNames.SET_PENDING_CHANGES_FLAG,
    value: value
})




