import * as actionNames from '../actionNames';


const initialState = {
    questionAnswers: []

}

const questionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionNames.INSERT_UPDATE_QUESTION:
            return {
                ...state,
                questionAnswers: insertOrUpdateQuestion(action.questionData, state)
            }

        case actionNames.CLEAR_QUESTION_DATA:
            return {
                ...state,
                questionAnswers: clearQuestionData()
            }
        default:
            return state;

    }

};

function insertOrUpdateQuestion(questionData, currentState) {

    let newQuestionAnswers = [...currentState.questionAnswers];

    if (!currentState.questionAnswers.map(q => q.questionId).includes(questionData.questionId)) {

        // Add a new question answer if does not exist yet in the redux.

        newQuestionAnswers.push(questionData);

    } else {
        // If state contains questionData.questionId UPDATE the current question answer instead
        let indexOfAnswerToUpdate = newQuestionAnswers
            .map(q => q.questionId)
            .indexOf(questionData.questionId);

        newQuestionAnswers.splice(indexOfAnswerToUpdate, 1, questionData);
    }

    return newQuestionAnswers.length ? newQuestionAnswers : null;
}

function clearQuestionData() {
    return [];
}

export default questionsReducer;