import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import Button from '@material-ui/core/Button';
import CoinsSlider from './CoinsSlider';
import * as happyShopService from '../../api/happyShopItemService';
import { confirmAlert } from 'react-confirm-alert';
import ModalSimpleConfirmation from '../../containers/_Global/ModalSimpleConfirmation'
import ModalsHeaderWeeklyObjective from '../../containers/_Global/ModalsHeaderWeeklyObjective';
import * as modalUtils from '../../utils/modalUtils';
import { useHistory } from 'react-router-dom';
import ModalsHeaderHappycoins from '../../containers/_Global/ModalsHeaderHappycoins';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import { getCurrentDay } from '../../api/diaryService';

export default function HappyShopItemDetail(props) {
    const [isItemInStock, setIsItemInStock] = useState(false);
    const history = useHistory();
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const [daysLeft, setDaysLeft] = useState(0);
    const [isItemExpired, setIsItemExpired] = useState(false);

    useEffect(() => {
        if (props.itemId != null && props.itemDetail != null) {
            getDaysLeft();

            if (props.itemDetail.stockQuantity > 0) {
                setIsItemInStock(true);
            }

        }
    }, [props.itemId, props.itemDetail])

    function renderCoinSliderObjective() {
        // These cases wont show the slider            
        if (props.userCanAffordItem
            || !props.canUserBuyMoreItemStock
            || !isItemInStock
            || isItemExpired) {
            return null;
        }
        else {

            return (
                <div>
                    <div className="objective">
                        <CoinsSlider
                            sliderCurrentValue={props.userHappyCoins}
                            sliderMaxValue={props.itemDetail != null ? props.itemDetail.price : 0} />
                    </div >
                </div>);
        }
    }

    function handlePurchaseItemClick() {


        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ModalSimpleConfirmation
                        onModalClosed={onClose}
                        onConfirmClicked={() => { purchaseItem() }}
                        confirmationMessage={"Queres fazer esta troca?"}
                        cancelButtonLabel={"Não"}
                        confirmButtonLabel={"Sim"}
                    />
                );
            }
        });
    }

    function purchaseItem() {
        happyShopService.purchaseItem(props.itemId)
            .then(purchaseInfo => {
                if (purchaseInfo == null) {
                    history.push(process.env.REACT_APP_ROUTING_PAGE_COINS);
                    return;
                }
                if (purchaseInfo.hasError) {
                    modalUtils.displayModalImageSimpleConfirm(
                        <ModalsHeaderHappycoins />,
                        "Erro",
                        purchaseInfo.errorMessage,
                        "Ok");

                } else {
                    history.push(process.env.REACT_APP_ROUTING_PAGE_COINS);

                    modalUtils.displayModalImageSimpleConfirm(
                        <ModalsHeaderWeeklyObjective />,
                        "Pedido submetido com sucesso!",
                        "Irás receber um email com os dados do teu pedido. Em breve serás contactado(a) com mais informações. Até lá continua a participar!",
                        "Ok");
                }
            });
    }
    function getDaysLeft() {
        if (props.itemDetail == null) {
            return;
        }

        getCurrentDay().then(currentDay => {
            let today = new Date(currentDay); 
            let expirationDate = new Date(props.itemDetail.expirationDate);
            let diffTime = expirationDate - today;
            let daysLeft = Math.round(diffTime / oneDay);
            
            setDaysLeft(daysLeft);
            
            if (daysLeft <= 0) {
                setIsItemExpired(true);
            }
        });

    }
    function renderContent() {

        return props.itemDetail == null
            ? <LoadingComponent />
            : <div>
                <div className="NewsDetailImg">
                    <div className="HappyCoins">{props.itemDetail != null ? props.itemDetail.price : null}</div>
                    <img src={props.itemDetail != null
                        ? props.itemDetail.image != null
                            ? props.itemDetail.image
                            : "https://happycorpindex.blob.core.windows.net/images/HappyStoreItem.jpg"
                        : "https://happycorpindex.blob.core.windows.net/images/HappyStoreItem.jpg"} />
                </div>

                {/* Product Detail */}
                <div className="NewsTxtDetail HappyShopItemDetail">
                    <div className="NewsTitle">{props.itemDetail != null ? props.itemDetail.name : null}</div>
                    <div className="NewsText">{props.itemDetail != null ? parse(props.itemDetail.description) : null}</div>
                    <br />
                    <br />
                    {renderCoinSliderObjective()}

                    {isItemExpired
                        ? <div className="StoreItemBts">
                            <Button variant="contained" disabled>
                                Oferta terminada em {props.itemDetail != null
                                    ? new Date(props.itemDetail.expirationDate).toLocaleDateString()
                                    : null}
                            </Button>
                        </div>
                        : <span className="txt blueColor">Faltam: <span>{daysLeft}</span> dias</span>}

                    <br />
                    <br />

                    {!isItemInStock
                        ? <div className="StoreItemBts">
                            <Button variant="contained" disabled>Este item está fora de stock</Button>
                        </div>
                        : null}

                    {/* ACTION BUTTONS */}
                    {props.userCanAffordItem && props.canUserBuyMoreItemStock && isItemInStock
                        ? <div className="StoreItemBts">
                            <Button onClick={handlePurchaseItemClick} variant="contained" color="primary">Trocar Happycoins por este item</Button>
                        </div>
                        : null}

                    {!props.userCanAffordItem && props.canUserBuyMoreItemStock
                        ? <div className="StoreItemBts">
                            <Button variant="contained" disabled>Faltam {props.missingHappyCoins} Happycoins</Button>
                        </div>
                        : null}

                    {!props.canUserBuyMoreItemStock && !isItemExpired
                        ? <div className="StoreItemBts">
                            <Button variant="contained" disabled>Não podes trocar mais</Button>
                        </div>
                        : null}
                </div>
            </div>

    }

    return (
        <div className="NewsDetail">
            {/* Product price and image */}
            {renderContent()}
        </div>
    );
}