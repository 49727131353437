import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { store } from '../../redux/reducers/store';
import * as moodService from '../../api/moodService';
import MoodHeader from '../../components/Mood/MoodHeader'
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import ModalSimpleConfirmation from '../../containers/_Global/ModalSimpleConfirmation'
import ModalComponentNoButtons from '../../containers/_Global/ModalComponentNoButtons'
import QuestionCarouselContainer from '../../containers/Questions/QuestionCarouselContainer';
import { getUnansweredQuestionsFromActiveQuiz, getCoinRewardForQuestionAnswered } from '../../api/questionService';

function MoodHeaderContainer(props) {
    const history = useHistory();
    const [isBadgeInvisible, setIsBadgeInvisible] = React.useState(false);
    const [unansweredQuestions, setUnansweredQuestions] = React.useState(null);
    const [isSubmitAvaiable, setIsSubmitAvaiable] = React.useState(false);
    const [coinRewardForAnsweringQuestions, setCoinRewardForAnsweringQuestions] = React.useState();


    useEffect(() => {
        if (props.hasPendingChanges) {
            setIsBadgeInvisible(false);
        }
        else {
            setIsBadgeInvisible(true);
        }
    }, [props.hasPendingChanges])

    useEffect(() => {
        getCoinRewardForQuestionAnswered(
            setCoinRewardForAnsweringQuestions).then(reward => {
                setCoinRewardForAnsweringQuestions(reward);
            });

    }, [])


    useEffect(() => {
        // Get questions to answer, if any
        getUnansweredQuestionsFromActiveQuiz(false, questionsCallback) // isToGetAll false, we only want 'x' amount
            .then(questions => {
                questionsCallback(questions);
            })
    }, [])

    function questionsCallback(questions) {
        setIsSubmitAvaiable(true);
        setUnansweredQuestions(questions);
    }

    function goToHomeFunction() {
        history.push("/");
    }

    function handleSubmitClick() {

        if (props.canSubmit) {
            if (unansweredQuestions != null && unansweredQuestions.length) {

                // Show questions modal                
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <Provider store={store}>

                                <ModalComponentNoButtons
                                    onModalClosed={onClose}
                                    disableBackdropClickFlag={true}
                                    confirmationMessage={props.translateFunc("QUESTIONS_ANSWER_AND_WIN")}
                                    componentContent={
                                        <QuestionCarouselContainer
                                            translateFunc={props.translateFunc}
                                            i18n={props.i18n}
                                            showNotNowButton={true}
                                            showIndexPagination={true} // TODO JS: index
                                            goToHomeFunction={() => { goToHomeFunction(); onClose(); }}
                                            coinRewardForAnsweringQuestions={coinRewardForAnsweringQuestions}
                                            questionCollection={unansweredQuestions} />}
                                />
                            </Provider>
                        );
                    }
                });

            } else {
                moodService.buildMoodDataAndSubmit(
                    false,
                    null,
                    props.currentWeekMoods,
                    goToHomeFunction,
                    props.clearMoodState,
                    null);
            }
        }
    }

    function showCancelConfirmationModal() {
        if (props.hasPendingChanges) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ModalSimpleConfirmation
                            onModalClosed={onClose}
                            onConfirmClicked={onDeleteClick}
                            confirmationMessage={props.translateFunc("GLOBAL_DELETE_CHANGES_QUESTION")}
                            cancelButtonLabel={props.translateFunc("GLOBAL_NO")}
                            confirmButtonLabel={props.translateFunc("GLOBAL_YES")}
                        />
                    );
                }
            });
        }
    }

    function showSubmitPendingChangesModal() {

        if (props.hasPendingChanges) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ModalSimpleConfirmation
                            onModalClosed={onClose}
                            onConfirmClicked={() => { goToHomeFunction() }}
                            confirmationMessage={props.translateFunc("GLOBAL_EXIT_WITHOUT_SUBMIT_QUESTION")}
                            cancelButtonLabel={props.translateFunc("GLOBAL_NO")}
                            confirmButtonLabel={props.translateFunc("GLOBAL_YES")}
                        />
                    );
                }
            });
        }
        else {
            goToHomeFunction();
        }
    }

    function onDeleteClick() {
        props.clearMoodState();
        goToHomeFunction();
    }

    return (
        <div>
            <MoodHeader
                translateFunc={props.translateFunc} i18n={props.i18n}
                canSubmit={props.canSubmit === true && isSubmitAvaiable === true}
                hasPendingChanges={props.hasPendingChanges}
                onReturnClick={showSubmitPendingChangesModal}
                onSubmitClick={handleSubmitClick}
                onDeleteClick={showCancelConfirmationModal}
                isBadgeInvisible={isBadgeInvisible} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        currentWeekMoods: state.moodDetailReducer.currentWeekMoods,
        questionAnswers: state.questionsReducer.questionAnswers,
        hasPendingChanges: state.moodDetailReducer.hasPendingChanges,
        canSubmit: state.moodDetailReducer.canSubmit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearMoodState: () => dispatch(
            moodDetailActions.clearMoodState())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MoodHeaderContainer);