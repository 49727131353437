// Contains the influence calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getInfluencesForSpecificCategory(categoryId) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_INFLUENCE_GETINFLUENCESFORSPECIFICCATEGORY, categoryId),
        "GET")
        .then(response => response);


}


export function getNumberOfInfluencesFilled(categoryId, moodId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_INFLUENCEENTRY_GET_INFENTRIES_FILLED, categoryId, moodId),
        "GET",
        null,
        cacheCallbackData => cacheCallback(cacheCallbackData, categoryId, moodId))
        .then(response => response);


}