
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getActiveCampaign(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_CAMPAIGN_GET_ACTIVE_CAMPAIGN,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getActiveCampaignCurrentCoins(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_CAMPAIGN_GET_ACTIVE_CAMPAIGN_CURRENT_COINS,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getCampaignCoinsFromSpecificUser(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_CAMPAIGN_GET_CAMPAIGN_COINS_SPECIFIC_USER,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function createUpdateOrRemoveCampaignParticipationExclusion(isParticipating) {

  return fetchWithBearerToken(
    stringFormat(
      process.env.REACT_APP_URL_API_CAMPAIGN_EXCLUSION_CREATE_UPDATE_REMOVE_EXCLUSION, isParticipating), "POST");
}

export function isParticipatingInCurrentCampaign(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_CAMPAIGN_EXCLUSION_IS_PARTICIPATING,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}





