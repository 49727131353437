import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { canUserBuyMoreItemStock } from '../../api/happyShopItemService';

export default function HappyShopItemRowElement(props) {

    const history = useHistory();
    const [canUserBuyMoreStock, setCanUserBuyMoreStock] = useState(null);
    const [canShowUserStockLabel, setCanShowUserStockLabel] = useState(false);

    useEffect(() => {
        canUserBuyMoreItemStock(props.itemId, canBuyMoreStockCacheCallback)
            .then(flag => {
                canBuyMoreStockCacheCallback(flag);
            });
    }, [])

    function onElementClicked() {
        history.push(process.env.REACT_APP_ROUTING_PAGE_HAPPY_SHOP_ITEM_DETAIL + props.itemId);
    }

    function canBuyMoreStockCacheCallback(flag) {
        setCanUserBuyMoreStock(flag);
        setCanShowUserStockLabel(true);
    }

    function renderMissingCoinsLabel() {
        if (!props.isInStock || props.canUserAfford || !canUserBuyMoreStock) {
            return;
        }

        let missingCoin = props.userCoinAmount - props.price;

        return (
            <div className="NoCoins">Faltam {Math.abs(missingCoin)} Happycoins</div>);
    }

    return (
        <Card className="HappyShopItem">
            <CardActionArea onClick={onElementClicked}>
                <div className="HappyCoins">{props.price}</div>
                {props.isInStock
                    ? null
                    : <div className="NoStock">indisponível</div>
                }

                {!canUserBuyMoreStock && canShowUserStockLabel
                    ? <div className="NoStock">não podes trocar mais</div>
                    : null
                }

                {renderMissingCoinsLabel()}

                <CardMedia
                    className="Photo"
                    // {props.image}
                    image={props.image == null
                        ? "https://happycorpindex.blob.core.windows.net/images/HappyStoreItem.jpg"
                        : props.image}
                    title={props.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="body1">
                        {props.name}
                    </Typography>
                    <Typography variant="body2">
                        {props.shortDescription}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
