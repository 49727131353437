import * as actionNames from '../actionNames';

export const onMedalUserDetailSelected = (userDetails) => ({
    type: actionNames.SET_SELECTED_MEDALUSERDETAIL,
    userDetails: userDetails
})

export const setMedalDetailComingFromThisUser = (isMedalDetailComingFromLocalUser) => ({
    type: actionNames.SET_MEDALDETAIL_COMINGFROMUSER_FLAG,
    isMedalDetailComingFromLocalUser: isMedalDetailComingFromLocalUser
})


export const setMedalNavHistory = (historyType) => ({
    type: actionNames.SET_MEDAL_NAVHISTORYTYPE,
    historyType: historyType
})

export const updateMedalCategoryAssignedRow = (medalCategoryAssignedRowDetails) => ({
    type: actionNames.UPDATE_MEDAL_CATEGORY_ASSIGNED_ROW,
    medalCategoryAssignedRowDetails: medalCategoryAssignedRowDetails
})

export const resetMedalCategoryAssignedRows = () => ({
    type: actionNames.RESET_STATE_MEDAL_CATEGORY_ASSIGNED_ROWS
})

export const setMedalListSearchValue = (value) => ({
    type: actionNames.SET_MEDAL_SEARCH_VALUE,
    medalListSearchValue: value
})

export const setMedalHistroyBackSettings = (
    isMedalDetailComingFromHighlights,
    highlightHistoryLastPath) => ({
        type: actionNames.SET_MEDAL_HISTORY_BACK_SETTINGS,
        isMedalDetailComingFromHighlights: isMedalDetailComingFromHighlights,
        highlightHistoryLastPath: highlightHistoryLastPath
    })

export const setDirectClickCategoryId = (categoryId) => ({
    type: actionNames.SET_DIRECT_CLICK_CATEGORY_ID,
    categoryId: categoryId
})



