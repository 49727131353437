// Contains the mood calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getAllAvailableItems(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_HAPPY_SHOP_GET_ALL_ITEMS,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getItem(itemId, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_HAPPY_SHOP_GET_ITEM, itemId),
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function canUserBuyMoreItemStock(itemId, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_HAPPY_SHOP_CAN_USER_BUY_ITEM_STOCK, itemId),
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}


export function purchaseItem(itemId) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_HAPPY_SHOP_PURCHASE_ITEM, itemId),
    "POST")
    .then(response => {
      return response;
    });
}




