import React from 'react';
import HistoryHeaderContainer from '../../containers/UserCharts/HistoryHeaderContainer';
import HistoryCoinsListContainer from '../../containers/UserCharts/HistoryCoinsListContainer';


export default function HistoryDetail() {

  return (
    <div className="HistoryDetail">
      <HistoryHeaderContainer />      
      <HistoryCoinsListContainer />
    </div>
  );
}
