import React, { useEffect, useState } from 'react';
import AssignMedalRowElement from '../../components/Medals/AssignMedalRowElement';
import PersonalInfo from '../../components/Medals/PersonalInfo'
import { connect } from 'react-redux';
import * as medalCategoryService from '../../api/medalCategoryService';
import List from '@material-ui/core/List';
import { navIconStyle } from "../../css/globalStyles";
import { formatCurrentAndTotalMedalsAvailable } from '../../utils/utils';
import * as userActions from '../../redux/actions/userActions';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import { getSpecificUserDetails } from '../../api/userService';

function MedalsAssignListContainer(props) {
    const [medalCategories, setMedalCategories] = React.useState();
    const [totalNumberOfAssignedMedals, setTotalNumberOfAssignedMedals] = React.useState(0);    

    const [targetUserDetails, setTargetUserDetails] = useState(null);

    let medalCounter = 0;

    useEffect(() => {
        if (props.routingUserId != null && props.routingUserId != 0) {
            getSpecificUserDetails(props.routingUserId).then(
                details => {
                    setTargetUserDetails(details);

                }
            );
        } else {
            setTargetUserDetails(props.selectedUserMedalDetail);
        }

    }, [])

    useEffect(() => {
        if (targetUserDetails == null) {
            return;
        }

        loadCategories();
        props.setPendingChangesFlag(false);

    }, [targetUserDetails])

    // function setDetails(routingDetails) {
    //     if (routingDetails != null) {
    //         setTargetUserDetails(routingDetails);
    //     }
    //     else {
    //         setTargetUserDetails(props.selectedUserMedalDetail);
    //     }
    // }

    function handlePendingChangesFlag(isAddOperation) {

        if (isAddOperation) {
            medalCounter++;
        } else {
            medalCounter--;
        }

        if (medalCounter === 0) {
            props.setPendingChangesFlag(false);
        } else {
            props.setPendingChangesFlag(true);
        }

        setTotalNumberOfAssignedMedals(medalCounter);
    }

    function loadCategories() {
        let categoriesUpdated = [];

        if (targetUserDetails.currentAssociatedCompany == null) {
            return;
        }


        medalCategoryService.getAllMedalCategoriesForCompany(
            targetUserDetails.currentAssociatedCompany.id,
            setMappedCategoriesToRowElements)
            .then(medalCategories => {
                setMappedCategoriesToRowElements(medalCategories);
            });
    }

    function setMappedCategoriesToRowElements(medalCategories) {
        let categoriesUpdated = [];

        medalCategories.map(category => {

            categoriesUpdated.push(
                <AssignMedalRowElement
                    key={category.id}
                    categoryId={category.id}
                    medalCategoryWeight={category.categoryWeight}
                    appraisalsReceivedFromSystemThisWeek={props.currentUser.appraisalsReceivedFromSystemThisWeek}
                    categoryName={category.name}
                    userId={targetUserDetails.id}
                    fromUserId={props.currentUser.id}
                    handlePendingChangesFunction={handlePendingChangesFlag}
                />
            )
        })
        setMedalCategories(categoriesUpdated);
    }


    return (

        targetUserDetails == null
            ? <div></div>
            : <div><PersonalInfo
                localUser={props.currentUser}
                selectedUserMedalDetail={targetUserDetails}
                historyType={props.historyType}
                company={targetUserDetails.currentAssociatedCompany != null
                    ? targetUserDetails.currentAssociatedCompany
                    : null}
                medalCount={formatCurrentAndTotalMedalsAvailable(
                    props.currentUser.currentAvailableAppraisalsToSpend,
                    props.currentUser.appraisalsReceivedFromSystemThisWeek)}
                medalStyle={navIconStyle.whiteColorTransparency}
                isAssigningMedals={true}
                localUserProfilePicture={props.currentUser.profilePictureFullPath}
                selectedUserProfilePicture={targetUserDetails.profilePictureFullPath} />

                <List className="DetailMedalsList">
                    {medalCategories != null && medalCategories.length
                        ? medalCategories
                        : <LoadingComponent></LoadingComponent>}
                </List>
            </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedUserMedalDetail: state.medalsReducer.selectedUserMedalDetail,
        currentUser: state.userReducer.currentUser,
        historyType: state.medalsReducer.historyType
    };
}




const mapDispatchToProps = (dispatch) => {
    return {
        setPendingChangesFlag: (value) => dispatch(
            userActions.setPendingChangesFlag(value)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MedalsAssignListContainer);