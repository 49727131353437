import React from 'react';
import MedalsAssignHeader from '../../components/Medals/MedalsAssignHeader'
import { connect } from 'react-redux';
import * as medalService from '../../api/medalService';
import { useHistory } from 'react-router-dom';
import * as userActions from '../../redux/actions/userActions';
import * as medalActions from '../../redux/actions/medalActions';
import { confirmAlert } from 'react-confirm-alert';
import ModalSimpleConfirmation from '../../containers/_Global/ModalSimpleConfirmation'
import * as coinService from '../../api/coinService';
import * as modalUtils from '../../utils/modalUtils';
import ModalsHeaderHappycoinsWon from '../../containers/_Global/ModalsHeaderHappycoinsWon';

function MedalsAssignHeaderContainer(props) {
    const history = useHistory();
    /// If the suer clicks "back" and has pending changes
    /// We prompt a modal asking if he wants to submit the current changes
    function showCancelConfirmationModal() {
        if (props.hasPendingChanges) {

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ModalSimpleConfirmation
                            onModalClosed={onClose}
                            onConfirmClicked={() => { clearAppraisals(true) }}
                            confirmationMessage={props.translateFunc("GLOBAL_EXIT_WITHOUT_SUBMIT_QUESTION")}
                            cancelButtonLabel={props.translateFunc("GLOBAL_NO")}
                            confirmButtonLabel={props.translateFunc("GLOBAL_YES")}
                        />
                    );
                }
            });
        } else {
            clearAppraisals(true);
        }
    }

    function showDeleteConfirmationModal() {
        if (props.hasPendingChanges) {

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ModalSimpleConfirmation
                            onModalClosed={onClose}
                            onConfirmClicked={() => { clearAppraisals(true) }}
                            confirmationMessage={props.translateFunc("GLOBAL_DELETE_CHANGES_QUESTION")}
                            cancelButtonLabel={props.translateFunc("GLOBAL_NO")}
                            confirmButtonLabel={props.translateFunc("GLOBAL_YES")}
                        />
                    );
                }
            });
        }
    }

    function clearAppraisals(isToReturnToDetail) {

        medalService.getUserAppraisalsToSpend(
            props.currentUser.id,
            updateAppraisalsAndResetCategoryRows)
            .then(appraisalCount => {
                updateAppraisalsAndResetCategoryRows(appraisalCount);
                if (isToReturnToDetail) {
                    if (props.routingUserId != null && props.routingUserId != 0) {
                        history.push(process.env.REACT_APP_ROUTING_MEDALS_DETAIL + "/" + props.routingUserId);
                    } else {

                        history.push(process.env.REACT_APP_ROUTING_MEDALS_DETAIL);
                    }
                }
            });
    }

    function updateAppraisalsAndResetCategoryRows(appraisalCount) {
        props.updateCurrentAvailableAppraisalsToSpend(appraisalCount);
        props.resetMedalCategoryAssignedRows();
    }

    function handleAssignedMedalsSubmit() {

        // Only submit if the user spend appraisals

        if (props.currentUser.availableAppraisalsBeforeAssign !== props.currentUser.currentAvailableAppraisalsToSpend) {
            medalService.submitAssignedMedalsData(props.medalCategoryAssignedList)
                .then(medalTransactionNotificationDto => {
                    let appraisalAmmount = props.currentUser.availableAppraisalsBeforeAssign - props.currentUser.currentAvailableAppraisalsToSpend;

                    props.setAvailableAppraisalsBeforeAssign(props.currentUser.currentAvailableAppraisalsToSpend);
                    props.resetMedalCategoryAssignedRows();
                    props.setUserGivenAppraisalsThisWeek(true);


                    coinService.giveCoinsToUserFromAppraisalsGiven(appraisalAmmount)
                        .then(coinTransaction => {
                            if (coinTransaction != null && coinTransaction.quantity !== 0) {

                                var titleText = props.translateFunc(
                                    "HAPPYCOIN_REWARD",
                                    { count: coinTransaction.quantity })

                                modalUtils.displayModalImageSimpleConfirm(
                                    <ModalsHeaderHappycoinsWon />,
                                    titleText,
                                    props.translateFunc("GLOBAL_CONTINUE_PARTICIPATION_TO_WIN"),
                                    props.translateFunc("GLOBAL_OK"));
                            }

                            if (medalTransactionNotificationDto.destinyPlayerIds != null
                                && medalTransactionNotificationDto.destinyPlayerIds.length !== 0) {
                                // Send notification
                                medalService.sendNotificationAssignedMedal(medalTransactionNotificationDto);
                            }

                            history.push(process.env.REACT_APP_ROUTING_MEDALS);
                        });

                });
        }
    }

    return (
        <div>
            <MedalsAssignHeader
                i18n={props.i18n}
                translateFunc={props.translateFunc}
                hasPendingChanges={props.hasPendingChanges}
                onClickSubmit={handleAssignedMedalsSubmit}
                onClickReturnFunction={showCancelConfirmationModal}
                onClickDeleteFunction={showDeleteConfirmationModal} />
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        medalCategoryAssignedList: state.medalsReducer.medalCategoryAssignedList,
        currentUser: state.userReducer.currentUser,
        hasPendingChanges: state.userReducer.hasPendingChanges
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentAvailableAppraisalsToSpend: (appraisalCount) => dispatch(
            userActions.updateCurrentAvailableAppraisalsToSpend(appraisalCount)),

        setAvailableAppraisalsBeforeAssign: (appraisalCount) => dispatch(
            userActions.setAvailableAppraisalsBeforeAssign(appraisalCount)),

        resetMedalCategoryAssignedRows: () => dispatch(
            medalActions.resetMedalCategoryAssignedRows()),

        setUserGivenAppraisalsThisWeek: (value) => dispatch(
            userActions.setUserGivenAppraisalsThisWeek(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedalsAssignHeaderContainer);