import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import logo from "../../images/hci_logo.png";
import * as systemService from '../../api/systemService';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function AboutContainer(props) {
    const classes = useStyles();
    const [apiVersion, setApiVersion] = React.useState("0.0.0");

    useEffect(() => {
        systemService.getApiVersion().then(version => {
            setApiVersion(version);
        })
    }, [apiVersion])

    return (
        <div>
            <div className={classes.grow}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/">
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        <Typography className="titleHeader" variant="h6" noWrap>
                            {props.translateFunc("ABOUT_APP_LABEL")}
                        </Typography>
                        <div className={classes.grow} />
                    </Toolbar>
                </AppBar>
            </div>
            <div className="tempContent">
                <div>
                    <span><img alt="" src={logo} width="100" /></span>
                    <div className="title">{props.translateFunc("ABOUT_APP_LABEL")}</div>
                    <div className="text">{props.translateFunc("ABOUT_APP_DESCRIPTION")}</div>
                    <div className="soon">{props.translateFunc("ABOUT_APP_VERSION", { appVersion: process.env.REACT_APP_VERSION })} </div>
                    <div className="soon">{props.translateFunc("ABOUT_API_VERSION", { apiVersion: apiVersion })}</div>

                </div>
            </div>
        </div>
    );
}