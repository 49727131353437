import * as actionNames from '../actionNames';

export const setLastMoodNotificationDate = (date) => ({
    type: actionNames.SET_LAST_MOOD_NOTIFICATION_DATE,
    date: date
})

export const setHasDisplayedMoodNotificationDate = (value) => ({
    type: actionNames.SET_HAS_DISPLAYED_MOOD_NOTIFICATION,
    value: value    
})