import React from 'react';
import Wallpaper from '../../components/_Global/Wallpaper';
import {generateRandomNumber} from '../../utils/utils'
import Background1 from '../../images/n01.jpg';
import Background2 from '../../images/n02.jpg';
import Background3 from '../../images/n03.jpg';
import Background4 from '../../images/n04.jpg';
import Background5 from '../../images/n05.jpg';
import Background6 from '../../images/n06.jpg';
import Background7 from '../../images/n07.jpg';
import Background8 from '../../images/n08.jpg';
import Background9 from '../../images/n09.jpg';
import Background10 from '../../images/n10.jpg';
import Background11 from '../../images/n11.jpg';
import Background12 from '../../images/n12.jpg';
import Background13 from '../../images/n13.jpg';
import Background14 from '../../images/n14.jpg';
import Background15 from '../../images/n15.jpg';
import Background16 from '../../images/n16.jpg';
import Background17 from '../../images/n17.jpg';
import Background18 from '../../images/n18.jpg';
import Background19 from '../../images/n19.jpg';
import Background20 from '../../images/n20.jpg';
import Background21 from '../../images/n21.jpg';
import Background22 from '../../images/n22.jpg';
import Background23 from '../../images/n23.jpg';
import Background24 from '../../images/n24.jpg';
import Background25 from '../../images/n25.jpg';
import Background26 from '../../images/n26.jpg';
import Background27 from '../../images/n27.jpg';
import Background28 from '../../images/n28.jpg';
import Background29 from '../../images/n29.jpg';
import Background30 from '../../images/n30.jpg';


export default function WallpaperContainer(props){

    function getRandomBackgroundImage() {
        let images = [Background1, Background2, Background3, Background4, Background5, Background6, Background7, Background8, Background9, Background10, Background11, Background12, Background13, Background14, Background15, Background16, Background17, Background18, Background19, Background20, Background21, Background22, Background23, Background24, Background25, Background26, Background27, Background28, Background29, Background30];
        var randomNumber = generateRandomNumber(images.length);
        return images[randomNumber];
    }
   
    return(
        <Wallpaper imageToRender={getRandomBackgroundImage()} >
            {props.children}
        </Wallpaper>
    );

}

