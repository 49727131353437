import { createTheme } from "@material-ui/core/styles";

const transparency = "rgba(255,255,255,0.1)"
const montserrat = "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif"

export default createTheme({
  palette: {
    //primary: { main: "#F1B929" },
    //primary: { main: transparency },
    //secondary: { main: "#FFFF8D" }
  },
  typography: {
    fontFamily: montserrat,
  },

  overrides: {
    MuiAvatar: {
      colorDefault: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color:'rgba(255, 255, 255, 0.5)',
      }
    },
    //MuiSvgIcon: {
    //  root: {
    //    color: 'white',
    //  }
    //},
    MuiBottomNavigation: {
      root: {
        position: 'fixed',
        bottom: '0',
        width: '768px',
        backgroundColor: 'rgba(0,0,0,0.3)'
      }
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: '#009FFF',
      },
      colorSecondary: {
        backgroundColor: 'red',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: 'rgba(255,255,255,0.3)',
      },
      label: {
        color: 'white',
        textTransform: 'uppercase',
      }
    },
    MuiFab: {
      root: {
        position: 'fixed',
        bottom: '30px',
        left: '50%',
        zIndex: '100',
        transform: 'translate(-50%, 0)',
      },
      primary: {
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
          backgroundColor: '#FFFF8D!important',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none',
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: 'white',
      },
    },
    MuiTab: {
      root: {
        minWidth: 'auto!important',
        '&$selected': {
          color: '#FFFF8D!important',
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#FFFF8D',
      },
    },
    MuiListItemText: {
      secondary: {
        color: 'rgba(255,255,255,0.5)',
        textTransform: 'none',
        fontSize: '12px',
      },
    },
    MuiButton: {
      root: {
        color: 'white',
        fontFamily: 'Montserrat!important',
      },
      textPrimary: {
        color: 'white',
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'transparent',
        backdropFilter: 'blur(60px) brightness(1.5)'
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: 'transparent',
        backdropFilter: 'blur(60px) brightness(2)'
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'rgba(255,255,255,0.2)',
      }
    },
    MuiBox: {
      root: {
        padding: '0px',
        margin: '0px',
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: transparency,
        boxShadow: 'none',
      },
    },
    MuiTypography: {
      root: {
        textTransform: 'uppercase',
        color: 'white',
      },
      body1: {
        fontSize: '14px',
        fontFamily: 'Montserrat!important',
      },
      subtitle2: {
        textTransform: 'none',
        fontWeight: '300',
      },
      h6: {
        fontSize: '16px!important',
        fontFamily: 'Montserrat!important',
      },
    },
    MuiList: {
      root: {
        //falta
      },
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
      }
    },
    MuiListItem: {
      root: {
        backgroundColor: transparency,
        borderBottom: 'solid 1px rgba(255,255,255,0.3)',
        minHeight: '55px',
        paddingTop: '6px',
        paddingBottom: '6px',
        flexWrap: 'wrap',
      },
    },
    MuiListItemAvatar: {
      root: {
        //falta
      }
    },
    MuiGrid: {
      item: {
        padding: '0px!important',
      },
      'spacing-xs-2': {
        width: '100%',
        margin: '0px'
      }
    },
    MuiIconButton: {
      root: {
        color: 'white!important',
        padding: '4px',
      },
      edgeEnd: {
        marginRight: '0px',
      },
      edgeStart: {
        marginLeft: '0px',
      }
    },
    MuiInput: {
      inputMultiline: {
        color: 'rgba(255,255,255,0.5)',
        fontSize: '14px',
      },
    },
  }
});