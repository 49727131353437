import * as actionNames from '../actionNames';

const initialState = {

    directClickCategoryId: 0, // Clicking on the category row now directs to detail with +1 in that category
    isMedalDetailComingFromLocalUser: false,// Clicking the medal icon sets this flag to false, clicking the number the icon sets to true
    isMedalDetailComingFromHighlights: false,
    highlightHistoryLastPath: "",
    historyType: "GENERAL",
    medalCategoryAssignedList: [], // 'medalCategoryAssignedRow' array
    medalListSearchValue: "",
    selectedUserMedalDetail: {
        id: 0,
        name: null,
        currentAssociatedCompany: null,
        receivedMedalCount: 0,
        assignedMedalCount: 0,
        totalMedalsStyle: null,
        profilePictureFullPath: null
    }
}

const medalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionNames.SET_SELECTED_MEDALUSERDETAIL:
            return {
                ...state,
                selectedUserMedalDetail: {
                    id: action.userDetails.userId,
                    name: action.userDetails.userName,
                    currentAssociatedCompany: action.userDetails.currentAssociatedCompany,
                    receivedMedalCount: action.userDetails.receivedMedalCount,
                    assignedMedalCount: action.userDetails.assignedMedalCount,
                    totalMedalsStyle: action.userDetails.totalMedalsStyle,
                    profilePictureFullPath: action.userDetails.profilePictureFullPath
                }
            }
        case actionNames.SET_MEDALDETAIL_COMINGFROMUSER_FLAG:
            return {
                ...state,
                isMedalDetailComingFromLocalUser: action.isMedalDetailComingFromLocalUser,
                historyType: "GENERAL"
            }

        case actionNames.SET_MEDAL_NAVHISTORYTYPE:
            return {
                ...state,
                historyType: action.historyType
            }

        case actionNames.UPDATE_MEDAL_CATEGORY_ASSIGNED_ROW:
            return {
                ...state,
                medalCategoryAssignedList:
                    updateMedalCategoryAssignedRow(
                        state.medalCategoryAssignedList,
                        action.medalCategoryAssignedRowDetails)
            }
        case actionNames.RESET_STATE_MEDAL_CATEGORY_ASSIGNED_ROWS:
            return {
                ...state,
                medalCategoryAssignedList: []
            }
        case actionNames.SET_MEDAL_SEARCH_VALUE:
            return {
                ...state,
                medalListSearchValue: action.medalListSearchValue
            }
        case actionNames.SET_MEDAL_HISTORY_BACK_SETTINGS:
            return {
                ...state,
                isMedalDetailComingFromHighlights: action.isMedalDetailComingFromHighlights,
                highlightHistoryLastPath: action.highlightHistoryLastPath
            }
        case actionNames.SET_DIRECT_CLICK_CATEGORY_ID:
            return {
                 ...state,
                 directClickCategoryId: action.categoryId
             }

        default:
            return state;

    }

};

/// Updates the medal category assigned medal count
function updateMedalCategoryAssignedRow(currentRowList, rowDetails) {

    // Get the previous list and copy it (immmutable state updating)
    let rowElementsUpdated = currentRowList.map(m => m);

    // Iterate through the list and update existing values
    for (let index = 0; index < rowElementsUpdated.length; index++) {
        const medalCategoryRow = rowElementsUpdated[index];

        if (medalCategoryRow.categoryId === rowDetails.categoryId) {
            medalCategoryRow.assignedAppraisalsCount = rowDetails.assignedAppraisalsCount;
        }
    }

    // If we added another row, we can insert it after the iteration
    if (rowElementsUpdated.map(m => m.categoryId).indexOf(rowDetails.categoryId) < 0) {

        let newRow = {
            categoryId: rowDetails.categoryId,
            fromUserId: rowDetails.fromUserId,
            toUserId: rowDetails.toUserId,
            assignedAppraisalsCount: rowDetails.assignedAppraisalsCount
        };

        rowElementsUpdated.push(newRow);
    }

    return rowElementsUpdated;
}

export default medalsReducer;