import React from 'react';

export default function RedirectIfRequired(props) {
    return (
        <div>
            {redirectIfRequired()}
        </div>
    );
}

function redirectIfRequired() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('l');
    if (redirectUrl) {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        console.warn("going to :" +baseUrl + redirectUrl);
        window.location.replace(baseUrl + redirectUrl);
    }
   
}