import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { ReactComponent as ModalIcon } from '../../images/Icons/five_medals.svg';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions';


const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

function ConfigurationsContainer(props) {
    const classes = useStyles();
    const history = useHistory();

    function resetApp() {
        localStorage.removeItem("persist:root");
        history.push("/");
        window.location.reload();
    }

    function onLanguageButtonClick(lang) {
        props.setUserLanguage(lang);
        props.i18n.changeLanguage(lang);
    }


    return (
        <div>
            <div className={classes.grow}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/">
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        <Typography className="titleHeader" variant="h6" noWrap>
                            {props.translateFunc("CONFIG_TITLE")}
                        </Typography>
                        <div className={classes.grow} />
                    </Toolbar>
                </AppBar>
            </div>
            <div className="tempContent">
                <div>
                    <span><ModalIcon /></span>
                    <div className="title">{props.translateFunc("CONFIG_TITLE")}</div>
                    <div className="text">{props.translateFunc("CONFIG_DESCRIPTION")}</div>

                    <div className='onesignal-customlink-container'></div>
                    <br />
                    <Button
                        className="btInstall"
                        size="small"
                        variant="contained"
                        disableElevation
                        onClick={() => { resetApp() }}>
                        {props.translateFunc("CONFIG_RESET_APP_TITLE")}
                    </Button>
                </div>

                <div>
                    <Button
                        style={{ backgroundColor: '#009FFF' }}
                        size="small"
                        variant="contained"
                        disableElevation
                        onClick={() => { onLanguageButtonClick("en") }}>
                        English
                    </Button>
                    <br />
                    <br />
                    <Button
                        style={{ backgroundColor: '#009FFF' }}
                        size="small"
                        variant="contained"
                        disableElevation
                        onClick={() => { onLanguageButtonClick("pt") }}>
                        Português
                    </Button>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {

    return {
        setUserLanguage: (lang) => dispatch(
            userActions.setUserLanguage(lang)
        )
    };
};

export default connect(null, mapDispatchToProps)(ConfigurationsContainer);