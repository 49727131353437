import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import Search from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function MedalsCategoryHeader() {
    const classes = useStyles();

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <Link to="/">
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                    <Typography className="titleHeader"variant="h6" noWrap>
                        Nome da categoria
                    </Typography>
                    <div className={classes.grow} />
                    <IconButton aria-label="Pesquisar" color="inherit">
                        <Search />
                    </IconButton>
                    <IconButton aria-label="Mais" color="inherit">
                        <MoreVertIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </div>
    );
}
