import * as actionNames from '../actionNames';

export const insertOrUpdateQuestion = (questionData) => ({
    type: actionNames.INSERT_UPDATE_QUESTION,
    questionData: questionData
})

export const clearQuestionData = () => ({
    type: actionNames.CLEAR_QUESTION_DATA
})

