import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { fetchWithBearerToken, stringFormat } from '../../utils/utils';
import { connect } from 'react-redux';
import { saveUserProfilePicture, deleteProfilePicture } from '../../api/userService';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { generateGUID } from '../../utils/utils';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateType,
    FilePondPluginImageEdit,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform);//FilePondPluginImagePreview,



const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

function UserPhotoEditorContainer(props) {
    const classes = useStyles();
    const [profilePicture, setProfilePicture] = React.useState();

    const [selectedUserId, setSelectedUserId] = React.useState(0);
    const [allUsers, setAllUsers] = React.useState([]);
    let pond = props.pond;
    const transformVariants = ({
        'thumb_small_': transforms => {
            transforms.resize = {
                size: {
                    width: 128,
                    height: 128,
                },
            };
            return transforms;
        }
    });


    useEffect(() => {
        fetchWithBearerToken(
            stringFormat(process.env.REACT_APP_URL_API + "/User/GetAllUsers/"),
            "GET")
            .then(users => {
                setAllUsers(users);
            });

    }, [])

    function getUserProfilePicture(userId) {
        if (userId == null && userId === 0) {
            return;
        }

        fetchWithBearerToken(
            stringFormat(process.env.REACT_APP_URL_API_USER_GET_PICTURE, userId),
            "GET")
            .then(pictureURL => {
                setProfilePicture(pictureURL);
            });
    }

    function deleteUserProfilePicture() {
        deleteProfilePicture().then(() => {
            setProfilePicture(null);
        });
    }

    const handleDropdownChange = (event) => {
        setSelectedUserId(event.target.value);
        getUserProfilePicture(event.target.value);
    };

    function renderDropdownOptions() {
        let options = []

        allUsers.forEach(user => {
            options.push(
                <option key={user.id} value={user.id}>{user.name}</option>);
        });

        return (
            options
        );
    }

    function renderUsersDropdown() {
        if (allUsers == null || allUsers.length === 0) {
            return;
        }

        return (
            <div>
                <FormControl variant="filled" className={classes.formControl} >
                    <InputLabel style={{ color: 'white' }} htmlFor="age-native-simple">Utilizador</InputLabel>
                    <Select
                        style={{ color: 'red' }}
                        native
                        value={selectedUserId}
                        onChange={handleDropdownChange}
                        inputProps={{
                            name: 'selectedUserId',
                            id: 'age-native-simple',
                        }}
                    >
                        <option aria-label="None" value="" />
                        {renderDropdownOptions()}

                    </Select>
                </FormControl>
            </div>
        );


    }

    return (
        <div>

            <div>
                <script src="https://unpkg.com/filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.js"></script>
                <script src="https://unpkg.com/filepond/dist/filepond.js"></script>
            </div>


            <div className={classes.grow}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/">
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        <Typography className={classes.title} variant="h6" noWrap>
                            •• Página temporária •• Dev only ••
                        </Typography>
                        <div className={classes.grow} />
                    </Toolbar>
                </AppBar>
            </div>

            <div className="tempContent">
                <div>
                    {renderUsersDropdown()}
                    <div className="title">A sua foto</div>
                    <div id="profilePictureArea">

                        {profilePicture != null
                            ? <img id="ItemPreview" alt="user"
                                src={profilePicture} />
                            : null}

                        <br />
                        <br />

                        <FilePond ref={ref => pond = ref}
                            maxFiles={1}
                            allowImageTransform={true}
                            imageTransformOutputMimeType={'image/jpeg'}
                            imageTransformOutputQuality={65}
                            allowMultiple={false}
                            allowImageEdit={true}
                            allowImageCrop={true}
                            allowImageResize={true}
                            allowFileTypeValidation={true}
                            imageResizeMode={'cover'}
                            imageResizeUpscale={false}
                            imageCropAspectRatio={'1:1'}
                            imageResizeTargetWidth={768}
                            imageResizeTargetHeight={768}
                            styleImageEditButtonEditItemPosition={"bottom center"}
                            labelIdle={'INSERIR UMA FOTO'}
                            acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
                            labelFileTypeNotAllowed={"O tipo de ficheiro não é suportado"}
                            fileValidateTypeLabelExpectedTypes={"Tipos de ficheiro suportados: .jpg .jpeg .png"}
                            //server=""
                            //oninit={}
                            imageTransformVariants={transformVariants}
                            onaddfile={(error, file) => {
                            }}
                            onpreparefile={(fileItem, outputFiles) => {
                                let fileSharedGuid = generateGUID();
                                outputFiles.forEach((image) => {
                                    if (image.name === "thumb_small_") {
                                        let thumbName = image.file.name.replace('.', '_t.');
                                        image.file.name = thumbName;
                                    }

                                    var reader = new FileReader();
                                    reader.readAsDataURL(image.file);

                                    reader.onloadend = function () {
                                        var base64data = reader.result;
                                        let userProfilePicture = {
                                            data: base64data,
                                            fileName: fileSharedGuid,
                                            isThumbnail: false
                                        };

                                        if (image.name !== "thumb_small_") {
                                            setProfilePicture(base64data);
                                        } else {
                                            userProfilePicture.isThumbnail = true;
                                        }

                                        saveUserProfilePicture(userProfilePicture)
                                            .then(pictureUrl => {
                                                var user = props.currentUser;

                                                if (image.name !== "thumb_small_") {
                                                    user.profilePictureFullPath = pictureUrl;

                                                } else {
                                                    user.profilePictureThumbnailPath = pictureUrl;
                                                }
                                            });
                                    }
                                });
                            }}
                            onupdatefiles={fileItems => {
                            }}>
                        </FilePond>

                        <div className="soon">A imagem é gravada automaticamente</div>
                        {
                            profilePicture != null
                                ? <button onClick={deleteUserProfilePicture} className="btInstall deletePhoto">Apagar a foto</button>
                                : null
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser
    }
}

export default connect(mapStateToProps)(UserPhotoEditorContainer);