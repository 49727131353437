import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import PersonalInfo from '../../components/Medals/PersonalInfo';
import { navIconStyle } from "../../css/globalStyles";
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import { useTranslation } from 'react-i18next';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        //backgroundColor: 'rgba(255, 255, 255, 1)',
        //width: 500,
    },
    title: {
        color: 'white',
    },

}));

export default function MedalsDetailTabsContainer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [receivedAssignedIndexTabValue, setReceivedAssignedIndexTabValue] = React.useState(0);

    const { t, i18n } = useTranslation();

    let translateFunc = t;
    let i18nEngine = i18n;


    const handleChange = (event, newValue) => {
        setReceivedAssignedIndexTabValue(newValue);
    };

    const handleChangeIndex = index => {
        setReceivedAssignedIndexTabValue(index);
    };

    useEffect(() => {
        if (props.translateFunc == null || props.i18n == null) {
            translateFunc = t;
            i18nEngine = i18n;
        } else {
            translateFunc = props.translateFunc;
            i18nEngine = props.i18n;
        }
    }, [])

    function renderReceivedMedalsFromCompany(isSelected) {
        if (isSelected === true) {
            if (props.receivedMedalsRowElements != null
                && props.receivedMedalsRowElements.length) {

                return props.receivedMedalsRowElements;
            } else {
                return <LoadingComponent></LoadingComponent>;
            }
        }
    }

    function renderAssignedMedalsFromCompany(isSelected) {
        if (isSelected === true) {
            if (props.assignedMedalsRowElements != null
                && props.assignedMedalsRowElements.length) {
                return props.assignedMedalsRowElements;
            } else {
                return <LoadingComponent></LoadingComponent>;
            }
        }
    }

    function renderPersonalInfo() {        
        if (props.localUserDetail == null && props.selectedUserMedalDetail == null) {
            return;
        }

        const medalCount = receivedAssignedIndexTabValue === 0
            ? props.totalReceivedMedalCount
            : props.totalAssignedMedalCount;

        const medalStyle = receivedAssignedIndexTabValue === 0
            ? props.historyType === "GENERAL"
                ? props.totalMedalsStyle
                : medalCount === 0
                    ? navIconStyle.whiteColorTransparency
                    : navIconStyle.whiteColor
            : medalCount === 0
                ? navIconStyle.whiteColorTransparency
                : navIconStyle.whiteColor;

        return (<PersonalInfo
            isViewingReceivedMedals={receivedAssignedIndexTabValue === 0}
            isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser}
            localUserProfilePicture={props.localUserDetail.profilePictureFullPath}
            selectedUserProfilePicture={props.selectedUserMedalDetail.profilePictureFullPath}
            localUser={props.localUserDetail}
            historyType={props.historyType}
            selectedUserMedalDetail={props.selectedUserMedalDetail}
            company={props.localUserDetail.currentAssociatedCompany != null
                ? props.localUserDetail.currentAssociatedCompany
                : null}
            medalCount={medalCount}
            medalStyle={medalStyle} />);

    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={receivedAssignedIndexTabValue}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab
                        label={props.historyType === "GENERAL"
                            ? translateFunc("MEDALS_TABS_RECEIVED")
                            : translateFunc("MEDALS_TABS_GIVEN_BY_YOU")}
                        {...a11yProps(0)}
                        className={classes.title} />

                    <Tab
                        label={props.historyType === "GENERAL"
                            ? translateFunc("MEDALS_TABS_GIVEN")
                            : translateFunc("MEDALS_TABS_GIVEN_TO_YOU")}
                        {...a11yProps(1)}
                        className={classes.title} />
                </Tabs>
            </AppBar>

            {renderPersonalInfo()}

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={receivedAssignedIndexTabValue}
                onChangeIndex={handleChangeIndex}
            >
                {/* RECEIVED MEDALS */}
                <TabPanel value={receivedAssignedIndexTabValue} index={0} dir={theme.direction} className="nopadding">
                    <List className="DetailMedalsList2">
                        {renderReceivedMedalsFromCompany(receivedAssignedIndexTabValue === 0)}
                    </List>
                </TabPanel>


                {/* ASSIGNED MEDALS */}
                <TabPanel value={receivedAssignedIndexTabValue} index={1} dir={theme.direction} className="nopadding">
                    <List className="DetailMedalsList2">
                        {renderAssignedMedalsFromCompany(receivedAssignedIndexTabValue === 1)}
                    </List>
                </TabPanel>

            </SwipeableViews>
        </div>
    );
}