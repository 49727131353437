import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal'
import { authProvider } from './auth/authProvider';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react';
import Loading from './components/_Global/Loading';
import { store } from './redux/reducers/store';
import './i18next';

// dotenv-expand
// https://github.com/motdotla/dotenv-expand
var dotenv = require('dotenv')
var dotenvExpand = require('dotenv-expand')

var myEnv = dotenv.config()
dotenvExpand(myEnv)

export const persistor = persistStore(store);

ReactDOM.render(

    <AzureAD provider={authProvider} forceLogin={true}>
        {
            ({ login, logout, authenticationState, error, accountInfo }) => {

                // https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        return (
                            <Provider store={store}>
                                <BrowserRouter>
                                    <PersistGate persistor={persistor}>
                                        <App
                                            logoutFunction={logout}
                                            name={accountInfo.account.name}
                                            email={accountInfo.account.userName} />
                                    </PersistGate>
                                </BrowserRouter>
                            </Provider>
                        );
                    default:
                        return (
                            <Loading isToUseWhiteLoader={true} />
                        );

                }
            }
        }
    </AzureAD >,
    document.getElementById('root')

)

