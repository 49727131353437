import React, { PureComponent } from 'react';
import {
  BarChart, Bar, ResponsiveContainer, YAxis, Tooltip
} from 'recharts';

function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} - ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
}

export default function BarChartHistory(props) {

  return (
    <div className={"BarChartHistory " + props.chartColor}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={props.data}
          margin={{ top: 0, right: 0, left: -35, bottom: 0, }}
        >
          <Bar dataKey="uv" fill="white" background={{ fill: 'rgba(255,255,255,0.1)' }} />
          <YAxis type="number" domain={[0, props.maxValueYaxis != null ? props.maxValueYaxis : 'dataMax']} />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(255, 255, 255, 0.4)' }}/>

        </BarChart>

      </ResponsiveContainer>
    </div>
  );
}
