import React from "react";
import MoodSelectionHorizontal from '../../components/Mood/MoodSelectionHorizontal';
import MoodInfluenceCategoryContainer from '../../containers/Mood/MoodInfluenceCategoryContainer';
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import { connect } from 'react-redux';
import { daysOfTheWeekNamesEN } from '../../utils/utils';

function MoodInputContainer(props) {

    let moodDayName = daysOfTheWeekNamesEN[props.dayOfTheWeekIndex];

    function renderMoodSelectionHorizontal() {
        let selectedMoodRating = null;
        let moodDay = null;

        if (props.associatedMood != null && props.associatedMood.id != null) {

            if (props.associatedMood.selectedMoodRating !== 0) {
                selectedMoodRating = props.associatedMood.selectedMoodRating;
            }
            else {
                selectedMoodRating = props.associatedMood.moodRatingFromDb;
            }

            moodDay = props.associatedMood.diaryDate;



            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let d = new Date(moodDay);
            moodDay = d.toLocaleDateString(props.i18n.language, options);

            return (

                <MoodSelectionHorizontal
                    translateFunc={props.translateFunc} i18n={props.i18n}
                    moodDay={moodDay}
                    onClickFunction={handleMoodSelection}
                    selectedMoodRating={selectedMoodRating} />
            );
        }

        return (

            <MoodSelectionHorizontal
                onClickFunction={handleMoodSelection}
                selectedMoodRating={selectedMoodRating} />
        );

    }

    function handleMoodSelection(moodRating) {

        props.onMoodSelected(moodRating, moodDayName);
    }

    function renderContent() {

        if (props.isSelected === true) {

            return (
                <div>
                    {renderMoodSelectionHorizontal()}

                    <MoodInfluenceCategoryContainer
                        i18n={props.i18n}
                        translateFunc={props.translateFunc}
                        associatedMood={props.associatedMood} />
                </div>
            );
        }

        return (<div />);
    }

    return (
        renderContent()
    );
}

const mapDispatchToProps = dispatch => {

    return {
        onMoodSelected: (moodValue, moodDayName) => dispatch(
            moodDetailActions.onMoodSelected(moodValue, moodDayName))
    };
};

const mapStateToProps = (state) => {

    return {
        selectedDayOfTheWeekIndex: state.moodDetailReducer.selectedDayOfTheWeekIndex,
        currentWeekMoods: state.moodDetailReducer.currentWeekMoods
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MoodInputContainer)

