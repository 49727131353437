import React, { useState, useEffect } from 'react';
import HistoryIconCard from '../../components/History/HistoryIconCard';
import HistoryChartCard from '../../components/History/HistoryChartCard';
import { ReactComponent as MoodIconAwful } from '../../images/Icons/moodStroke1.svg';
import { ReactComponent as MoodIconBad } from '../../images/Icons/moodStroke2.svg';
import { ReactComponent as MoodIconOk } from '../../images/Icons/moodStroke3.svg';
import { ReactComponent as MoodIconGood } from '../../images/Icons/moodStroke4.svg';
import { ReactComponent as MoodIconFantastic } from '../../images/Icons/moodStroke5.svg';
import { getMoodAverage, getMoodsSubmissionLastDaysAmount, getLastWeekAmountMoodAverage } from '../../api/moodService';
import { formatDate } from '../../utils/utils';
import { navIconStyle } from "../../css/globalStyles";

export default function UserChartCardMood(props) {
  const [moodAverage, setMoodAverage] = useState();
  const [moodDailySubmissionData, setMoodDailySubmissionData] = useState();
  const [moodWeeklyAverageData, setMoodWeeklyAverageData] = useState();

  let amountOfDaysToRetrieve = 14;
  let amountOfWeeksToRetrieve = 9;

  useEffect(() => {
    // TODO JS: remove hardcoded dates from here
    let beginDate = formatDate(new Date(2018, 1, 1));
    let endDate = formatDate(new Date(2035, 1, 1));

    getMoodAverage(beginDate, endDate, fixMoodNumber)
      .then(moodAvg => {
        fixMoodNumber(moodAvg);
      });

    getLastWeekAmountMoodAverage(amountOfWeeksToRetrieve, buildMoodWeeklyAverageData).then(data => {
      buildMoodWeeklyAverageData(data);
    });

    getMoodsSubmissionLastDaysAmount(amountOfDaysToRetrieve, buildMoodDailySubmissionData).then(data =>
      buildMoodDailySubmissionData(data));

  }, [])

  function fixMoodNumber(moodAvg) {

    if (Number.isInteger(moodAvg)) {
      setMoodAverage(moodAvg);
    }
    else {
      let parsedNumber = parseFloat(moodAvg).toFixed(1);
      if (parsedNumber !== "NaN") {
        setMoodAverage(parsedNumber);
      }
    }
  }

  function buildMoodDailySubmissionData(moodData) {
    var finalData = [];


    moodData.forEach(m => {
      if (m.id === 0) {
        finalData.push({
          name: m.diary.dateValue, uv: 0
        });
      } else {
        finalData.push({
          name: m.diary.dateValue, uv: m.moodType.value
        });
      }


    });

    setMoodDailySubmissionData(finalData);
  }


  function buildMoodWeeklyAverageData(moodData) {
    var finalData = [];


    moodData.forEach(m => {
      var date = m.weekStartAndEndDate.item1 + " - " + m.weekStartAndEndDate.item2;
      finalData.push({
        name: date, uv: m.moodAverage
      });

    });

    setMoodWeeklyAverageData(finalData);
  }

  function getMoodIconBasedOnMoodAverage(moodAvg) {

    if (moodAvg >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_FANTASTIC) {
      return (
        <MoodIconFantastic style={{ ...navIconStyle.whiteColor, ...navIconStyle.svgSize }} />
      );

    } else if (moodAvg >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_GOOD) {
      return (
        <MoodIconGood style={{ ...navIconStyle.whiteColor, ...navIconStyle.svgSize }} />
      );

    } else if (moodAvg >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_OK) {
      return (
        <MoodIconOk style={{ ...navIconStyle.whiteColor, ...navIconStyle.svgSize }} />
      );

    } else if (moodAvg >= process.env.REACT_APP_MOOD_AVERAGE_LIMIT_BAD
      || moodAvg > process.env.REACT_APP_MOOD_AVERAGE_LIMIT_AWFUL) {
      return (
        <MoodIconAwful style={{ ...navIconStyle.whiteColor, ...navIconStyle.svgSize }} />
      );
    }

  }

  return (
    <div className="HistoryCards">
      <HistoryIconCard cardIcon={getMoodIconBasedOnMoodAverage(moodAverage)} number={moodAverage} />

      <HistoryChartCard
        chartColor={"orangeColorBgTransparency"}
        data={moodDailySubmissionData}
        maxValueYaxis={5}
        description={props.translateFunc("CHART_MOOD_DAILY_DESCRIPTION")} />

      <HistoryChartCard
        maxValueYaxis={5}
        chartColor={"orangeColorBgTransparency"}
        data={moodWeeklyAverageData}
        description={props.translateFunc("CHART_MOOD_WEEKLY_DESCRIPTION")} />
      <div className="divSpacer" />
    </div>
  );
}
