// Contains the influence entry calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';


export function getInfluenceEntryForInfluence(influenceId) {

    const url = stringFormat(
        process.env.REACT_APP_URL_API_INFLUENCEENTRY_GETINFLUENCEENTRYFORINFLUENCE, influenceId);

    return fetchWithBearerToken(url, "GET")
        .then(response => response);
}

export function getAllInfluenceEntriesForCategory(categoryId, moodId, cacheCallback) {

    const url = stringFormat(
        process.env.REACT_APP_URL_API_INFLUENCEENTRY_GETINFLUENCEENTRRIESFORCATEGORY, categoryId, moodId);
    
    // Extra callback argument
    // https://stackoverflow.com/questions/40802071/pass-an-extra-argument-to-a-callback-function
    return fetchWithBearerToken(url, "GET", null, callbackData => cacheCallback(callbackData, moodId))
        .then(response => response);
}


export function getPreviousInfluenceEntryListClone(currentMoodId, selectedMoodDay) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_INFLUENCEENTRY_GET_PREVIOUS_CLONE_INFENTRIES, currentMoodId, selectedMoodDay),
        "GET")
        .then(response => response);


}

