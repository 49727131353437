import React from 'react';
import AssignAddWhiteIcon from '../../components/Medals/AssignAddWhiteIcon'

export default function AssingMedalsAdd(props) {

  return (
    <div className="MedalsNumberStar">
      <AssignAddWhiteIcon onPraiseAssignClick={props.onPraiseAssignClick}/>
    </div>
  );
}