import React from 'react';
import HistoryHeaderContainer from '../../containers/UserCharts/HistoryHeaderContainer';
import HistoryMoodListContainer from '../../containers/UserCharts/HistoryMoodListContainer';


export default function HistoryDetail() {

  return (
    <div className="HistoryDetail">
      <HistoryHeaderContainer />     
      
      <HistoryMoodListContainer />
    </div>
  );
}
