import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { generateGUID } from '../../utils/utils';
import { ReactComponent as HappyCoinIcon } from '../../images/Icons/happycoins_won.svg';

export default function MoodQtOne(props) {

    const [selectedAnswer, setSelectedAnswer] = React.useState(
        props.preselectedAnswer != null && props.preselectedAnswer[0] != null
            ? props.preselectedAnswer[0].possibleAnswerId
            : null);

    const [chips, setChips] = React.useState();
    const [coinRewardOpacity, setCoinRewardOpacity] = React.useState('30%');

    const handleClick = (answerId) => {
        if (selectedAnswer !== answerId) {
            setSelectedAnswer(answerId);

            let newAnswerObj = {
                questionAnswerEntryId: props.preselectedAnswer != null && props.preselectedAnswer.length
                    ? props.preselectedAnswer[0].questionAnswerEntryId
                    : 0,
                possibleAnswerId: answerId,
                questionId: props.questionId,
            }
            props.answerCallback(newAnswerObj, true);
        }
        setCoinRewardOpacity('100%');
    };

    useEffect(() => {
        if (props.possibleAnswers) {
            buildChips();
        }
    }, [selectedAnswer])

    function buildChips() {
        let answers = [];
        if (props.possibleAnswers) {
            var answersPromise = props.possibleAnswers.map(answerObj => {
                answers.push(
                    <Chip
                        key={generateGUID()}
                        variant={selectedAnswer === answerObj.possibleAnswer.id ? 'outlined' : 'default'}
                        size="medium"
                        label={answerObj.possibleAnswer.value}
                        onClick={() => handleClick(answerObj.possibleAnswer.id)}
                    />
                );
            });
            Promise.all([answersPromise]).then(() => {
                setChips(answers);
            })
        }

    }

    function renderCoinRewardSection() {
        return (
            <div className="Medals" style={{ opacity: coinRewardOpacity }}>
                <HappyCoinIcon />
                <div>{props.coinRewardForAnsweringQuestions}</div>
            </div>);
    }

    return (
        <div className="qtOne">
            <div className="txt">{props.questionText}</div>
            {props.preselectedAnswer != null ? props.preselectedAnswer.questionAnswerEntryId : null}
            {chips}
            {renderCoinRewardSection()}
        </div>
    );
}