import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { FilePond, registerPlugin, create } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { connect } from 'react-redux';
import { getProfilePicture, saveUserProfilePicture, deleteProfilePicture } from '../../api/userService';
import { generateGUID } from '../../utils/utils';
import * as userActions from '../../redux/actions/userActions';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateType,
    FilePondPluginImageEdit,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform);//FilePondPluginImagePreview,



const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

function PersonalDataContainer(props) {
    const classes = useStyles();
    const [profilePicture, setProfilePicture] = React.useState();

    const transformVariants = ({
        'thumb_small_': transforms => {
            transforms.resize = {
                size: {
                    width: 128,
                    height: 128,
                },
            };
            return transforms;
        }
    });

    let pond = props.pond;

    useEffect(() => {

        if (props.currentUser != null) {
            getProfilePicture(props.currentUser.id, setProfilePicture)
                .then(image => {
                    setProfilePicture(image);
                });
        }
    }, [])

    function _arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    function deleteUserProfilePicture() {
        deleteProfilePicture().then(() => {
            setProfilePicture(null);
            var user = props.currentUser;
            user.profilePictureFullPath = null;
            user.profilePictureThumbnailPath = null;
            props.setCurrentUser(user);
        });
    }

    return (
        <div>

            <div>
                <script src="https://unpkg.com/filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.js"></script>
                <script src="https://unpkg.com/filepond/dist/filepond.js"></script>


            </div>
            <div className={classes.grow}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/">
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        <Typography className={classes.title} variant="h6" noWrap>
                            {props.translateFunc("PERSONAL_DATA_LABEL")}
                        </Typography>
                        <div className={classes.grow} />
                    </Toolbar>
                </AppBar>
            </div>
            <div className="tempContent">
                <div>
                    <div className="title">{props.translateFunc("PERSONAL_DATA_YOUR_PHOTO")}</div>
                    <div id="profilePictureArea">

                        <img id="ItemPreview" src={profilePicture} />

                        <br />
                        <br />

                        <FilePond ref={ref => pond = ref}
                            maxFiles={1}
                            allowImageTransform={true}
                            imageTransformOutputMimeType={'image/jpeg'}
                            imageTransformOutputQuality={65}
                            allowMultiple={false}
                            allowImageEdit={true}
                            allowImageCrop={true}
                            allowImageResize={true}
                            allowFileTypeValidation={true}
                            imageResizeMode={'cover'}
                            imageResizeUpscale={false}
                            imageCropAspectRatio={'1:1'}
                            imageResizeTargetWidth={768}
                            imageResizeTargetHeight={768}
                            styleImageEditButtonEditItemPosition={"bottom center"}
                            labelIdle={props.translateFunc("PERSONAL_DATA_UPLOAD_PHOTO")}
                            acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
                            labelFileTypeNotAllowed={"O tipo de ficheiro não é suportado"}
                            fileValidateTypeLabelExpectedTypes={"Tipos de ficheiro suportados: .jpg .jpeg .png"}
                            imageTransformVariants={transformVariants}
                            onaddfile={(error, file) => {
                            }}
                            onpreparefile={(fileItem, outputFiles) => {
                                let fileSharedGuid = generateGUID();
                                outputFiles.forEach((image) => {
                                    if (image.name === "thumb_small_") {
                                        let thumbName = image.file.name.replace('.', '_t.');
                                        image.file.name = thumbName;
                                    }

                                    var reader = new FileReader();
                                    reader.readAsDataURL(image.file);

                                    reader.onloadend = function () {
                                        var base64data = reader.result;
                                        let userProfilePicture = {
                                            data: base64data,
                                            fileName: fileSharedGuid,
                                            isThumbnail: false
                                        };

                                        if (image.name !== "thumb_small_") {
                                            setProfilePicture(base64data);
                                        } else {
                                            userProfilePicture.isThumbnail = true;
                                        }

                                        saveUserProfilePicture(userProfilePicture)
                                            .then(pictureUrl => {
                                                var user = props.currentUser;

                                                if (image.name !== "thumb_small_") {
                                                    user.profilePictureFullPath = pictureUrl;

                                                } else {
                                                    user.profilePictureThumbnailPath = pictureUrl;
                                                }

                                                props.setCurrentUser(user);
                                            });
                                    }
                                });


                            }}

                            onupdatefiles={fileItems => {
                            }}>
                        </FilePond>

                        <div className="soon"> {props.translateFunc("PERSONAL_DATA_PHOTO_SAVE_AUTOMATICALLY_DESCRIPTION")}</div>
                        {
                            profilePicture != null
                                ? <button onClick={deleteUserProfilePicture} className="btInstall deletePhoto">{props.translateFunc("PERSONAL_DATA_DELETE_PHOTO")}</button>
                                : null
                        }

                    </div>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser
    }
}


const mapDispatchToProps = dispatch => {

    return {
        setCurrentUser: (userDetails) => dispatch(
            userActions.setCurrentUser(userDetails)
        )
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataContainer);