import React from 'react';
import CoinsHeaderContainer from '../../containers/Coins/CoinsHeaderContainer';
import CoinsTabsContainer from '../../containers/Coins/CoinsTabsContainer';

export default function CoinsContainer(props) {

    return (
        <div>            
            <CoinsHeaderContainer translateFunc={props.translateFunc} i18n={props.i18n}/>
            <CoinsTabsContainer translateFunc={props.translateFunc} i18n={props.i18n}/>
        </div>
    );
}