import React from 'react';
import { ReactComponent as CoinsIcon } from '../../images/Icons/coins.svg';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

export default function ConTransactionRowElement(props) {

  return (
    <ListItem>

      <ListItemAvatar>
        <Avatar>
          <CoinsIcon />
        </Avatar>

      </ListItemAvatar>

      <ListItemText primary={props.titleLabel} secondary={props.timeStamp} />
      {props.isPurchase
        ? <div className="Coins red"> {props.quantity}</div>
        : <div className="Coins">+{props.quantity}</div>}

    </ListItem>
  );
}