import React from 'react';
import PersonalInfoRelations from '../../components/Medals/PersonalInfoRelations'
import PersonalInfoName from '../../components/Medals/PersonalInfoName'
import MedalsNumberStar from '../../components/Medals/MedalsNumberStar'
import SelectedUserAvatar from '../../images/Icons/NoPhoto.svg';
import CompanyLogo from '../../components/_Global/CompanyLogo';
import Avatar from '@material-ui/core/Avatar';
import NoPhoto from '../../images/Icons/NoPhoto.svg';
import { calculateUserNameHashColor } from '../../utils/utils';


export default function PersonalInfo(props) {
  let backgroundPictureToRender = props.isMedalDetailComingFromLocalUser
    ? props.localUserProfilePicture
    : props.selectedUserProfilePicture;

  let selectedUserThumbnailAvatar = props.selectedUserProfilePicture != null ? props.selectedUserProfilePicture.replace('.jpg', '_t.jpg') : null;


  let userName = getUserName();
  let userNameHashColor = calculateUserNameHashColor(userName);

  function renderAvatar(sourceImage, hashColor) {
    if (sourceImage == null) {
      return (
        <Avatar
          className="NoPhotoAvatar"
          alt={userName}
          src={SelectedUserAvatar}
          style={{
            background:
              'linear-gradient(135deg, hsl(' + hashColor + ', 25%, 80%) 0%, hsl(' + hashColor + ', 30%, 50%) 100%)'
          }} />
      );
    } else {
      return (
        <Avatar alt={userName} src={sourceImage} />
      );
    }
  }

  function getPersonalRelationsLeftImage() {
    if (props.isAssigningMedals) {
      let selectedUserName = props.localUser.name;
      let selectedUserHashColor = calculateUserNameHashColor(selectedUserName);

      return (renderAvatar(props.localUserProfilePicture, selectedUserHashColor));
    }

    if (props.historyType === "GENERAL") {
      if (props.isViewingReceivedMedals) {
        return (<CompanyLogo companyLogo={props.company != null ? props.company.logoURL : null} />);
      }
      else {

        return (renderAvatar(selectedUserThumbnailAvatar, userNameHashColor));
      }
    }
    else if (props.historyType === "COMMON") {
      if (props.isViewingReceivedMedals) {
        // Here we want to see the medasl RECEIVED BY the LOCAL user
        let selectedUserName = props.localUser.name;
        let selectedUserHashColor = calculateUserNameHashColor(selectedUserName);
        return (renderAvatar(props.localUserProfilePicture, selectedUserHashColor));
      }
      else {
        // Here we want to see how many medals we ASSIGNED to LOCAL user
        return (renderAvatar(selectedUserThumbnailAvatar, userNameHashColor));
      }
    }
  }

  function getPersonalRelationsRightImage() {
    if (props.isAssigningMedals) {

      return (renderAvatar(selectedUserThumbnailAvatar, userNameHashColor));
    }


    if (props.historyType === "GENERAL") {
      if (props.isViewingReceivedMedals) {
        return (renderAvatar(selectedUserThumbnailAvatar, userNameHashColor));
      }
      else {

        return (<CompanyLogo companyLogo={props.company != null ? props.company.logoURL : null} />);
      }
    }
    else if (props.historyType === "COMMON") {
      if (props.isViewingReceivedMedals) {
        return (renderAvatar(selectedUserThumbnailAvatar, userNameHashColor));
      }
      else {
        if (props.localUser != null) {

          let selectedUserName = props.localUser.name;
          let selectedUserHashColor = calculateUserNameHashColor(selectedUserName);
          return (renderAvatar(props.localUserProfilePicture, selectedUserHashColor));
        }
      }
    }

  }

  function getUserName() {
    if (props.isMedalDetailComingFromLocalUser) {
      if (props.localUser != null) {
        return props.localUser.name;
      }
    }
    else {
      if (props.selectedUserMedalDetail != null) {
        return props.selectedUserMedalDetail.name;
      }
    }

    return "";
  }

  function renderPersonalRelations() {

    if (props.isMedalDetailComingFromLocalUser) {
      return null;
    } else {
      return (
        <PersonalInfoRelations
          leftImage={getPersonalRelationsLeftImage()}
          rightImage={getPersonalRelationsRightImage()} />);
    }
  }



  function renderUserProfilePicture() {

    if (backgroundPictureToRender != null && backgroundPictureToRender !== '') {
      // If the user has profile picture
      return (
        <div className="PersonalInfo" style={{ backgroundImage: "url(" + backgroundPictureToRender + ")" }}>
          <div className="PersonalInfoContent">
            {renderPersonalRelations()}

            <PersonalInfoName
              userName={userName}
              companyName={props.company != null ? props.company.name : null} />

            <MedalsNumberStar medalCount={props.medalCount} medalStyle={props.medalStyle} />
          </div>
          <div className="PersonalInfoDark" />
        </div >);

    } else {
      // No profile picture↓
      return (
        <div className="PersonalInfo PersonalInfoNoPhoto" style={{
          background:
            'linear-gradient(135deg, hsl(' + userNameHashColor + ', 25%, 80%) 0%, hsl(' + userNameHashColor + ', 30%, 50%) 100%)'
        }}>
          <img className="NoPhoto" src={NoPhoto} alt={userName} />
          <div className="PersonalInfoContent">
            {renderPersonalRelations()}

            <PersonalInfoName
              userName={userName}
              companyName={props.company != null ? props.company.name : null} />

            <MedalsNumberStar medalCount={props.medalCount} medalStyle={props.medalStyle} />
          </div>
          <div className="PersonalInfoDark" />
        </div>
      );
    }
  }

  return (
    <div>
      {renderUserProfilePicture()}
    </div>

  );
}