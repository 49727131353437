import React from 'react';
import { ReactComponent as ModalIcon } from '../../images/Icons/happycoins.svg';

export default function CoinsCampaignMycoins(props) {

    return (
        <div className="MyCoins">
            <div className="txt">{props.translateFunc("CAMPAIGN_YOUR_CONTRIBUTION_LABEL")}</div>
            <ModalIcon />
            <div className="number">{props.userContribution}</div>
        </div>
    );
}