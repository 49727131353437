import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as medalActions from '../../redux/actions/medalActions';
import HelpIcon from '@material-ui/icons/Help';
import { showTutorialPopup } from '../../utils/modalUtils';
import TutorialCard from '../Tutorials/TutorialCard';
import tutorialImageStep1 from '../../images/tutorialImages/0201.png'
import tutorialImageStep2 from '../../images/tutorialImages/0202.png'
import tutorialImageStep3 from '../../images/tutorialImages/0203.png'


const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));



function MedalsHeader(props) {
    const classes = useStyles();
    let homepageTutorialCardsContent = [];

    homepageTutorialCardsContent.push(
        <div key={1}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep1} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MERIT_BOARD_1")} />
        </div>);

    homepageTutorialCardsContent.push(
        <div key={2}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep2} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MERIT_BOARD_2")} />
        </div>);

    homepageTutorialCardsContent.push(
        <div key={3}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep3} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MERIT_BOARD_3")} />
        </div>);

    function clearMedalListSearchValue() {
        props.setMedalListSearchValue("");
    }

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <Link onClick={clearMedalListSearchValue} to="/">
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                    <Typography className="titleHeader" variant="h6" noWrap>
                        {props.translateFunc("MEDALS_MURAL_HEADER_TITLE")}
                    </Typography>
                    <div className={classes.grow} />
                    {/* <IconButton aria-label="Pesquisar" color="inherit">
                        <SearchIcon />
                    </IconButton>
                    <IconButton aria-label="Mais" color="inherit">
                        <MoreVertIcon />
                    </IconButton> */}
                    <IconButton aria-label="info here" color="inherit" onClick={() => {
                        showTutorialPopup(homepageTutorialCardsContent, props.translateFunc("TUTORIAL_HOMEPAGE_MERIT_BOARD_TITLE"), props.i18n, props.translateFunc);
                    }}>
                        <HelpIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMedalListSearchValue: (value) => dispatch(
            medalActions.setMedalListSearchValue(value)
        )
    }
}

export default connect(null, mapDispatchToProps)(MedalsHeader)
