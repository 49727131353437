import React from 'react';
import MoodHeaderContainer from '../../containers/Mood/MoodHeaderContainer';
import MoodHeaderDaysContainer from '../../containers/Mood/MoodHeaderDaysContainer';

export default function MoodDetailContainer(props) {

    return (
        <div>            
            <MoodHeaderContainer translateFunc={props.translateFunc} i18n={props.i18n} />
            <MoodHeaderDaysContainer translateFunc={props.translateFunc} i18n={props.i18n}/>
        </div>
    );
}