import React, { useState, useEffect } from 'react';
import HistoryIconCard from '../../components/History/HistoryIconCard';
import HistoryChartCard from '../../components/History/HistoryChartCard';
import { ReactComponent as StarsIcon } from '../../images/Icons/stars.svg';
import { getAcquiredMedals, getAcquiredMedalsLastDays, getAcquiredMedalsLastWeeks } from '../../api/medalService';
import { navIconStyle } from "../../css/globalStyles";

export default function UserChartCardMedals(props) {
  const [currentUserMedals, setCurrentUserMedals] = useState();
  const [acquiredMedalsLastDaysData, setAcquiredMedalsLastDaysData] = useState();
  const [acquiredMedalsLastWeeksData, setAcquiredMedalsLastWeeksData] = useState();

  let lastDaysAmount = 14;
  let numberOfWeeksToGet = 9;

  useEffect(() => {
    getAcquiredMedals(setCurrentUserMedals).then(m =>
      setCurrentUserMedals(m));

    getAcquiredMedalsLastDays(lastDaysAmount, buildAcquiredMedalsLastDaysData).then(data =>
      buildAcquiredMedalsLastDaysData(data));

    getAcquiredMedalsLastWeeks(numberOfWeeksToGet, buildAcquiredMedalsLastWeeksData).then(d => {
      buildAcquiredMedalsLastWeeksData(d);
    });
  }, [])

  function buildAcquiredMedalsLastDaysData(data) {
    var finalData = [];

    data.forEach(medalCount => {
      finalData.push({
        name: null, uv: medalCount
      });
    });

    setAcquiredMedalsLastDaysData(finalData);
  }

  function buildAcquiredMedalsLastWeeksData(data) {
    var finalData = [];

    data.forEach(m => {
      var date = m.weekStartAndEndDate.item1 + " - " + m.weekStartAndEndDate.item2;
      finalData.push({
        name: date, uv: m.medalQuantity
      });
    });

    setAcquiredMedalsLastWeeksData(finalData);
  }

  return (
    <div className="HistoryCards">
      <HistoryIconCard
        cardIcon={<StarsIcon style={{ ...navIconStyle.whiteColor }} />}
        number={currentUserMedals} />

      <HistoryChartCard
        chartColor={"greenColorBgTransparency "}
        data={acquiredMedalsLastDaysData}
        description={props.translateFunc("CHART_MEDALS_DAILY_DESCRIPTION")} />

      <HistoryChartCard
        chartColor={"greenColorBgTransparency "}
        data={acquiredMedalsLastWeeksData}
        description={props.translateFunc("CHART_MEDALS_WEEKLY_DESCRIPTION")} />
      <div className="divSpacer" />
    </div>
  );
}
