import React, { useState, useEffect, useRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AssingMedalsAdd from './AssingMedalsAdd';
import AssingMedalsAddSubtract from './AssingMedalsAddSubtract';
import * as userActions from '../../redux/actions/userActions';
import * as medalActions from '../../redux/actions/medalActions';
import IconButton from '@material-ui/core/IconButton';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ModalsComments from '../../containers/_Global/ModalsComments';
import { connect } from 'react-redux';

function AssignMedalRowElement(props) {

    const [isFirstEntry, setIsFirstEntry] = React.useState(true);

    const [currentRowAssignedAppraisals, setCurrentRowAssignedAppraisals] = React.useState(0);

    const [commentModalOpenned, setCommentModalOpenned] = useState(false);
    const [commentModalTextValue, setCommentModalTextValue] = useState("");

    const categoryRef = useRef();

    const openCommentModal = () => {
        setCommentModalOpenned(true);
    };

    const closeCommentModal = (textValue) => {
        setCommentModalTextValue(textValue)
        setCommentModalOpenned(false);
    };

    useEffect(() => {
        // If the direct click is for this current category
        // we increment +1
        if (props.directClickCategoryId === props.categoryId) {
            updateFirstEntryFlag(false);
            scrollToCategory();
        }
    }, [])

    function handlePraiseAssignClick(isAddOperation) {

        let newAppraisalCount = props.currentUser.currentAvailableAppraisalsToSpend;

        if (isAddOperation) {
            if (props.currentUser.currentAvailableAppraisalsToSpend > 0
                && currentRowAssignedAppraisals <= props.currentUser.appraisalsReceivedFromSystemThisWeek) {
                // Add operation means we are incrementing a praise
                // So we want to subtract its count
                newAppraisalCount--;
                props.handlePendingChangesFunction(true);
                let newRowAssignedAppraisalCount = currentRowAssignedAppraisals + 1;
                setCurrentRowAssignedAppraisals(newRowAssignedAppraisalCount);
                props.updateUserCurrentAppraisalsToSpend(newAppraisalCount);

                let assignedRowDetails = {
                    categoryId: props.categoryId,
                    fromUserId: props.fromUserId,
                    toUserId: props.userId,
                    assignedAppraisalsCount: newRowAssignedAppraisalCount
                }

                props.updateMedalCategoryAssignedRow(assignedRowDetails);
            }
        }
        else {
            if (props.currentUser.currentAvailableAppraisalsToSpend < props.currentUser.appraisalsReceivedFromSystemThisWeek
                && currentRowAssignedAppraisals > 0) {

                // ...the opposite for removing an incremented praise
                newAppraisalCount++;
                props.handlePendingChangesFunction(false);

                let newRowAssignedAppraisalCount = currentRowAssignedAppraisals - 1;

                if (newRowAssignedAppraisalCount === 0) {
                    setIsFirstEntry(true);
                }

                setCurrentRowAssignedAppraisals(newRowAssignedAppraisalCount);
                props.updateUserCurrentAppraisalsToSpend(newAppraisalCount);

                let assignedRowDetails = {
                    categoryId: props.categoryId,
                    fromUserId: props.fromUserId,
                    toUserId: props.userId,
                    assignedAppraisalsCount: newRowAssignedAppraisalCount
                }

                props.updateMedalCategoryAssignedRow(assignedRowDetails);
            }
        }
    }

    function updateFirstEntryFlag(value) {
        if (props.currentUser.currentAvailableAppraisalsToSpend > 0) {
            setIsFirstEntry(value);
            // The first time we click the plus icon we also want to increment 1 medal
            handlePraiseAssignClick(true);
        }
    }

    function renderAssignedSection() {
        if (isFirstEntry) {
            return (
                <AssingMedalsAdd
                    onPraiseAssignClick={updateFirstEntryFlag.bind(this, false)} />
            );
        }
        else {
            return (
                <AssingMedalsAddSubtract
                    medalCategoryWeight={props.medalCategoryWeight}
                    onPraiseAssignClick={handlePraiseAssignClick}
                    appraisalsReceivedFromSystemThisWeek={props.appraisalsReceivedFromSystemThisWeek}
                    assignedAppraisalsCount={currentRowAssignedAppraisals} />
            );
        }
    }
    function renderChatBubbltIcon() {
        if (!isFirstEntry) {
            return (
                <IconButton aria-label="comment" style={{ marginRight: '10px' }} onClick={openCommentModal}>
                    {commentModalTextValue.length ? <ChatBubbleIcon /> : <ChatBubbleOutlineIcon />}
                </IconButton>
            );
        }
        else {
            return null;
        }
    }

    function scrollToCategory() {
        categoryRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
    
    return (
        <ListItem ref={categoryRef}>
            <ListItemAvatar>
                <div>
                    {renderChatBubbltIcon()}
                    {commentModalOpenned
                        ? <ModalsComments
                            pictureToDisplay={props.currentUser.profilePictureFullPath}
                            userName={props.currentUser.name}
                            preFilledValue={commentModalTextValue}
                            onModalClosed={closeCommentModal} />
                        : null}
                </div>
            </ListItemAvatar>
            <ListItemText primary={props.categoryName} />
            {renderAssignedSection()}
        </ListItem>
    );
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser,
        directClickCategoryId: state.medalsReducer.directClickCategoryId
    };
}


const mapDispatchToProps = dispatch => {

    return {
        updateUserCurrentAppraisalsToSpend: (appraisalCount) => dispatch(
            userActions.updateCurrentAvailableAppraisalsToSpend(appraisalCount)),

        updateMedalCategoryAssignedRow: (assignedRowDetails) => dispatch(
            medalActions.updateMedalCategoryAssignedRow(assignedRowDetails))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AssignMedalRowElement);