import React, { useEffect, useRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MedalsNumberStar from './MedalsNumberStar'
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as medalService from '../../api/medalService';
import { calculateUserNameHashColor, trimStringAccordingToDeviceWidth } from '../../utils/utils';
import SelectedUserAvatar from '../../images/Icons/NoPhoto.svg';


export default function MedalsUserRowElement(props) {

  const [medalStyle, setMedalStyle] = React.useState();
  let userNameHashColor = calculateUserNameHashColor(props.userName);
  const userRowRef = useRef();


  useEffect(() => {
    scrollToUser();
    setMedalStyle(medalService
      .getStartColorBasedOnRanking(props.userRanking, props.receivedMedalCount, props.totalUserCount));

  }, [])

  function getUserDetails() {
    const userDetails = {
      userId: props.userId,
      userName: props.userName,
      currentAssociatedCompany: props.currentAssociatedCompany,
      receivedMedalCount: props.receivedMedalCount,
      assignedMedalCount: props.assignedMedalCount,
      totalMedalsStyle: medalStyle,
      profilePictureFullPath: props.profilePictureFullPath
    }
    return userDetails;
  }

  function scrollToUser() {
    if (props.lastUserIdSelected == props.userId) {
      userRowRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
  }

  function renderAvatar() {
    if (props.profilePictureFullPath == null) {
      return (
        <Avatar
          className="NoPhotoAvatar"
          alt={props.userName}
          src={SelectedUserAvatar}
          style={{
            background:
              'linear-gradient(135deg, hsl(' + userNameHashColor + ', 25%, 80%) 0%, hsl(' + userNameHashColor + ', 30%, 50%) 100%)'
          }} />
      );
    } else {
      return (
        <Avatar alt={props.userName} src={props.profilePictureThumbnailPath} />
      );
    }
  }

  return (
    <ListItem
      ref={userRowRef}
      button
      disabled={props.isDisabled}
      onClick={props.handleArrowDetailClick.bind(this, getUserDetails())}>

      <ListItemAvatar>
        {renderAvatar()}
      </ListItemAvatar>

      <ListItemText
        primary={trimStringAccordingToDeviceWidth(props.userName)}
        secondary={props.currentAssociatedCompany.name} />

      <IconButton edge="end" aria-label="Ver detalhes">
        <ChevronRightIcon />
      </IconButton>

      {
        <MedalsNumberStar
          medalCount={props.receivedMedalCount}
          userId={props.userId}
          companyId={props.currentAssociatedCompany.id}
          medalStyle={medalStyle}
        />
      }

    </ListItem>
  );
}