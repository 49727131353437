import React from 'react';
import HistoryIcon from '../../components/History/HistoryIcon';
import HistoryNumber from '../../components/History/HistoryNumber';

export default function HistoryIconCard(props) {

  return (
      <div className="HistoryIconCard">
        <HistoryIcon cardIcon={props.cardIcon}/>
        <HistoryNumber number={props.number}/>
      </div>
  );
}
