import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import ModalsHeaderHappycoins from '../../containers/_Global/ModalsHeaderHappycoins';
import ModalsHeaderHowWasYourDay from '../../containers/_Global/ModalsHeaderHowWasYourDay';
import ModalsHeaderDailyObjective from '../../containers/_Global/ModalsHeaderDailyObjective';
import ModalsHeaderWeeklyObjective from '../../containers/_Global/ModalsHeaderWeeklyObjective';
import ModalsHeaderNews from '../../containers/_Global/ModalsHeaderNews';
import ModalsHeaderQuiz from '../../containers/_Global/ModalsHeaderQuiz';
import ModalsHeaderNewLevelOrange from '../../containers/_Global/ModalsHeaderNewLevelOrange';
import ModalsHeaderNewLevelGreen from '../../containers/_Global/ModalsHeaderNewLevelGreen';
import ModalsHeaderNewLevelBlue from '../../containers/_Global/ModalsHeaderNewLevelBlue';
import ModalsHeaderNewMedal from '../../containers/_Global/ModalsHeaderNewMedal';
import ModalsHeaderFiveMedals from '../../containers/_Global/ModalsHeaderFiveMedals';
import ModalsHeaderHappycoinsWon from '../../containers/_Global/ModalsHeaderHappycoinsWon';
import ModalsHeaderHappycoinsWonMore from '../../containers/_Global/ModalsHeaderHappycoinsWonMore';
import { useTranslation } from 'react-i18next';


export default function Modals() {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [open9, setOpen9] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);
  const [open11, setOpen11] = React.useState(false);
  const [open12, setOpen12] = React.useState(false);
  const [open13, setOpen13] = React.useState(false);
  const [open14, setOpen14] = React.useState(false);

  const { t, i18n } = useTranslation();

  const ClickOpen1 = () => {
    setOpen1(true);
  };
  const ClickOpen2 = () => {
    setOpen2(true);
  };
  const ClickOpen3 = () => {
    setOpen3(true);
  };
  const ClickOpen4 = () => {
    setOpen4(true);
  };
  const ClickOpen5 = () => {
    setOpen5(true);
  };
  const ClickOpen6 = () => {
    setOpen6(true);
  };
  const ClickOpen7 = () => {
    setOpen7(true);
  };
  const ClickOpen8 = () => {
    setOpen8(true);
  };
  const ClickOpen9 = () => {
    setOpen9(true);
  };
  const ClickOpen10 = () => {
    setOpen10(true);
  };
  const ClickOpen11 = () => {
    setOpen11(true);
  };
  const ClickOpen12 = () => {
    setOpen12(true);
  };
  const ClickOpen13 = () => {
    setOpen13(true);
  };
  const ClickOpen14 = () => {
    setOpen14(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const handleClose6 = () => {
    setOpen6(false);
  };
  const handleClose7 = () => {
    setOpen7(false);
  };
  const handleClose8 = () => {
    setOpen8(false);
  };
  const handleClose9 = () => {
    setOpen9(false);
  };
  const handleClose10 = () => {
    setOpen10(false);
  };
  const handleClose11 = () => {
    setOpen11(false);
  };
  const handleClose12 = () => {
    setOpen12(false);
  };
  const handleClose13 = () => {
    setOpen13(false);
  };
  const handleClose14 = () => {
    setOpen14(false);
  };

  function toggleLanguage() {

    if (i18n.language === "en") {
      i18n.changeLanguage("pt");
    } else if (i18n.language === "pt") {

      i18n.changeLanguage("en");
    }
  }


  return (
    <div>
      <div>
        <button onClick={toggleLanguage}>Toggle language</button>
        <p>
          <strong>            
            {t('HAPPYCOIN_REWARD', {count: 1}) }
          </strong>
        </p>
      </div>
      <Button variant="outlined" color="primary" onClick={ClickOpen1}>
        Ganhar mais Happycoins
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen2}>
        Como foi o seu dia hoje?
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen3}>
        Atingiu o objetivo diário!
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen4}>
        Atingiu o objetivo semanal!
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen5}>
        Notícia da empresa
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen6}>
        Responda e ganhe
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen7}>
        Nível Laranja
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen8}>
        Nível Verde
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen9}>
        Nível Azul
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen10}>
        Recebeu 1 medalha!
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen11}>
        Ainda tem 5 medalhas
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen12}>
        Ganhou 20 happycoins!
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen13}>
        Ganhou +20 happycoins!
      </Button>
      <br />
      <Button variant="outlined" color="primary" onClick={ClickOpen14}>
        Responder a questões
      </Button>
      <br />

      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderHappycoins />
        <DialogTitle id="alert-dialog-title">{"Ganhar mais Happycoins"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Assista a um curto vídeo e ganhe 30 Happycoins.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose1} color="primary" autoFocus>
            ver
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderHowWasYourDay />
        <DialogTitle id="alert-dialog-title">{"Como foi o seu dia hoje?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Não se esqueça de indicar como está e o que o influenciou!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose2} color="primary" autoFocus>
            Ir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderDailyObjective />
        <DialogTitle id="alert-dialog-title">{"Atingiu o objetivo diário!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Continue assim! No final da semana ganhará 20 Hapycoins!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderWeeklyObjective />
        <DialogTitle id="alert-dialog-title">{"Atingiu o objetivo semanal!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parabéns! Esta semana preencheu todos os dias. Continue assim!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open5}
        onClose={handleClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderNews />
        <DialogTitle id="alert-dialog-title">{"Notícia da empresa"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Não te esqueças que tens de preencher o formulário de férias até dia 15 de março
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose5} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose5} color="primary" autoFocus>
            Ir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open6}
        onClose={handleClose6}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderQuiz />
        <DialogTitle id="alert-dialog-title">{"Responda e ganhe"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Responda a esta pergunta e ganhe 20 moedas
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose6} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose6} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open7}
        onClose={handleClose7}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderNewLevelOrange />
        <DialogTitle id="alert-dialog-title">{"Alcançou um novo Nível!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parabéns! Continue a participar para manter o seu nível!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary">
            <FacebookIcon />
          </Button>
          <Button color="primary">
            <LinkedInIcon />
          </Button>
          <Button onClick={handleClose7} color="primary">
            Ver
          </Button>
          <Button onClick={handleClose7} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open8}
        onClose={handleClose8}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderNewLevelGreen />
        <DialogTitle id="alert-dialog-title">{"Alcançou um novo Nível!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parabéns! Continue a participar para manter ou aumentar o seu nível!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary">
            <FacebookIcon />
          </Button>
          <Button color="primary">
            <LinkedInIcon />
          </Button>
          <Button onClick={handleClose8} color="primary">
            Ver
          </Button>
          <Button onClick={handleClose8} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open9}
        onClose={handleClose9}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderNewLevelBlue />
        <DialogTitle id="alert-dialog-title">{"Alcançou um novo Nível!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parabéns! Continue a participar para manter ou aumentar o seu nível!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary">
            <FacebookIcon />
          </Button>
          <Button color="primary">
            <LinkedInIcon />
          </Button>
          <Button onClick={handleClose9} color="primary">
            Ver
          </Button>
          <Button onClick={handleClose9} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open10}
        onClose={handleClose10}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderNewMedal />
        <DialogTitle id="alert-dialog-title">{"Recebeu 1 medalha!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parabéns! Jorge chaves atribuiu-lhe uma medalha de Profissionalismo.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose10} color="primary">
            Ver
          </Button>
          <Button onClick={handleClose10} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open11}
        onClose={handleClose11}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderFiveMedals />
        <DialogTitle id="alert-dialog-title">{"Ainda tem 5 medalhas"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Não se esqueça de as atribuir aos colegas que as merecem...
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose11} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose11} color="primary" autoFocus>
            Atribuir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open12}
        onClose={handleClose12}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderHappycoinsWon />
        <DialogTitle id="alert-dialog-title">{"Ganhou 20 happycoins!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Queres ganhar mais 20 happycoins? Assiste a um curto vídeo...
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose12} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose12} color="primary" autoFocus>
            Ver
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open13}
        onClose={handleClose13}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <ModalsHeaderHappycoinsWonMore />
        <DialogTitle id="alert-dialog-title">{"Ganhou +20 happycoins!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Obrigado por assistir Ganhou o dobro das happycoins que tinha direito!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose13} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open14}
        onClose={handleClose14}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modals"
      >
        <DialogTitle id="alert-dialog-title">{"Responde e ganha"}</DialogTitle>
        <DialogContent>
          {/*<MoodQuestionContainer/> comentado para não dar erro por faltarem props. Falta também o Carousel */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose14} color="primary">
            Agora não
          </Button>
          <Button onClick={handleClose14} color="primary" autoFocus>
            Submeter
          </Button>
        </DialogActions>
      </Dialog>


      {/* <ModalsComments preFilledValue={""} onModalClosed={handleClose14}/> */}

    </div>
  );
}
