import React from 'react';
import { navIconStyle } from "../../css/globalStyles";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';

export default function AssignSubtractIcon(props) {

  return (
    <IconButton
      edge="end"
      aria-label="Remover medalha"
      onClick={props.onPraiseAssignClick.bind(this, false)}>
      <RemoveCircleIcon style={{ ...navIconStyle.blueColor, ...navIconStyle.svgSizeList }} />
    </IconButton>
  );
}