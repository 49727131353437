import React from 'react';
import { ReactComponent as ModalIcon } from '../../images/Icons/blue_level.svg';

export default function ModalsHeaderNewLevelBlue() {

  return (
    <div>
      <div className="ModalsHeader blue">
        <div>
          <ModalIcon />
        </div>
      </div>
      <div className="ModalsHeaderShadow"></div>
    </div>
  );
}
