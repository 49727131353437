import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as newsService from '../../api/newsService';
import parse from 'html-react-parser';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import * as coinService from '../../api/coinService';
import * as modalUtils from '../../utils/modalUtils';
import ModalsHeaderHappycoinsWon from '../../containers/_Global/ModalsHeaderHappycoinsWon';
import ReactStars from "react-rating-stars-component";

function NewsDetailContainer(props) {

    const [directLinkNewsTitle, setDirectLinkNewsTitle] = useState("");
    const [newsImageURL, setNewsImageURL] = useState("");
    const [directLinkNewsMessageContent, setDirectLinkNewsMessageContent] = useState("");
    const [newsDateCreated, setNewsDateCreated] = useState();
    const [canShowNewsFromDirectLink, setCanShowNewsFromDirectLink] = useState(false);
    const [videoId, setVideoId] = useState();
    const [newsRating, setNewsRating] = useState(null);
    const [canShowStarRatingForced, setCanShowStarRatingForced] = useState(false);

    // The news Id can come from the direct link or from the list.
    const [trueNewsId, setTrueNewsId] = useState(0);
    const [canAwardCoinFromNewsRating, setCanAwardCoinFromNewsRating] = useState(false);

    useEffect(() => {
        let actualNewsId = props.newsId != null ? props.newsId : props.selectedNews.id;
        setTrueNewsId(actualNewsId);

        if (actualNewsId != null) {
            newsService.saveNewsAsRead(actualNewsId);

            newsService.getNewsById(actualNewsId, handleNewsDetailData)
                .then(news => {
                    handleNewsDetailData(news);
                });
        }

    }, [])

    useEffect(() => {
        let actualNewsId = props.newsId != null ? props.newsId : props.selectedNews.id;
        setTrueNewsId(actualNewsId);

        if (actualNewsId != null) {

            newsService.getNewsRatingValueFromUser(actualNewsId, handleSetNewsRating)
                .then(n => { handleSetNewsRating(n); });

            newsService.isNewsReadByUser(actualNewsId).then(userReadNews => {

                if (!userReadNews) {
                    coinService.giveCoinsToUserFromReadingNews().then(coinTransaction => {
                        if (coinTransaction != null && coinTransaction.quantity !== 0) {

                            var titleText = coinTransaction.quantity === 1
                                ? "Ganhaste " + 1 + " Happycoin"
                                : "Ganhaste " + coinTransaction.quantity + " Happycoins";

                            modalUtils.displayModalImageSimpleConfirm(
                                <ModalsHeaderHappycoinsWon />,
                                titleText,
                                "Consulta todas as nossas notícias e artigos para ganhares mais.",
                                "Ok");
                        }

                    });

                }
            });
        }
    }, [])

    function handleSetNewsRating(newsRatingObj) {
        setNewsRating(newsRatingObj);

        // client side verification to allow coin award
        if (newsRatingObj == null || newsRatingObj.id == 0) {
            setCanAwardCoinFromNewsRating(true);
        }
    }

    function handleNewsDetailData(news) {
        setCanShowNewsFromDirectLink(true);
        setNewsDetails(news);

    }

    function setNewsDetails(news) {

        if (news != null) {
            setCanShowStarRatingForced(true);
            setDirectLinkNewsTitle(news.title);
            setNewsDateCreated(new Date(news.dateCreated).toLocaleDateString());
            setDirectLinkNewsMessageContent(news.messageContent);
            setNewsImageURL(news.imageURL);
            setVideoId(news.videoId);
        }
    }

    /// Renders the news block coming from the news list
    function renderNewsFromList() {

        return (
            <div className="NewsTxtDetail">
                <div className="NewsTitle">{props.selectedNews != null ? props.selectedNews.title : ""}</div>
                <div className="NewsText">{props.selectedNews != null ? parse(props.selectedNews.messageContent) : ""}</div>
                <br />
                <div className="NewsText">{new Date(props.selectedNews.dateCreated).toLocaleDateString()}</div>
                {canShowStarRatingForced ? renderStarRating() : null}
            </div>
        );
    }

    /// Renders the news block from a direct link with a news ID
    function renderNewsFromDirectLink() {
        if (!canShowNewsFromDirectLink) {
            return (<LoadingComponent />);
        }

        return (

            <div className="NewsTxtDetail">

                <div className="NewsTitle">{directLinkNewsTitle}</div>
                <div className="NewsText">{parse(directLinkNewsMessageContent)}</div>

                <br />

                <div className="NewsText">{newsDateCreated}</div>
                {renderStarRating()}

            </div>
        );
    }

    function renderMedia() {
        if (videoId) {
            return (
                <iframe width="100%" height="100%"
                    src={"https://www.youtube.com/embed/" + videoId}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />);
        } else {

        }
        return (<img src={newsImageURL ? newsImageURL : props.selectedNews.imageURL} />);
    }

    function renderStarRating() {
        if (newsRating == null) {
            return;
        }

        return (
            <div class="newsStars">
                <h2>{props.translateFunc("NEWS_RATING_YOUR_OPINION_QUESTION")}</h2>
                <h3>Responda e ganhe mais 1 HappyCoin</h3>
                <ReactStars
                    value={newsRating != null ? newsRating.rating : 0}
                    count={5}
                    onChange={onStarRatingClicked}
                    size={36}
                    emptyIcon={<i className="far fa-star"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    color="#c7c7c7"
                    activeColor="#FFC233"
                />
            </div>
        );
    }

    function onStarRatingClicked(rating) {
        if (newsRating != null && newsRating.rating === rating) {
            // There is no need to make calls if the rating is the same.
            return;
        }

        var updatedNewsRating = {
            id: newsRating != null ? newsRating.id : 0,
            newsId: trueNewsId,
            rating: rating
        };

        newsService.saveOrUpdateNewsRating(updatedNewsRating.id, trueNewsId, rating)
            .then(n => {
                setNewsRating(n);

                if (canAwardCoinFromNewsRating) {
                    coinService.giveCoinsToUserFromNewsRating(trueNewsId)
                        .then(coinTransaction => {
                            
                            setCanAwardCoinFromNewsRating(false);
                            if (coinTransaction != null && coinTransaction.quantity !== 0) {

                                var titleText = props.translateFunc(
                                    'HAPPYCOIN_REWARD',
                                    { count: coinTransaction.quantity });

                                modalUtils.displayModalImageSimpleConfirm(
                                    <ModalsHeaderHappycoinsWon />,
                                    titleText,
                                    props.translateFunc("NEWS_CHECK_TO_WIN_MORE_COINS"),
                                    props.translateFunc("GLOBAL_OK"));
                            }
                        });
                }
            });
    }


    return (
        <div className="NewsDetail">
            <div className="NewsDetailImg">
                {/* <img src={require("../../images/news.jpg")} />                 */}
                {renderMedia()}
            </div>

            {props.newsId != null ? renderNewsFromDirectLink() : renderNewsFromList()}

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedNews: state.newsReducer.selectedNews
    };
}

export default connect(mapStateToProps)(NewsDetailContainer);