import React from 'react';
import ReactDOM from 'react-dom';
import ModalImageSimpleConfirm from '../containers/_Global/ModalImageSimpleConfirm';
import { confirmAlert } from 'react-confirm-alert';
import ModalComponentNoButtons from '../containers/_Global/ModalComponentNoButtons';
import ModalComponentSimpleConfirm from '../containers/_Global/ModalComponentSimpleConfirm';
import TutorialCarousel from '../components/_Global/TutorialCarousel';


export function displayModalImageSimpleConfirm(
    imageComponentToRender,
    modalTitle,
    modalMessage,
    buttonText,
    buttonFunction,
    modalSubTitle) {

    var modal = <ModalImageSimpleConfirm
        imageComponentToRender={imageComponentToRender}
        isOppenedByDefault={true}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        buttonText={buttonText}
        buttonFunction={buttonFunction}
        modalSubTitle={modalSubTitle} />

    renderModal(modal);
}

function renderModal(component) {
    ReactDOM.render(component, document.getElementById("idModalSection"))

}

export function showTutorialPopup(cardsContent, modalTitle, i18n, translateFunc) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <ModalComponentSimpleConfirm
                    disableActionBar={true}
                    isOppenedByDefault={true}
                    onModalClosed={null}
                    confirmationMessage={modalTitle}
                    componentContent={
                        <TutorialCarousel
                            i18n={i18n}
                            translateFunc={translateFunc}
                            cardsContent={cardsContent}
                            confirmFunction={onClose}
                            showIndexPagination={true}
                        />}
                    isConfirmButtonDisabled={true}
                />
            );
        }
    });
}