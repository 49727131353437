import * as actionNames from '../actionNames';

export const onMoodSelected = (moodValue, currentDayName) => ({
    type: actionNames.SET_SELECTEDMOODRATING,
    moodValue: moodValue,
    currentDayName: currentDayName
})

export const onDayOfTheWeekSelected = (index) => ({
    type: actionNames.SET_SELECTEDDAYOFTHEWEEK,
    index: index
})

export const updateMood = (currentDayName, selectedMoodRating, moodRatingFromDb, moodId, diaryDate, commentData, workPlace) => ({
    type: actionNames.UPDATE_MOOD,
    currentDayName: currentDayName,
    selectedMoodRating: selectedMoodRating,
    moodRatingFromDb: moodRatingFromDb,
    moodId: moodId,
    diaryDate: diaryDate,
    commentData: commentData,
    workPlace: workPlace
})

export const addInfluenceEntry = (selectedDayOfTheWeekIndex, influenceEntry) => ({
    type: actionNames.ADD_INFLUENCEENTRY,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    influenceEntry: influenceEntry
})

export const updateInfluenceEntry = (selectedDayOfTheWeekIndex, influenceEntry) => ({
    type: actionNames.UPDATE_INFLUENCEENTRY,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    influenceEntry: influenceEntry
})

export const updateInfluenceEntryComment = (selectedDayOfTheWeekIndex, influenceEntryId, influenceCommentText) => ({
    type: actionNames.UPDATE_INFLUENCE_ENTRY_COMMENT,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    influenceEntryId: influenceEntryId,
    influenceCommentText: influenceCommentText
})

export const onInfluenceCategorySelected = (categoryId, categoryName) => ({
    type: actionNames.SET_SELECTED_INFLUENCECATEGORY,
    categoryId: categoryId,
    categoryName: categoryName
})

export const updateInfluenceEntries = (selectedDayOfTheWeekIndex, influenceEntries) => ({
    type: actionNames.UPDATEINFLUENCEENTRIES,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    influenceEntries: influenceEntries
})

export const updateMoodcomment = (selectedDayOfTheWeekIndex, commentData) => ({
    type: actionNames.UPDATE_MOOD_COMMENT,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    commentData: commentData
})

export const clearMoodState = () => ({
    type: actionNames.MOOD_CLEAR_STATE
})

export const updateQuestion = (selectedDayOfTheWeekIndex, questionObject, isToApplyPendingChanges) => ({
    type: actionNames.UPDATE_QUESTION,
    isToApplyPendingChanges: isToApplyPendingChanges,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    questionObject: questionObject
})

export const updateWorkplace = (selectedDayOfTheWeekIndex, workPlace) => ({
    type: actionNames.UPDATE_WORKPLACE,
    selectedDayOfTheWeekIndex: selectedDayOfTheWeekIndex,
    workPlace: workPlace
})

