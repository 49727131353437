import React from 'react';
import CategoryHeaderContainer from '../../containers/Medals/CategoryHeaderContainer';
import CategoryListContainer from '../../containers/Medals/CategoryListContainer';

export default function MedalsCategoryDetailContainer(props) {

    return (
        <div>        
            <CategoryHeaderContainer />            
            <CategoryListContainer />
        </div>
    );
}