import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ListIcon from '@material-ui/icons/List';
import { Link } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import { showTutorialPopup } from '../../utils/modalUtils.js';
import TutorialCard from '../Tutorials/TutorialCard';
import tutorialImageStep1 from '../../images/tutorialImages/0601.png';
import tutorialImageStep2 from '../../images/tutorialImages/0602.png';
import tutorialImageStep3 from '../../images/tutorialImages/0603.png';
import tutorialImageStep4 from '../../images/tutorialImages/0604.png';
import { getCoinRewardByCode } from '../../api/coinService.js';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function CoinsHeader(props) {
    const classes = useStyles();

    let tutorialCardsContent = [];

    const [coinRewardMood, setCoinRewardMood] = React.useState(null);
    const [coinRewardMerit, setCoinRewardMerit] = React.useState(null);
    const [coinRewardNews, setCoinRewardNews] = React.useState(null);


    useEffect(() => {
        getCoinRewardByCode("MOOD_FILL", setCoinRewardMood).then(r => {
            setCoinRewardMood(r);
        });

        getCoinRewardByCode("APPRAISAL_ASSIGN", setCoinRewardMood).then(r => {
            setCoinRewardMerit(r);
        });

        getCoinRewardByCode("NEWS_READ", setCoinRewardMood).then(r => {
            setCoinRewardNews(r);
        });
    }, [])

    tutorialCardsContent.push(
        <div key={1}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep1} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_HAPPYCOINS_LABEL_1")} />
        </div>);

    tutorialCardsContent.push(
        <div key={2}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep2} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_HAPPYCOINS_LABEL_2",
                    {
                        moodReward: coinRewardMood,
                        meritReward: coinRewardMerit,
                        newsReward: coinRewardNews
                    })} />
        </div>);

    tutorialCardsContent.push(
        <div key={3}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep3} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_HAPPYCOINS_LABEL_3")} />
        </div>);

    tutorialCardsContent.push(
        <div key={4}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep4} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_HAPPYCOINS_LABEL_4")} />
        </div>);

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <Link to={props.linkToRef != null ? props.linkToRef : "/"}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                    <Typography className="titleHeader" variant="h6" noWrap>
                        HappyCoins
                    </Typography>

                    <div className={classes.grow} />

                    {props.isToHideTransactionHistoryButton
                        ? null
                        : <Link to={process.env.REACT_APP_ROUTING_PAGE_COINS_TRANSACTION_HISTORY}>
                            <IconButton aria-label="Mais" color="inherit" >
                                <ListIcon />
                            </IconButton>
                        </Link>}

                    <IconButton aria-label="info here" color="inherit" onClick={() => {
                        showTutorialPopup(tutorialCardsContent, "tutorialCardLabel", props.i18n, props.translateFunc);
                    }}>
                        <HelpIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
        </div>
    );
}
