import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { fetchWithBearerToken } from '../../utils/utils';

export default function _DevReportsContainer(props) {
  const [newsletterData, setNewsletterData] = useState(null);

  useEffect(() => {
    getData(setNewsletterData);
  }, [])

  function getData(cacheCallback) {

    return fetchWithBearerToken(
      process.env.REACT_APP_URL_API_DEV_AUTOMATIC_NEWSLETTER_DATA,
      "GET",
      null,
      cacheCallback)
      .then(response => response).then(d => {
        setNewsletterData(d);
      });
  }

  return (
    <div style={{ color: 'white' }}>

      <div>_______________________________</div>
      <br></br>
      <div>
        Happycoins:

        <div>
          Total: {newsletterData != null && newsletterData.weeklyReportData != null
            ? newsletterData.weeklyReportData.numberOfHappyCoinsEarnedLast7Days
            : null}
        </div>

        <div>
          Difference: {newsletterData != null ? newsletterData.happyCoinsDifference : null}
        </div>
      </div>

      <br></br>
      <div>_______________________________</div>
      <br></br>

      <div>
        Medals:

        <div>
          Total: {newsletterData != null && newsletterData.weeklyReportData != null
            ? newsletterData.weeklyReportData.numberOfGeneratedMedalsLast7Days
            : null}
        </div>

        <div>
          Difference: {newsletterData != null ? newsletterData.medalsDifference : null}
        </div>
      </div>

      <br></br>
      <div>_______________________________</div>
      <br></br>

      <div>
        Mood:
        <div>
          Total: {newsletterData != null && newsletterData.weeklyReportData != null
            ? newsletterData.weeklyReportData.moodAverageValueLast7Days
            : null}
        </div>

        <div>
          Difference: {newsletterData != null ? newsletterData.moodAverageDifference : null}
        </div>
      </div>

      <br></br>
      <div>_______________________________</div>

      <br></br>
      <div>
        <h4>
          StartDate: {newsletterData != null ? newsletterData.startDate : null}
        </h4>
        
        <h4>
          EndDate: {newsletterData != null ? newsletterData.endDate : null}
        </h4>
      </div>

    </div>
  );
}
