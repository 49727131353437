// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const authority = process.env.REACT_APP_AUTHPROVIDER_CONFIG_AUTH_AUTHORITY;
//const flow_susi = process.env.REACT_APP_AUTHPROVIDER_CONFIG_AUTH_FLOW_SUSI;
const clientId = process.env.REACT_APP_AUTHPROVIDER_CONFIG_AUTH_CLIENTID;
const apiScope = process.env.REACT_APP_AUTHPROVIDER_CONFIG_AUTH_API_SCOPE;


// Msal Configurations
const config = {
    // auth: {
    //     authority: authority + flow_susi,
    //     clientId: clientId,
    //     validateAuthority: false,
    //     navigateToLoginRequestUrl: true,
    // },
    auth: {
        authority: authority,
        clientId: clientId,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
// const authenticationParameters = {
//     scopes: JSON.parse(process.env.REACT_APP_AUTHPROVIDER_AUTHENTICATIONPARAMETERS_SCOPES)
// }
export const authenticationParameters = {
    scopes: [
        apiScope
    ]
}
export const authenticationParametersGraph = {
    scopes: [
        'openid'
    ]
}



// Options
const options = {
    //loginType: LoginType.Popup,
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
}


function handleForgotPassword(error) {
    if (error && error.errorMessage && error.errorMessage.indexOf('AADB2C90118') > -1) {
        // Forgot Password Handler https://github.com/syncweek-react-aad/react-aad/issues/105
        const flow_passwordreset = process.env.REACT_APP_AUTHPROVIDER_CONFIG_AUTH_FLOW_PASSWORDRESET;
        authProvider.setAuthenticationParameters({ authority: authority + flow_passwordreset })
        authProvider.login()
    }
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
authProvider.registerErrorHandler(handleForgotPassword);