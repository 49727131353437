import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HappyShopContainer from './HappyShopContainer';
import CoinsCoinsCampaign from '../../containers/Coins/CoinsCampaign';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    title: {
        color: 'white',
    },

}));

export default function CoinsTabsContainer(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={props.translateFunc("COINS_HEADER_TAB_PERSONAL")}
                        {...a11yProps(0)}
                        className={classes.title} />

                    <Tab label={props.translateFunc("COINS_HEADER_TAB_SOLIDARY")}
                        {...a11yProps(1)}
                        className={classes.title} />
                </Tabs>
            </AppBar>
            <SwipeableViews

                index={value}
                onChangeIndex={handleChangeIndex}
                disabled={true}
            >
                <TabPanel value={value} index={0} className="nopadding">
                    <HappyShopContainer
                        i18n={props.i18n}
                        translateFunc={props.translateFunc} />
                </TabPanel>


                <TabPanel value={value} index={1} className="nopadding">
                    <CoinsCoinsCampaign
                        i18n={props.i18n}
                        translateFunc={props.translateFunc}
                    />
                </TabPanel>

            </SwipeableViews>
        </div>
    );
}

