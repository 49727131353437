import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import MedalsNumber from '../../components/Medals/MedalsNumber';
import MoodIcon from '../../components/Mood/MoodIcon';

export default function CategoryListContainer() {  

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="DetailHistory"
      >
        <ListItem >
          <ListItemText primary="Segunda, 10/02/2020" />
          <MedalsNumber medalCount={4} />
          <MoodIcon medalStyle={1} />
        </ListItem>
        <ListItem >
          <ListItemText primary="Domingo, 9/02/2020" />
          <MedalsNumber medalCount={5} />
          <MoodIcon medalStyle={1} />
        </ListItem>
        <ListItem >
          <ListItemText primary="Sábado, 8/02/2020" />
          <MedalsNumber medalCount={3} />
          <MoodIcon medalStyle={1} />
        </ListItem>
        <ListItem >
          <ListItemText primary="Sexta-feira, 7/02/2020" />
          <MedalsNumber medalCount={5} />
          <MoodIcon medalStyle={1} />
        </ListItem>
        <ListItem >
          <ListItemText primary="Quinta-feira, 6/02/2020" />
          <MedalsNumber medalCount={5} />
          <MoodIcon medalStyle={1} />
        </ListItem>

      </List>
    </div>

  );
}