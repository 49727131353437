import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { ReactComponent as ModalIcon } from '../../images/Icons/five_medals.svg';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function AwardsContainer(props) {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.grow}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/">
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        <Typography className="titleHeader" variant="h6" noWrap>
                            {props.translateFunc("ACHIEVEMENTS_LABEL")}
                        </Typography>
                        <div className={classes.grow} />
                    </Toolbar>
                </AppBar>
            </div>
            <div className="tempContent">
                <div>
                    <span><ModalIcon /></span>
                    <div className="title"> {props.translateFunc("ACHIEVEMENTS_YOUR_TITLE")}</div>
                    <div className="text">{props.translateFunc("ACHIEVEMENTS_DESCRIPTION")}</div>
                    <div className="soon">{props.translateFunc("GLOBAL_COMING_SOON")}</div>
                </div>
            </div>
        </div>
    );
}