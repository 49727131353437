// Contains the diary calls
import { fetchWithBearerToken } from '../utils/utils';

export function getCurrentDay() {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_DIARY_CURRENTDAY,
        "GET")
        .then(response => response);
}

export function getCurrentDayFormatted(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_DIARY_CURRENTDAYFORMATTED,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getCurrentWeekFullDays(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_DIARY_GETCURRENTWEEKFULLDAYS,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}
