import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import { connect } from 'react-redux';
import { Provider } from 'react-redux'

export default function ModalComponentSimpleConfirm(props) {

  function renderDialogActions() {
    if (props.disableActionBar) {
      return;
    } else {
      return (
        <DialogActions>

          <Button onClick={props.onModalClosed} color="primary">
            {props.cancelButtonLabel}
          </Button>

          <Button
            disabled={props.isConfirmButtonDisabled}
            color="primary"
            autoFocus
            onClick={() => {
              props.onModalClosed();
              props.onConfirmClicked();
            }}>
            {props.confirmButtonLabel}
          </Button>

        </DialogActions>
      );
    }
  }
  return (



    <Dialog
      open={true}
      onClose={props.onModalClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modals"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: 'black' }}
        disableTypography={true}>

        {props.confirmationMessage}

      </DialogTitle>

      {props.componentContent}
      
      {renderDialogActions()}

    </Dialog>

  );
}
