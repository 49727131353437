import React, { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function SwitchLabels(props) {

  const [isCurrentlyChecked, setIsCurrentlyChecked] = useState(false);

  const handleChange = (event) => {
    // The switch is only toggled off if tthe user confirmed the dialog
    props.switchToggleCallback(event.target.checked);
  };

  useEffect(() => {
    setIsCurrentlyChecked(props.isChecked);

  }, [props.isChecked])

  return (
    <FormControlLabel
      control={<Switch checked={isCurrentlyChecked} onChange={handleChange} />}
      label={props.translateFunc("CAMPAIGN_SWITCH_LABEL")}
    />
  );
}