import React from 'react';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as HappyCoinIcon } from '../../images/Icons/happycoins_won.svg';


export default function MoodQtText(props) {
    const [coinRewardOpacity, setCoinRewardOpacity] = React.useState('30%');

    const [textValue, setTextValue] = React.useState(
        props.preselectedAnswer != null
            ? props.preselectedAnswer[0].freeTextAnswerValue
            : "");

    const handleFocusOut = event => {
        setTextValue(event.target.value);

        let newAnswerObj = {
            questionAnswerEntryId: props.preselectedAnswer != null
                ? props.preselectedAnswer[0].questionAnswerEntryId
                : 0,
            questionId: props.questionId,
            freeTextAnswerValue: event.target.value
        }
        props.answerCallback(newAnswerObj, true);
    };

    const handleChange = event => {
        setTextValue(event.target.value);

        if (event.target.value != null && event.target.value.length) {
            setCoinRewardOpacity('100%');
        } else {
            setCoinRewardOpacity('30%');
        }
    };

    function renderCoinRewardSection() {
        return (
            <div className="Medals" style={{ opacity: coinRewardOpacity }}>
                <HappyCoinIcon />
                <div>{props.coinRewardForAnsweringQuestions}</div>
            </div>);
    }


    return (
        <div className="qtText">
            <div className="txt">{props.questionText}</div>
            <form noValidate autoComplete="off">
                <TextField
                    id="standard-basic"
                    label="Resposta:"
                    multiline
                    value={textValue}
                    maxRows={4}
                    onBlur={handleFocusOut}
                    onChange={handleChange} />
            </form>

            {renderCoinRewardSection()}
        </div>
    );
}