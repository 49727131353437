import React, { useEffect } from "react";
import HeaderContainer from "./HeaderContainer";
import QuotesContainer from "./QuotesContainer";
import FooterContainer from "./FooterContainer";
import CompanyLogoContainer from "./CompanyLogoContainer";
import RedirectIfRequired from "./RedirectIfRequired";
import { generateRandomNumber, fetchWithBearerToken } from '../../utils/utils'
import * as medalService from '../../api/medalService';
import Background1 from '../../images/n01.jpg';
import Background2 from '../../images/n02.jpg';
import Background3 from '../../images/n03.jpg';
import Background4 from '../../images/n04.jpg';
import Background5 from '../../images/n05.jpg';
import Background6 from '../../images/n06.jpg';
import Background7 from '../../images/n07.jpg';
import Background8 from '../../images/n08.jpg';
import Background9 from '../../images/n09.jpg';
import Background10 from '../../images/n10.jpg';
import Background11 from '../../images/n11.jpg';
import Background12 from '../../images/n12.jpg';
import Background13 from '../../images/n13.jpg';
import Background14 from '../../images/n14.jpg';
import Background15 from '../../images/n15.jpg';
import Background16 from '../../images/n16.jpg';
import Background17 from '../../images/n17.jpg';
import Background18 from '../../images/n18.jpg';
import Background19 from '../../images/n19.jpg';
import Background20 from '../../images/n20.jpg';
import Background21 from '../../images/n21.jpg';
import Background22 from '../../images/n22.jpg';
import Background23 from '../../images/n23.jpg';
import Background24 from '../../images/n24.jpg';
import Background25 from '../../images/n25.jpg';
import Background26 from '../../images/n26.jpg';
import Background27 from '../../images/n27.jpg';
import Background28 from '../../images/n28.jpg';
import Background29 from '../../images/n29.jpg';
import Background30 from '../../images/n30.jpg';
import ModalsTermsOfUse from "../_Global/ModalsTermsOfUse";
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

function HCIHome(props) {

    useEffect(() => {

        //medalService.awardCurrentUserWeeklySystemAppraisals()
        medalService.awardCurrentUserWeeklySystemAppraisals()
            .then(systemAppraisals => {

                // The first time after the week as reset, we need to update the user's appraisals
                // Because the login function runs before awarding appraisals
                if (systemAppraisals > 0) {

                    var userDetails = {
                        id: props.currentUser.id,
                        name: props.currentUser.name,
                        email: props.currentUser.email,
                        currentAssociatedCompany: props.currentUser.currentAssociatedCompany,
                        receivedMedalCount: props.currentUser.receivedMedalCount,
                        assignedMedalCount: props.currentUser.assignedMedalCount,
                        totalMedalsStyle: props.currentUser.totalMedalsStyle,
                        appraisalsReceivedFromSystemThisWeek: systemAppraisals,
                        availableAppraisalsToSpend: systemAppraisals,
                        availableAppraisalsBeforeAssign: systemAppraisals,
                        hasUserGivenAppraisalsThisWeek: props.currentUser.hasUserGivenAppraisalsThisWeek,
                        medalRankPosition: props.currentUser.medalRankPosition,
                        profilePictureFullPath: props.currentUser.profilePictureFullPath,
                        profilePictureThumbnailPath: props.currentUser.profilePictureThumbnailPath
                    };

                    props.setCurrentUser(userDetails);
                }
            });

    }, [])

    function getRandomBackgroundImage() {
        // HACK JS: duplicated code to fetch another image so that the home screen does not blur the background
        // remove this!!! and find another way
        let images = [Background1, Background2, Background3, Background4, Background5, Background6, Background7, Background8, Background9, Background10, Background11, Background12, Background13, Background14, Background15, Background16, Background17, Background18, Background19, Background20, Background21, Background22, Background23, Background24, Background25, Background26, Background27, Background28, Background29, Background30];

        var randomNumber = generateRandomNumber(images.length);
        return images[randomNumber];
    }

    function updateUserTermsOfUse() {
        fetchWithBearerToken(
            process.env.REACT_APP_URL_API_USER_UPDATE_TERMS_OF_USE,
            "POST").then(_ => {
                props.acceptTermsFunction();
            });
    }
    function renderContent() {

        return (
            <div className="bgImage" style={{ backgroundImage: "url(" + getRandomBackgroundImage() + ")" }}>
                {/* remove both divs ↑↓*/}

                <div className="bgImageDark">
                    <HeaderContainer translateFunc={props.translateFunc} i18n={props.i18n} />

                    <div className="InspirationQuotes">
                        <QuotesContainer />
                    </div>

                    <div className="LogoContainer">
                        <CompanyLogoContainer />
                    </div>

                    <div className="FooterHome">
                        <FooterContainer translateFunc={props.translateFunc} i18n={props.i18n} />
                    </div>
                </div>

                {props.isToShowTermsOfUse
                    ? <ModalsTermsOfUse
                        linkRGPD={props.currentUser.currentAssociatedCompany.linkRGPD}
                        companyName={props.currentUser.currentAssociatedCompany.name}
                        companyLogo={props.currentUser.currentAssociatedCompany.logoURL}
                        onRefuseClick={props.logoutFunction}
                        onAcceptClick={updateUserTermsOfUse} />
                    : null}

                <RedirectIfRequired />
            </div>
        );
    }

    return (
        <div>
            {renderContent()}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser
    };
}


const mapDispatchToProps = dispatch => {

    return {
        setCurrentUser: (userDetails) => dispatch(
            userActions.setCurrentUser(userDetails)
        )
    };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(HCIHome);