import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MedalsNumberStar from './MedalsNumberStar'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import IconButton from '@material-ui/core/IconButton';
import * as medalService from '../../api/medalService';
import { Avatar, Collapse, List } from '@material-ui/core';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { generateGUID } from '../../utils/utils';
import Badge from '@material-ui/core/Badge';


export default function MedalCategoryRowElement(props) {
  const [categoryMedalsReceivedThisWeek, setCategoryMedalsReceivedThisWeek] = React.useState(0);
  const [isCategoryOpened, setIsCategoryOpened] = React.useState(false);

  useEffect(() => {

    if (props.isMedalDetailComingFromLocalUser && props.medalCount > 0) {
      medalService.getReceivedMedalCountForUserAndCategoryForCurrentWeek(
        props.categoryId,
        setCategoryMedalsReceivedThisWeek)
        .then(count => {
          setCategoryMedalsReceivedThisWeek(count);
        });
    }

  }, [])

  /// Only renders the comment icon if there are comments
  function renderCommentIcon() {
    if (props.numberOfComments !== 0) {
      return (
        <IconButton>
          <div className="mensagensNumero">{props.numberOfComments}</div>
          <ChatBubbleIcon />
        </IconButton>);
    }
    else {
      return (<div></div>);
    }
  }

  function handleListItemClick() {
    if (props.isMedalDetailComingFromLocalUser) {
      // TODO JS: expand thing
      setIsCategoryOpened(!isCategoryOpened)
      return;
    } else {
      props.onRowClickCallback(props.categoryId);
    }
  }

  function renderAppraisalGivenUserList() {
    if (props.medalUserDetailList) {
      let avatarList = [];
      props.medalUserDetailList.forEach(m => {
        avatarList.push(

          <Badge
            key={generateGUID()}
            color={m.wasAssignedThisWeek ? "secondary" : "primary"}
            badgeContent={m.userMedalAssignedCount}
            overlap="circular">
            <Avatar
              alt={m.userOrigin.name}
              src={m.userOrigin.profilePictureThumbnailPath} />
          </Badge>
        );
      });
      return avatarList;
    }
  }

  return (
    <div>
      <ListItem
        button
        onClick={handleListItemClick}>

        <ListItemText primary={props.categoryName} />
        <ListItemSecondaryAction className="whois" onClick={handleListItemClick}>

          {props.isMedalDetailComingFromLocalUser
            ? isCategoryOpened
              ? <ListItemIcon><ExpandLess /> </ListItemIcon>
              : <ListItemIcon><ExpandMore /> </ListItemIcon>
            : null}

          <MedalsNumberStar
            medalCount={props.medalCount}
            badgeContent={categoryMedalsReceivedThisWeek}
            userId={props.userId}
            companyId={props.companyId}
            medalStyle={props.medalStyle} />
        </ListItemSecondaryAction>

      </ListItem>

      <Collapse in={isCategoryOpened} timeout="auto">
        <List component="div" disablePadding>
          {renderAppraisalGivenUserList()}
        </List>
      </Collapse>

    </div>

  );
}