import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MedalsUsersListContainer from './MedalsUsersListContainer';
import { connect } from 'react-redux';
import MedalsHighlightsContainer from './MedalsHighlightsContainer';
import { replaceURL } from '../../utils/utils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        //backgroundColor: 'rgba(255, 255, 255, 1)',
        //width: 500,
    },
    title: {
        color: 'white',
    },

}));

function MedalsTabsContainer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        let historyToSet = newValue === 1
            ? process.env.REACT_APP_ROUTING_MEDALS_HIGHLIGHTS
            : process.env.REACT_APP_ROUTING_MEDALS;

        replaceURL(historyToSet);
    };

    useEffect(() => {
        setValue(props.isToDisplayHighlights ? 1 : 0);
    }, [])

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={props.translateFunc("MEDALS_TABS_NAME")} {...a11yProps(0)} className={classes.title} />

                    <Tab label={props.translateFunc("MEDALS_TABS_HIGHLIGHTS")}
                        {...a11yProps(1)}
                        className={classes.title} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChange}
                disabled={true}
            >
                {/* NAMES */}
                <TabPanel value={value} index={0} dir={theme.direction} className="nopadding">
                    <MedalsUsersListContainer
                        translateFunc={props.translateFunc}
                        i18n={props.i18n}
                        sortFilter={"NAME"}
                        isSelected={value === 0} />
                </TabPanel>

                {/* HIGHLIGHTS */}
                <TabPanel value={value} index={1} dir={theme.direction} className="nopadding">
                    <MedalsHighlightsContainer categoryId={props.categoryId} />
                </TabPanel>


            </SwipeableViews>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        currentUser: state.userReducer.currentUser
    });
}

export default connect(mapStateToProps)(MedalsTabsContainer);
