import React, { useEffect } from 'react';
import MoodSelectionVertical from '../../components/Mood/MoodSelectionVertical'
import * as diaryService from '../../api/diaryService.js';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { materialOverride } from "../../css/globalStyles.js";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions';
import * as userService from '../../api/userService';
import * as moodService from '../../api/moodService';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        //display: 'none',
        //[theme.breakpoints.up('md')]: {
        //    display: 'flex',
        //},
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function MoodContainer(props) {
    const classes = useStyles();
    const [currentDayName, setCurrentDayName] = React.useState();
    const [currentDayFormatted, setCurrentDayFormatted] = React.useState();
    const history = useHistory();

    useEffect(() => {

        setCurrentDay();
        getCurrentUserDetailsIfNull();

    }, [])

    function setCurrentDay() {
        let formatOptions = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        diaryService.getCurrentDay()
            .then(day => {
                var date = new Date(day);

                setCurrentDayName(date.toLocaleDateString("en-EN", { weekday: 'long' }));
                setCurrentDayFormatted(date.toLocaleDateString(props.i18n.language, formatOptions));
            });
    }

    function getCurrentUserDetailsIfNull() {
        // Gets the user details if that info is null
        // This is to allow users to be redirected to this page from a direct link
        // without passing through the home screen        

        if (props.currentUser.id === 0) {

            userService.getCurrentUserDetails().then(detail => {
                let userDetails = {
                    id: detail.id,
                    name: detail.name,
                    email: detail.email,
                    currentAssociatedCompany: detail.currentAssociatedCompany,
                    receivedMedalCount: detail.receivedMedalCount,
                    assignedMedalCount: detail.assignedMedalCount,
                    medalRankPosition: detail.medalRankPosition,
                    totalMedalsStyle: null,
                    appraisalsReceivedFromSystemThisWeek: detail.appraisalsReceivedFromSystemThisWeek,
                    currentAvailableAppraisalsToSpend: detail.availableAppraisalsToSpend,
                    hasUserGivenAppraisalsThisWeek: detail.hasUserGivenAppraisalsThisWeek,
                    profilePictureFullPath: detail.profilePictureFullPath,
                    profilePictureThumbnailPath: detail.profilePictureThumbnailPath
                }
                props.setCurrentUser(userDetails);
            });
        }
    }

    function redirectToDetailIfTodayIsFilled() {
        moodService.hasUserFilledMoodToday(redirectIfUserFilledMood)
            .then(userFilledMoodToday => {
                redirectIfUserFilledMood(userFilledMoodToday);
            });
    }

    function redirectIfUserFilledMood(userFilledMoodToday) {
        if (userFilledMoodToday) {
            history.push(
                process.env.REACT_APP_ROUTING_MOOD_DETAIL);
        }
    }

    return (
        <div>
            {redirectToDetailIfTodayIsFilled()}
            <div className={classes.grow}>
                <AppBar position="static" style={materialOverride.whiteTransparencyColor}>
                    <Toolbar>
                        <Link to={"/"} style={{ textDecoration: 'none', color: 'white' }}>
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        <div className={classes.grow} />
                        <Typography className="titleHeader" variant="h6" noWrap>
                            {currentDayFormatted}
                        </Typography>
                        <div className={classes.grow} />

                        <Link to={process.env.REACT_APP_ROUTING_MOOD_DETAIL} style={{ textDecoration: 'none', color: 'white' }}>
                            <IconButton >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Link>

                    </Toolbar>
                </AppBar>
            </div>
            <div>
                <MoodSelectionVertical
                    translateFunc={props.translateFunc} i18n={props.i18n}
                    currentDay={currentDayFormatted}
                    currentDayName={currentDayName} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentUser: (userDetails) => dispatch(
            userActions.setCurrentUser(userDetails)
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MoodContainer);
