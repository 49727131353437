import React from 'react';
import CoinsHeader from '../../components/Coins/CoinsHeader'

export default function CoinsHeaderContainer(props) {

    return (
        <div>
            <CoinsHeader
                translateFunc={props.translateFunc} i18n={props.i18n}
                linkToRef={props.linkToRef}
                isToHideTransactionHistoryButton={props.isToHideTransactionHistoryButton} />
        </div>
    );
}