import React from 'react';
import HistoryHeaderContainer from '../../containers/UserCharts/HistoryHeaderContainer';
import HistoryMedalsListContainer from '../../containers/UserCharts/HistoryMedalsListContainer';


export default function HistoryDetail() {

  return (
    <div className="HistoryDetail">
      <HistoryHeaderContainer />      
      <HistoryMedalsListContainer />
    </div>
  );
}
