import * as actionNames from '../actionNames';
import * as systemService from '../../api/systemService';

const initialState = {
    currentUser: {
        id: 0,
        name: null,
        email: null,
        currentAssociatedCompany: null,
        receivedMedalCount: 0,
        assignedMedalCount: 0,
        totalMedalsStyle: null,
        appraisalsReceivedFromSystemThisWeek: 0,
        currentAvailableAppraisalsToSpend: 0,
        availableAppraisalsBeforeAssign: 0,
        hasUserGivenAppraisalsThisWeek: false,
        medalRankPosition: 0,
        profilePictureFullPath: null,
        profilePictureThumbnailPath: null        
    },
    hasPendingChanges: false,
    userLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionNames.SET_CURRENTUSER:
            return {
                ...state,
                currentUser: updateCurrentUser(action.userDetails)
            }
        case actionNames.UPDATE_CURRENT_AVAILABLE_APPRAISALS_TO_SPEND:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    currentAvailableAppraisalsToSpend: action.currentAvailableAppraisalsToSpend
                }
            }
        case actionNames.SET_USER_GIVEN_APPRAISALS_THIS_WEEK:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    hasUserGivenAppraisalsThisWeek: action.hasUserGivenAppraisalsThisWeek
                }
            }
        case actionNames.SET_USER_AVAILABLE_APPRAISALS_BEFORE_ASSIGN:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    availableAppraisalsBeforeAssign: action.availableAppraisalsBeforeAssign
                }
            }

        case actionNames.SET_PENDING_CHANGES_FLAG: {
            return {
                ...state,
                hasPendingChanges: action.value
            }
        }
        case actionNames.SET_USER_LANGUAGE: {
            systemService.setLanguageCookieValue(action.value);
            return {
                ...state,
                userLanguage: action.value
            }
        }
    
        default:
            return state;

    }

};

function updateCurrentUser(userDetails) {

    let updatedUser = {
        id: userDetails.id,
        name: userDetails.name,
        email: userDetails.email,
        medalRankPosition: userDetails.medalRankPosition,
        currentAssociatedCompany: userDetails.currentAssociatedCompany,
        receivedMedalCount: userDetails.receivedMedalCount,
        assignedMedalCount: userDetails.assignedMedalCount,
        totalMedalsStyle: userDetails.totalMedalsStyle,
        appraisalsReceivedFromSystemThisWeek: userDetails.appraisalsReceivedFromSystemThisWeek,
        currentAvailableAppraisalsToSpend: userDetails.availableAppraisalsToSpend,
        availableAppraisalsBeforeAssign: userDetails.availableAppraisalsToSpend,
        hasUserGivenAppraisalsThisWeek: userDetails.hasUserGivenAppraisalsThisWeek,
        profilePictureFullPath: userDetails.profilePictureFullPath,
        profilePictureThumbnailPath: userDetails.profilePictureThumbnailPath,

    }

    return updatedUser;
}

export default userReducer;