import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CompanyLogo from '../../components/_Global/CompanyLogo';
import { connect } from 'react-redux';


const useStyles = makeStyles({
  centerElement: {
    textAlign: 'center',
  },
  logo: {
    width: '150px',
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    cursor: 'pointer',
  },
});

function CompanyLogoContainer(props) {

  const classes = useStyles();
  return (
    <div>
      <div className={classes.centerElement}>
        <CompanyLogo
          companyLogo={props.currentUser.currentAssociatedCompany.logoURL}
          companyName={props.currentUser.currentAssociatedCompany.name}
        ></CompanyLogo>

      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.currentUser,
  }
}

export default connect(mapStateToProps, null)(CompanyLogoContainer);