import React from 'react';
import HistoryDetailHeader from '../../components/History/HistoryDetailHeader'

export default function HistoryHeaderContainer(props) {

    return (
        <div>
            <HistoryDetailHeader />            
        </div>
    );
}