import React from 'react';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import { ReactComponent as LoadingComponentWhite } from '../../images/loadingWhite.svg';

export default function Loading(props) {
  return (
    <div className="Loading" >
      {props.isToUseWhiteLoader ? <LoadingComponentWhite /> : <LoadingComponent />}

    </div>
  );
}