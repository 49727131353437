import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function ModalSimpleConfirmation(props) {

  function renderDialogContent() {
    if (props.dialogContent == null) {
      return null;
    } else {
      return (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogContent}
          </DialogContentText>
        </DialogContent>
      );
    }
  }

  return (

    <Dialog
      open={true}
      onClose={props.onModalClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modals"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: 'black' }}
        disableTypography={true}>

        {props.confirmationMessage}

      </DialogTitle>

      {renderDialogContent()}

      <DialogActions>

        <Button onClick={props.onModalClosed} color="primary">
          {props.cancelButtonLabel}
        </Button>

        <Button onClick={() => {
          props.onModalClosed();
          props.onConfirmClicked();
        }}
          color="primary" autoFocus>
          {props.confirmButtonLabel}
        </Button>

      </DialogActions>
    </Dialog>
  );
}
