import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ModalComponentNoButtons(props) {

  return (



    <Dialog
      open={true}
      onClose={props.onModalClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modals"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ color: 'black' }}
        disableTypography={true}>

        {props.confirmationMessage}

      </DialogTitle>

      {props.componentContent}     
    </Dialog>

  );
}
