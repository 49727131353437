// Contains the medal category calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';


export function getAllMedalCategoriesForCompany(companyId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALCATEGORY_GETALLCATEGORIESFORCOMPANY, companyId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}
