import React, { useEffect } from 'react';
import CoinsCampaignText from '../../components/Coins/CoinsCampaignText';
import CoinsCampaignMycoins from '../../components/Coins/CoinsCampaignMycoins';
import CoinsCampaignObjective from '../../components/Coins/CoinsCampaignObjective';
import * as campaignService from '../../api/campaignService';
import { ReactComponent as ModalIcon } from '../../images/Icons/smiles_weekly_objective.svg';
import ModalSimpleConfirmation from '../../containers/_Global/ModalSimpleConfirmation'
import { confirmAlert } from 'react-confirm-alert';

export default function CoinsCampaign(props) {

    const [canRenderNoCampaignMessage, setCanRenderNoCampaignMessage] = React.useState(false);
    const [campaign, setCampaign] = React.useState();
    const [userContribution, setUserContribution] = React.useState(0);
    const [campaignCurrentCoins, setCampaignCurrentCoins] = React.useState(0);
    const [campaignParticipationFlag, setCampaignParticipationFlag] = React.useState(false);

    useEffect(() => {
        campaignService.getActiveCampaign(setCampaign)
            .then(activeCampaign => {
                if (activeCampaign != null) {
                    setCampaign(activeCampaign);
                } else {
                    setCanRenderNoCampaignMessage(true);
                }
            }).then(_ => {

                campaignService.getCampaignCoinsFromSpecificUser(setUserContribution)
                    .then(contribution => {
                        setUserContribution(contribution);
                    })

                campaignService.getActiveCampaignCurrentCoins(setCampaignCurrentCoins)
                    .then(coins => {
                        setCampaignCurrentCoins(coins);
                    })
            });

    }, [campaignParticipationFlag])

    useEffect(() => {
        if (campaign != null) {
            campaignService.isParticipatingInCurrentCampaign().then(isParticipating => {
                setCampaignParticipationFlag(isParticipating);
            });
        }

    }, [campaign])

    function handleCampaignSwitchToggle(value) {
        // There is no confirmation dialog to switch the campaign ON
        if (value === true) {
            toggleCampaignSwitch(value);
            return;
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ModalSimpleConfirmation
                        onModalClosed={onClose}
                        onConfirmClicked={() => { toggleCampaignSwitch(value) }}
                        confirmationMessage={props.translateFunc("CAMPAIGN_PARTICIPATION_MESSAGE")}
                        dialogContent={props.translateFunc("CAMPAIGN_PARTICIPATION_TOGGLE_OFF_MESSAGE")}
                        cancelButtonLabel={props.translateFunc("CAMPAIGN_PARTICIPATION_WANT")}
                        confirmButtonLabel={props.translateFunc("CAMPAIGN_PARTICIPATION_DONT_WANT")}
                    />
                );
            }
        });
    }

    function toggleCampaignSwitch(value) {
        campaignService.createUpdateOrRemoveCampaignParticipationExclusion(value);
        setCampaignParticipationFlag(value);
    }

    function renderCampaign() {

        return (
            <div className="tempContent intab">

                <CoinsCampaignText
                    i18n={props.i18n}
                    translateFunc={props.translateFunc}
                    switchToggleCallback={handleCampaignSwitchToggle}
                    isParticipatingInCampaign={campaignParticipationFlag}
                    title={campaign != null ? campaign.title : ""}
                    campaignDescription={campaign != null ? campaign.shortDescription : ""}
                    news={campaign != null ? campaign.news : ""} />

                <CoinsCampaignMycoins
                    i18n={props.i18n}
                    translateFunc={props.translateFunc}
                    userContribution={userContribution} />

                <CoinsCampaignObjective
                    i18n={props.i18n}
                    translateFunc={props.translateFunc}
                    startDate={campaign != null ? campaign.startDate : null}
                    endDate={campaign != null ? campaign.endDate : null}
                    campaignCurrentCoins={campaignCurrentCoins}
                    units={campaign != null ? campaign.units : 0}
                    coinsPerUnit={campaign != null ? campaign.coinsPerUnit : 0} />
            </div>
        );
    }

    function renderNoCampaignMessage() {
        return (
            <div className="tempContent intab">
                <div>
                    <ModalIcon />
                    <div className="title">{props.translateFunc("CAMPAIGN_NONE_ACTIVE")}</div>
                    <div className="text">{props.translateFunc("CAMPAGIN_STAY_TUNED")}</div>
                </div>
            </div>
        );
    }

    function renderContent() {
        if (canRenderNoCampaignMessage) {
            return campaign == null ? renderNoCampaignMessage() : renderCampaign();

        } else {
            return campaign == null ? null : renderCampaign();
        }
    }

    return (
        <div>
            {renderContent()}
        </div>
    );
}