import * as actionNames from '../actionNames';
import { daysOfTheWeekNamesEN } from '../../utils/utils';


const emptyMoodsAndQuestions =
    [
        {
            mood: buildDayOfTheWeekState("Saturday"),            
            influenceEntries: []
        },
        {
            mood: buildDayOfTheWeekState("Sunday"),
            influenceEntries: []
        },
        {
            mood: buildDayOfTheWeekState("Monday"),
            influenceEntries: []
        },
        {
            mood: buildDayOfTheWeekState("Tuesday"),
            influenceEntries: []
        },
        {
            mood: buildDayOfTheWeekState("Wednesday"),
            influenceEntries: []
        },
        {
            mood: buildDayOfTheWeekState("Thursday"),
            influenceEntries: []
        },
        {
            mood: buildDayOfTheWeekState("Friday"),
            influenceEntries: []
        }
    ];

const initialState = {
    selectedDayOfTheWeekIndex: null, // 0 = monday...etc    
    // selectedInfluenceCategoryId: 0,
    // selectedInfluenceCategoryName: null,
    currentWeekMoods: emptyMoodsAndQuestions,
    canSubmit: false, // flag to indicate if the mood is ready for submit
    hasPendingChanges: false
};

const moodDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionNames.SET_SELECTEDMOODRATING:
            return {
                ...state,
                hasPendingChanges: true,
                canSubmit: true,
                currentWeekMoods: getUpdatedCurrentWeekMoods(
                    action.currentDayName,
                    action.moodValue),

                selectedDayOfTheWeekIndex: daysOfTheWeekNamesEN.indexOf(action.currentDayName)
            }

        case actionNames.UPDATE_MOOD:
            return {
                ...state,
                currentWeekMoods: getUpdatedCurrentWeekMoods(
                    action.currentDayName,
                    null, // selected mood rating
                    action.moodRatingFromDb,
                    action.moodId,
                    action.diaryDate,
                    action.commentData,
                    action.workPlace
                )
            }

        case actionNames.SET_SELECTEDDAYOFTHEWEEK:
            return {
                ...state,
                selectedDayOfTheWeekIndex: action.index
            }

        case actionNames.UPDATE_MOOD_COMMENT:
            return {
                ...state,
                hasPendingChanges: state.hasPendingChanges || hasMoodCommentPendingChanges(
                    action.commentData, state.currentWeekMoods, action.selectedDayOfTheWeekIndex),
                canSubmit: checkIfIsValidSubmit(action.selectedDayOfTheWeekIndex),
                currentWeekMoods: updateMoodComment(
                    state.currentWeekMoods,
                    action.selectedDayOfTheWeekIndex,
                    action.commentData)
            }

        case actionNames.SET_SELECTED_INFLUENCECATEGORY:
            return {
                ...state,
                selectedInfluenceCategoryId: action.categoryId,
                selectedInfluenceCategoryName: action.categoryName
            }

        case actionNames.UPDATEINFLUENCEENTRIES:
            return {
                ...state,
                currentWeekMoods: updateMoodsInfluenceEntries(
                    action.selectedDayOfTheWeekIndex,
                    action.influenceEntries,
                    action.moodId)
            }

        case actionNames.UPDATE_INFLUENCEENTRY:

            return {
                ...state,
                hasPendingChanges: true,
                canSubmit: checkIfIsValidSubmit(action.selectedDayOfTheWeekIndex),
                currentWeekMoods: updateInfluenceEntry(
                    action.selectedDayOfTheWeekIndex,
                    action.influenceEntry)
            }

        case actionNames.ADD_INFLUENCEENTRY:
            return {
                ...state,
                hasPendingChanges: true,
                canSubmit: checkIfIsValidSubmit(action.selectedDayOfTheWeekIndex),
                currentWeekMoods: addInfluenceEntry(
                    action.selectedDayOfTheWeekIndex,
                    action.influenceEntry)
            }

        case actionNames.UPDATE_INFLUENCE_ENTRY_COMMENT:
            return {
                ...state,
                hasPendingChanges: action.influenceCommentText != null && action.influenceCommentText !== "",
                canSubmit: checkIfIsValidSubmit(action.selectedDayOfTheWeekIndex),
                currentWeekMoods: updateInfluenceEntryComment(
                    state.currentWeekMoods,
                    action.selectedDayOfTheWeekIndex,
                    action.influenceEntryId,
                    action.influenceCommentText)
            }

        case actionNames.MOOD_CLEAR_STATE:
            return clearMoodState();

        // case actionNames.UPDATE_QUESTION:
        //     return {
        //         ...state,
        //         hasPendingChanges: true,
        //         canSubmit: checkIfIsValidSubmit(
        //             action.selectedDayOfTheWeekIndex,
        //             action.isToApplyPendingChanges),
        //         currentWeekMoods: updateQuestion(
        //             state.currentWeekMoods,
        //             action.selectedDayOfTheWeekIndex,
        //             action.questionObject
        //         )
        //     }

        case actionNames.UPDATE_WORKPLACE:
            return {
                ...state,
                hasPendingChanges: true,
                canSubmit: checkIfIsValidSubmit(action.selectedDayOfTheWeekIndex),
                currentWeekMoods: updateWorkplace(
                    state.currentWeekMoods,
                    action.selectedDayOfTheWeekIndex,
                    action.workPlace
                )
            }

        default:
            return state;

    }

};

function buildDayOfTheWeekState(dayOfTheWeekName) {
    return {
        id: 0,
        dayOfTheWeekName: dayOfTheWeekName,
        selectedMoodRating: 0,
        moodRatingFromDb: 0,
        diaryDate: null, //the date value for the current day
        moodCommentData: null,
        workPlace: null
    };
}

function clearMoodState() {

    emptyMoodsAndQuestions.forEach(element => {
        element.mood.selectedMoodRating = 0;        
        element.influenceEntries = [];
        element.mood.moodCommentData = null;
    });

    return initialState;
}

function hasMoodCommentPendingChanges(newCommentData, currentWeekMoods, selectedDayOfTheWeekIndex) {
    let mood = currentWeekMoods[selectedDayOfTheWeekIndex].mood;

    if (mood.moodCommentData == null && newCommentData != null) {
        return true;
    }

    if ((mood.moodCommentData.textValue !== newCommentData.textValue)
        || mood.moodCommentData.isCommentAnonymous !== newCommentData.isCommentAnonymous) {

        return true;

    }

    return false;
}

function getUpdatedCurrentWeekMoods(
    dayToUpdate,
    selectedMoodRating,
    moodRatingFromDb,
    moodId,
    diaryDate,
    moodCommentData,
    workPlace) {

    let currentWeekMoodsUpdated = initialState.currentWeekMoods.map(m => m);

    for (let index = 0; index < currentWeekMoodsUpdated.length; index++) {

        const element = currentWeekMoodsUpdated[index];

        if (element.mood.dayOfTheWeekName === dayToUpdate) {

            if (selectedMoodRating != null) {
                element.mood.selectedMoodRating = selectedMoodRating;
            }

            if (moodRatingFromDb != null) {
                element.mood.moodRatingFromDb = moodRatingFromDb;
            }

            if (moodId != null) {
                element.mood.id = moodId;
            }

            if (diaryDate != null) {
                element.mood.diaryDate = diaryDate;
            }

            if (moodCommentData != null) {
                element.mood.moodCommentData = moodCommentData;
            }

            if (workPlace != null) {
                element.mood.workPlace = workPlace;
            }
        }
    }

    return currentWeekMoodsUpdated;
}

function updateMoodComment(
    currentWeekMoods,
    selectedDayOfTheWeekIndex,
    commentData) {

    // copy array to apply good practices
    let currentWeekMoodsMapped = currentWeekMoods.map(e => e);

    if (currentWeekMoodsMapped != null && currentWeekMoodsMapped[selectedDayOfTheWeekIndex] != null) {
        let element = currentWeekMoodsMapped[selectedDayOfTheWeekIndex];
        element.mood.moodCommentData = commentData;
    }

    return currentWeekMoodsMapped;
}

function updateWorkplace(currentWeekMoods, selectedDayOfTheWeekIndex, workPlace) {
    let currentWeekMoodsMapped = currentWeekMoods.map(e => e);

    if (currentWeekMoodsMapped != null && currentWeekMoodsMapped[selectedDayOfTheWeekIndex] != null) {
        let element = currentWeekMoodsMapped[selectedDayOfTheWeekIndex];
        element.mood.workPlace = workPlace;
    }

    return currentWeekMoodsMapped;
}


function updateMoodsInfluenceEntries(selectedDayOfTheWeekIndex, influenceEntries) {
    let currentWeekMoodsUpdated = initialState.currentWeekMoods.map(m => m);

    if ((influenceEntries != null && !influenceEntries.length)
        || selectedDayOfTheWeekIndex == null) {
        return currentWeekMoodsUpdated;
    }


    if (influenceEntries == null || !influenceEntries.length) {
        return;
    }

    let influenceEntriesUpdated = []
    influenceEntries.map(ie => {

        if (ie.moodId === currentWeekMoodsUpdated[selectedDayOfTheWeekIndex].mood.id) {

            influenceEntriesUpdated.push(ie);
        }
    });

    currentWeekMoodsUpdated[selectedDayOfTheWeekIndex].influenceEntries = influenceEntriesUpdated;
    return currentWeekMoodsUpdated;
}

function updateInfluenceEntry(selectedDayOfTheWeekIndex, influenceEntry) {

    let currentWeekMoodsUpdated = initialState.currentWeekMoods.map(m => m);
    currentWeekMoodsUpdated.influenceEntries = [];

    let influenceEntriesUpdated = currentWeekMoodsUpdated[selectedDayOfTheWeekIndex]
        .influenceEntries
        .map(infEntry => {
            if (infEntry.influenceId === influenceEntry.influenceId) {

                return influenceEntry;
            }
            else {
                return infEntry;
            }
        });

    currentWeekMoodsUpdated[selectedDayOfTheWeekIndex].influenceEntries = influenceEntriesUpdated;
    return currentWeekMoodsUpdated;

}

function updateInfluenceEntryComment(
    currentWeekMoods,
    selectedDayOfTheWeekIndex,
    influenceEntryId,
    influenceCommentText) {

    // copy array to apply good practices
    let currentWeekMoodsMapped = currentWeekMoods.map(e => e);

    if (currentWeekMoodsMapped != null && currentWeekMoodsMapped[selectedDayOfTheWeekIndex] != null) {
        let currentMood = currentWeekMoodsMapped[selectedDayOfTheWeekIndex];

        if (currentMood.influenceEntries != null && currentMood.influenceEntries.length) {
            // Find the infuence entry and update its text
            let infEntryIndex = currentMood.influenceEntries.map(ie => ie.id).indexOf(influenceEntryId);
            let influenceEntryToUpdate = currentMood.influenceEntries[infEntryIndex];
            influenceEntryToUpdate.commentText = influenceCommentText;
        }
    }

    return currentWeekMoodsMapped;
}


function addInfluenceEntry(selectedDayOfTheWeekIndex, influenceEntry) {

    let currentWeekMoodsUpdated = initialState.currentWeekMoods.map(m => m);

    currentWeekMoodsUpdated[selectedDayOfTheWeekIndex].influenceEntries.push(influenceEntry);

    return currentWeekMoodsUpdated;

}



/// Verification to assure that we can submit a mood
/// We can only submit moods that have a rating.
/// The user can fill influence entries but if he doesnt select a rating he will not be able to submit
function checkIfIsValidSubmit(selectedDayOfTheWeekIndex, isToApplyPendingChanges) {

    if (!isToApplyPendingChanges) {
        return true;
    }

    let currentWeekMoods = initialState.currentWeekMoods.map(m => m);

    var selectedRating = currentWeekMoods[selectedDayOfTheWeekIndex].mood.selectedMoodRating;
    var ratingFromDB = currentWeekMoods[selectedDayOfTheWeekIndex].mood.moodRatingFromDb;

    if ((selectedRating === 0 && ratingFromDB === 0)
        ||
        (selectedRating === null && ratingFromDB === null)) {
        return false;
    } else {
        return true;
    }
}
export default moodDetailReducer;