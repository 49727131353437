import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function TutorialCard(props) {

    function renderTitle() {
        if ((props.title == null || props.title == "")
            && (props.subTitle == null || props.subTitle == "")) {
            return null;
        } else {
            return (
                <div>
                    <DialogTitle id="alert-dialog-title">
                        {props.title}
                        <br />
                        {props.subTitle}
                    </DialogTitle>
                </div>
            );
        }
    }

    return (

        <div className={"help"}>

            {props.imageComponentToRender}
            < meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            {renderTitle()}

            <DialogContent >
                <DialogContentText id="alert-dialog-description">
                    {props.modalMessage}
                </DialogContentText>

            </DialogContent >
        </div>



    );
}
