import React, { useEffect, useState } from 'react';
import HappyShopItemDetailHeader from '../../components/Coins/HappyShopItemDetailHeader';
import HappyShopItemDetail from '../../components/Coins/HappyShopItemDetail';
import * as happyShopItemService from '../../api/happyShopItemService';
import { getUserTotalCoinsForCompany } from '../../api/coinService';

export default function HappyShopItemDetailContainer(props) {
    const [itemId, setItemId] = useState(0);
    const [itemDetail, setItemDetail] = useState(null);
    const [canUserBuyMoreItemStock, setCanUserBuyMoreItemStock] = useState(false);
    const [userCanAffordItem, setUserCanAffordItem] = useState(false);
    const [userHappyCoins, setUserHappyCoins] = useState(0);
    const [missingHappyCoins, setMissingHappyCoins] = useState(0);

    useEffect(() => {
        getUserTotalCoinsForCompany()
            .then(response => {
                return response;
            }).then(coins => {
                setUserHappyCoins(coins);
            });

    }, [itemId])

    useEffect(() => {
        if (itemId === 0) {
            setItemId(props.match != null ? props.match.params.itemId : 0);
        } else {
            // Get item detail
            happyShopItemService.getItem(itemId, handleItemDetailSet).then(item => {
                handleItemDetailSet(item);
            });

            // Check if the current user can buy more of this item
            happyShopItemService.canUserBuyMoreItemStock(itemId, setCanUserBuyMoreItemStock)
                .then(flag => {
                    setCanUserBuyMoreItemStock(flag);
                });
        }
    }, [itemId, userHappyCoins])

    function handleItemDetailSet(item) {        
        setItemDetail(item);
        setUserCanAffordItem(item.price <= userHappyCoins);
        setMissingHappyCoins(item.price - userHappyCoins);
    }

    if (itemId === 0) {
        return (null);
    } else {
        return (
            <div>
                <HappyShopItemDetailHeader />
                <HappyShopItemDetail
                    itemId={itemId}
                    itemDetail={itemDetail}
                    userHappyCoins={userHappyCoins}
                    missingHappyCoins={missingHappyCoins}
                    userCanAffordItem={userCanAffordItem}
                    canUserBuyMoreItemStock={canUserBuyMoreItemStock} />
            </div>
        );
    }
}