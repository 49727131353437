import React from 'react';
import { navIconStyle } from "../../css/globalStyles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';

export default function AssignAddIcon(props) {

  return (
    <IconButton
      edge="end"
      aria-label="Atribuir mérito"
      onClick={props.onPraiseAssignClick.bind(this, true)}>
      <AddCircleIcon style={{ ...navIconStyle.orangeColor, ...navIconStyle.svgSizeList }} />
    </IconButton>
  );
}