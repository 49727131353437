import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactDOM from 'react-dom';

export default function ModalImageSimpleConfirm(props) {

    function handleClose(isToSkipFunction) {
        // If the user clicks outside of the modal we just close it
        // isToSkipFunction skips the button function and closes the modal
        if (!isToSkipFunction && props.buttonFunction) {
            props.buttonFunction();
        }

        ReactDOM.render(this, document.getElementById("idModalSection"))
    };

    function renderTitle() {
        return (
            <div>
                <DialogTitle id="alert-dialog-title">
                    {props.modalTitle}
                    <br />
                    {props.modalSubTitle}
                </DialogTitle>
            </div>
        );
    }

    return (
        <Dialog
            open={props.isOppenedByDefault}
            onClose={handleClose.bind(this, true)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="modals"
        >
            {props.imageComponentToRender}
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            {renderTitle()}





            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.modalMessage}
                </DialogContentText>

            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose.bind(this, false)}
                    color="primary" autoFocus>
                    {props.buttonText}
                </Button>
            </DialogActions>

        </Dialog>

    );
}
