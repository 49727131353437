import React from 'react';

export default function ModalsHeaderNews(props) {

  return (
    <div>
      <div className="ModalsHeader">
        <div>          
          <img alt={""} src={props.companyLogo} className="logo"/>
        </div>
      </div>
      <div className="ModalsHeaderShadow"></div>
    </div>
  );
}
