import React, { useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ModalsComments from '../../containers/_Global/ModalsComments';



export default function MoodInfluence(props) {

  const [commentModalOpenned, setCommentModalOpenned] = useState(false);
  const [commentModalTextValue, setCommentModalTextValue] = useState("");

  useEffect(() => {
    setCommentModalTextValue(
      props.associatedInfluenceEntry != null
        ? props.associatedInfluenceEntry.commentText
        : "");
  }, [props.associatedInfluenceEntry])

  const openCommentModal = () => {
    setCommentModalOpenned(true);
  };

  const closeCommentModal = (textValue) => {
    if (textValue !== commentModalTextValue) {
      setCommentModalTextValue(textValue)
      props.onInfluenceEntryCommentChanged(props.associatedInfluenceEntry.id, textValue);
    }
    setCommentModalOpenned(false);
  };

  function getThumbIconClass(buttonType, infEntry) {
    if (infEntry != null) {

      if (infEntry.rating === 1 && buttonType === "thumbsUp") {
        return "thumbIcon like";
      }
      else if (infEntry.rating === -1 && buttonType === "thumbsDown") {
        return "thumbIcon unlike";
      }
    }


    return "thumbIcon";
  }

  function renderChatBubbltIcon() {
    if (props.associatedInfluenceEntry != null
      && props.associatedInfluenceEntry.rating !== 0) {
      return (
        <IconButton aria-label="comment" style={{ marginRight: '10px' }} onClick={openCommentModal}>
          {commentModalTextValue != null && commentModalTextValue.length
            ? <ChatBubbleIcon />
            : <ChatBubbleOutlineIcon />}
        </IconButton>
      );
    }
    else {
      return (<IconButton aria-label="comment" style={{ marginRight: '35px' }} />);
    }
  }

  return (
    <ListItem>
      {renderChatBubbltIcon()}
      <ListItemText
        primary={props.influenceTitle}
      />

      <IconButton
        edge="end"
        aria-label="Open"
        onClick={props.onThumbClickFunction.bind(
          this,
          "thumbsDown",
          props.associatedInfluenceEntry,
          props.influenceId,
          props.influenceCategoryId)}
        className={getThumbIconClass("thumbsDown", props.associatedInfluenceEntry)}>
        <ThumbDownIcon />
      </IconButton>

      <IconButton
        edge="end"
        aria-label="Open"
        onClick={props.onThumbClickFunction.bind(
          this,
          "thumbsUp",
          props.associatedInfluenceEntry,
          props.influenceId,
          props.influenceCategoryId)}
        className={getThumbIconClass("thumbsUp", props.associatedInfluenceEntry)}>

        <ThumbUpIcon />
      </IconButton>

      {/* Render comment modal if the button was clicked 
        If the commentModalOpenned is false we dont want to show the modal
      */}
      {commentModalOpenned
        ? <ModalsComments
          userName={props.userName}
          pictureToDisplay={props.profilePictureFullPath}
          preFilledValue={commentModalTextValue}
          onModalClosed={closeCommentModal} />
        : null}


    </ListItem>

  );
}