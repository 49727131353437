import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import * as newsService from '../../api/newsService';


export default function NewsListRowElement(props) {
    const [isBadgeInvisible, setIsBadgeInvisible] = React.useState(true);
    const [isNewsReadByUser, setIsNewsReadByUser] = React.useState(false);

    useEffect(() => {
        newsService.isNewsReadByUser(props.id, setBadgeAndFlag).then(flag => {
            setBadgeAndFlag(flag);
        });
    }, [])

    function setBadgeAndFlag(flag) {
        setIsBadgeInvisible(flag);
        setIsNewsReadByUser(flag);
    }

    return (

        <ListItem button onClick={
            props.onRowElementClick.bind(
                this,
                props.id,
                props.title,
                props.summary,
                props.messageContent,
                props.imageURL,
                isNewsReadByUser,
                props.dateCreated)}>
            <Badge color="secondary" invisible={isBadgeInvisible} variant={"dot"} />
            <div className="NewsImg">
                <img src={props.imageURL} alt=""/>
            </div>
            <ListItemText
                className="NewsText"
                primary={props.title}
                secondary={
                    <React.Fragment>
                        {props.summary}
                    </React.Fragment>
                }
            />
        </ListItem >
    );
}