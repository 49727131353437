import React from 'react';
import { ReactComponent as MoodIcon1 } from '../../images/Icons/moodStroke1.svg';
import { ReactComponent as MoodIcon2 } from '../../images/Icons/moodStroke2.svg';
import { ReactComponent as MoodIcon3 } from '../../images/Icons/moodStroke3.svg';
import { ReactComponent as MoodIcon4 } from '../../images/Icons/moodStroke4.svg';
import { ReactComponent as MoodIcon5 } from '../../images/Icons/moodStroke5.svg';
import { ReactComponent as MoodIconFill1 } from '../../images/Icons/moodFill1.svg';
import { ReactComponent as MoodIconFill2 } from '../../images/Icons/moodFill2.svg';
import { ReactComponent as MoodIconFill3 } from '../../images/Icons/moodFill3.svg';
import { ReactComponent as MoodIconFill4 } from '../../images/Icons/moodFill4.svg';
import { ReactComponent as MoodIconFill5 } from '../../images/Icons/moodFill5.svg';
import { navIconStyle } from "../../css/globalStyles.js";
import NavIcon from '../../components/Home/NavIcon';
import {getColorBasedMoodValue} from '../../utils/utils';

export default function MoodSelectionHorizontal(props) {


    const styles = {
        center: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            //minHeight: '200px',
            textAlign: 'center',
        },
        date: {
            color: 'white',
            fontSize: '12px',
            fontWeight: "300",
            fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            padding: '10px 0'
        },
        buttons: {
            display: 'inline-block',
            //marginBottom: '30px'
        },
        button: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: '0',
            cursor: 'pointer',
            display: 'inline-block',
            textAlign: 'left',
            padding: '0px',
        },
        moodIcon: {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        svgSize: {
            width: '64px',
            height: 'auto',
        },
    };


    function getIconToRender(iconTypeRating) {

        let iconToRender;


        switch (iconTypeRating) {
            case 1:
                iconToRender = decideIcon(
                    iconTypeRating, props.selectedMoodRating, <MoodIconFill1 />, <MoodIcon1 />)
                break;

            case 2:
                iconToRender = decideIcon(
                    iconTypeRating, props.selectedMoodRating, <MoodIconFill2 />, <MoodIcon2 />)
                break;

            case 3:
                iconToRender = decideIcon(
                    iconTypeRating, props.selectedMoodRating, <MoodIconFill3 />, <MoodIcon3 />)
                break;

            case 4:
                iconToRender = decideIcon(
                    iconTypeRating, props.selectedMoodRating, <MoodIconFill4 />, <MoodIcon4 />)
                break;

            case 5:
                iconToRender = decideIcon(
                    iconTypeRating, props.selectedMoodRating, <MoodIconFill5 />, <MoodIcon5 />)
                break;

            default:
                break;
        }

        // Local function to decide the icon
        function decideIcon(iconTypeRating, selectedMoodRating, iconFill, iconSimple) {
            let iconToRender;

            if (iconTypeRating === selectedMoodRating) {
                iconToRender = iconFill;
            }
            else {
                iconToRender = iconSimple;
            }
            return iconToRender;
        }

        return iconToRender;
    }

    function renderIcon(iconIndex) {
        let navIconStyleDynamic = navIconStyle.whiteColorTransparency;

        if (props.selectedMoodRating === iconIndex) {
            navIconStyleDynamic = getColorBasedMoodValue(iconIndex);
        }

        return (
            <span style={{ ...navIconStyleDynamic, ...styles.svgSize, ...styles.moodIcon }} >
                <NavIcon style={{ ...navIconStyle.whiteColorTransparency, ...styles.svgSize, ...styles.moodIcon }}
                    iconToRender={getIconToRender(iconIndex)}
                    onClickFunction={props.onClickFunction.bind(this, iconIndex)} />
            </span>
        );
    }


    return (
        <div style={styles.center} >
            <div>

                <div style={styles.date}>{props.moodDay}</div>
                <div style={styles.buttons}>
                    {renderIcon(1)}
                    {renderIcon(2)}
                    {renderIcon(3)}
                    {renderIcon(4)}
                    {renderIcon(5)}
                </div>
            </div>
        </div>
    );
}