import React, { useEffect } from 'react';
import MedalsDetailHeaderContainer from '../../containers/Medals/MedalsDetailHeaderContainer';
import MedalsDetailTabsContainer from '../../containers/Medals/MedalsDetailTabsContainer';
import MedalsDetailBottomNav from '../../containers/Medals/MedalsDetailBottomNav';
import { connect } from 'react-redux';
import { navIconStyle } from "../../css/globalStyles";
import * as medalService from '../../api/medalService';
import * as medalCategoryService from '../../api/medalCategoryService';
import MedalCategoryRowElement from '../../components/Medals/MedalCategoryRowElement';
import * as medalActions from '../../redux/actions/medalActions';
import { useHistory } from 'react-router-dom';
import MedalsGiveStar from '../../components/Medals/MedalsGiveStar';
import { getSpecificUserDetails } from '../../api/userService';
import { fetchWithBearerToken } from '../../utils/utils';

function MedalsDetailContainer(props) {

    const [receivedMedalsFromCompanyRowElements, setReceivedMedalsFromCompanyRowElements] = React.useState([]);
    const [assignedMedalsToCompanyRowElements, setAssignedMedalsToCompanyRowElements] = React.useState([]);

    // The medals received from the user checking the profile
    const [receivedMedalsFromCurrentUserRowElements, setReceivedMedalsFromCurrentUserRowElements] = React.useState([]);

    // The medals assigned to the user checking the profile    
    const [assignedMedalsToCurrentUserRowElements, setAssignedMedalsToCurrentUserRowElements] = React.useState([]);

    const [receivedMedalCount, setReceivedMedalCount] = React.useState(0);
    const [assignedMedalCount, setAssignedMedalCount] = React.useState(0);

    const [medalCategories, setMedalCategories] = React.useState([]);
    const [medalDetailData, setMedalDetailData] = React.useState([]);
    const history = useHistory();

    const [targetUserDetails, setTargetUserDetails] = React.useState();

    useEffect(() => {
        getUserDetail();
    }, [])

    useEffect(() => {
        if (targetUserDetails == null) {
            return;
        }


        if (medalCategories != null && medalCategories.length !== 0) {
            // Get medal detail data

            if (props.isMedalDetailComingFromLocalUser) {
                // In this case the user is viewing his own profile
                // despite sending "selectedUserMedalDetail" as argument it will not be used
                medalService.getMedalDetailDataWithUserList(
                    props.currentUser.id,
                    targetUserDetails.id,
                    props.currentUser.currentAssociatedCompany.id,
                    setMedalDetailData)
                    .then(data => {
                        setMedalDetailData(data);
                    });

            } else {
                // Since the user is vieweing someone's profile we send the "selectedUserMedalDetail" first                
                medalService.getMedalDetailDataWithUserList(
                    targetUserDetails.id,
                    props.currentUser.id,
                    props.currentUser.currentAssociatedCompany.id,
                    setMedalDetailData)
                    .then(data => {
                        setMedalDetailData(data);
                    });
            }



        } else {
            medalCategoryService.getAllMedalCategoriesForCompany(
                props.currentUser.currentAssociatedCompany.id,
                setMedalCategories)
                .then(medalCategories => {
                    setMedalCategories(medalCategories);
                })
        }

    }, [medalCategories, props.isMedalDetailComingFromLocalUser, targetUserDetails])

    useEffect(() => {

        if (medalDetailData != null && medalDetailData.length !== 0) {
            loadRowElements(medalDetailData.receivedMedalsFromCompany, setReceivedMedalsFromCompanyRowElements);
            loadRowElements(medalDetailData.assignedMedalsToCompany, setAssignedMedalsToCompanyRowElements);

            loadRowElements(medalDetailData.assignedMedalsToUser, setReceivedMedalsFromCurrentUserRowElements);
            loadRowElements(medalDetailData.receivedMedalsFromUser, setAssignedMedalsToCurrentUserRowElements);
        }
    }, [medalDetailData])

    useEffect(() => {
        if (medalDetailData != null && medalDetailData.length !== 0) {
            setTotalMedalCount();
        }

    }, [props.historyType, medalDetailData])

    function getUserDetail() {

        if (props.isMedalDetailComingFromLocalUser === true) {
            setTargetUserDetails(props.currentUser);
        }
        else {
            let userId = props.match != null ? props.match.params.userId : null;

            if (userId != null && userId != 0) {
                getSpecificUserDetails(userId).then(detail => {

                    let userDetails = {
                        id: detail.id,
                        name: detail.name,
                        email: detail.email,
                        currentAssociatedCompany: detail.currentAssociatedCompany,
                        receivedMedalCount: detail.receivedMedalCount,
                        assignedMedalCount: detail.assignedMedalCount,
                        medalRankPosition: detail.medalRankPosition,
                        totalMedalsStyle: medalService.getStartColorBasedOnRanking(detail.medalRankPosition, detail.receivedMedalCount),
                        appraisalsReceivedFromSystemThisWeek: detail.appraisalsReceivedFromSystemThisWeek,
                        currentAvailableAppraisalsToSpend: detail.availableAppraisalsToSpend,
                        hasUserGivenAppraisalsThisWeek: detail.hasUserGivenAppraisalsThisWeek,
                        profilePictureFullPath: detail.profilePictureFullPath,
                        profilePictureThumbnailPath: detail.profilePictureThumbnailPath
                    }

                    setTargetUserDetails(userDetails);
                });

            }
            else {
                setTargetUserDetails(props.selectedUserMedalDetail);

            }
        }
    }

    function setTotalMedalCount() {
        if (props.historyType === "GENERAL") {
            if (props.isMedalDetailComingFromLocalUser === true) {

                setReceivedMedalCount(props.currentUser.receivedMedalCount);
                setAssignedMedalCount(props.currentUser.assignedMedalCount);
            }
            else {
                setReceivedMedalCount(targetUserDetails.receivedMedalCount);
                setAssignedMedalCount(targetUserDetails.assignedMedalCount);
            }
        }
        else if (props.historyType === "COMMON") {

            let receivedFromCurrentUserMedalTotalCount = medalDetailData.assignedMedalsToUser
                .map(m => m.medalCount)
                .reduce((a, b) => a + b);

            let assignedToCurrentUserMedalTotalCount = medalDetailData.receivedMedalsFromUser
                .map(m => m.medalCount)
                .reduce((a, b) => a + b);

            setReceivedMedalCount(receivedFromCurrentUserMedalTotalCount);
            setAssignedMedalCount(assignedToCurrentUserMedalTotalCount);
        }
    }


    function getTotalMedalsStyle() {
        // let userDetail = getUserDetail();


        if (props.historyType === "GENERAL") {
            return targetUserDetails.totalMedalsStyle;
        }
        else if (props.historyType === "COMMON") {
            return navIconStyle.whiteColorTransparency;
        }
    }

    function onCategoryRowElementClicked(categoryId) {
        props.setDirectClickCategoryId(categoryId);
        history.push(process.env.REACT_APP_ROUTING_MEDALS_ASSIGN);
    }

    function loadRowElements(medalsData, callback) {        
        let assignedCategoryElementsUpdated = [];
        let companyId = props.currentUser.currentAssociatedCompany.id;

        let mapPromise =
            medalsData.map(rowData => {
                assignedCategoryElementsUpdated.push(
                    <MedalCategoryRowElement
                        key={rowData.category.id}
                        medalUserDetailList={rowData.medalUserDetailList}
                        onRowClickCallback={onCategoryRowElementClicked}
                        isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser}
                        numberOfComments={0} // TODO JS: fetch comments                         
                        categoryName={rowData.category.name}
                        categoryId={rowData.category.id}
                        medalCount={rowData.medalCount}
                        userId={targetUserDetails.id}
                        companyId={companyId}
                        medalStyle={rowData.medalCount !== 0
                            ? navIconStyle.whiteColor
                            : navIconStyle.whiteColorTransparency} />
                );
            });

        // Wait for everything to load and the set the state
        Promise.all([mapPromise]).then(() => {
            callback(
                sortMedalCategories(assignedCategoryElementsUpdated, true));
        })
    }

    function sortMedalCategories(medalArray, bo) {
        let sortedArray = medalArray.sort(function (a, b) {
            return b.props.medalCount - a.props.medalCount
        });

        return sortedArray;

    }

    function clearHighlightsHistorySettings() {
        props.setMedalHistroyBackSettings(false, null);
    }


    return (

        <div>


            <MedalsDetailHeaderContainer
                i18n={props.i18n}
                translateFunc={props.translateFunc}
                historyType={props.historyType}
                isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser}
                isMedalDetailComingFromHighlights={props.isMedalDetailComingFromHighlights}
                highlightHistoryLastPath={props.highlightHistoryLastPath}
                clearHighlightsHistorySettings={clearHighlightsHistorySettings} />
            {
                targetUserDetails != null
                    ? <MedalsDetailTabsContainer
                        i18n={props.i18n}
                        translateFunc={props.translateFunc}
                        totalMedalsStyle={getTotalMedalsStyle()}
                        totalReceivedMedalCount={receivedMedalCount}
                        totalAssignedMedalCount={assignedMedalCount}
                        historyType={props.historyType}
                        localUserDetail={props.currentUser}
                        selectedUserMedalDetail={targetUserDetails}
                        receivedMedalsRowElements={
                            props.historyType === "GENERAL"
                                ? receivedMedalsFromCompanyRowElements // medals received from company
                                : receivedMedalsFromCurrentUserRowElements} // medals received from the user checking the profile
                        assignedMedalsRowElements={
                            props.historyType === "GENERAL"
                                ? assignedMedalsToCompanyRowElements // medals given to company
                                : assignedMedalsToCurrentUserRowElements} // medals assigned to the user checking the profile
                        isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser} />
                    : null
            }

            {
                targetUserDetails != null && props.currentUser.id === targetUserDetails.id
                    ? null
                    : <MedalsGiveStar
                        routingUserId={props.match != null ? props.match.params.userId : null}
                        isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser} />
            }

            {
                targetUserDetails != null && props.currentUser.id === targetUserDetails.id
                    ? null
                    : <MedalsDetailBottomNav
                        i18n={props.i18n}
                        translateFunc={props.translateFunc}
                        historyType={props.historyType}
                        isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser} />
            }

        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        isMedalDetailComingFromLocalUser: state.medalsReducer.isMedalDetailComingFromLocalUser,
        isMedalDetailComingFromHighlights: state.medalsReducer.isMedalDetailComingFromHighlights,
        highlightHistoryLastPath: state.medalsReducer.highlightHistoryLastPath,
        selectedUserMedalDetail: state.medalsReducer.selectedUserMedalDetail,
        currentUser: state.userReducer.currentUser,
        historyType: state.medalsReducer.historyType
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setMedalHistroyBackSettings: (isMedalDetailComingFromHighlights, highlightHistoryLastPath) => dispatch(
            medalActions.setMedalHistroyBackSettings(isMedalDetailComingFromHighlights,
                highlightHistoryLastPath)
        ),
        setDirectClickCategoryId: (categoryId) => dispatch(
            medalActions.setDirectClickCategoryId(categoryId)
        ),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MedalsDetailContainer);