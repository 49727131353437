import React from 'react';

export default function PersonalInfoName(props) {

  return (
    <div className="PersonalInfoName">
      <div>{props.userName}</div>
      <span>{props.companyName}</span>
    </div>
  );
}