import React from 'react';

export default function HistoryNumber(props) {

  return (
      <div className="HistoryNumber">
        {props.number}
      </div>
  );
}
