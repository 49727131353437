import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import { useHistory } from 'react-router-dom';
import NewsListRowElement from '../../components/News/NewsListRowElement';
import * as newsService from '../../api/newsService';
import { connect } from 'react-redux';
import * as newsActions from '../../redux/actions/newsActions';
import * as coinService from '../../api/coinService';
import * as modalUtils from '../../utils/modalUtils';
import ModalsHeaderHappycoinsWon from '../../containers/_Global/ModalsHeaderHappycoinsWon';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';

function NewsListContainer(props) {
  const history = useHistory();
  const [newsList, setNewsList] = useState(null);

  useEffect(() => {
    getNewsList();

  }, [])


  function onRowElementClicked(id, title, summary, messageContent, imageURL, isNewsReadByUser, dateCreated) {
    
    goToNewsDetail(id, title, summary, messageContent, imageURL, dateCreated);
  }

  function goToNewsDetail(id, title, summary, messageContent, imageURL, dateCreated) {

    let newsObject = {
      id: id,
      title: title,
      summary: summary,
      messageContent: messageContent,
      imageURL: imageURL,
      dateCreated: dateCreated
    }

    props.selectNews(newsObject);

    history.push(process.env.REACT_APP_ROUTING_NEWS_DETAIL);
  }

  function mapNewsList(newsList) {
    let rowElementsList = [];
    newsList.map(n => {
      rowElementsList.push(
        <NewsListRowElement
          key={n.id}
          id={n.id}
          title={n.title}
          dateCreated={n.dateCreated}
          summary={n.summary}
          messageContent={n.messageContent}
          imageURL={n.imageURL}
          onRowElementClick={onRowElementClicked} />
      );
    });
    setNewsList(rowElementsList);

  }

  function getNewsList() {
    newsService.getCompanyNewsList(mapNewsList)
      .then(newsList => {
        mapNewsList(newsList);
      });
  }

  return (
    <List className="NewsList">

      {newsList != null ? newsList : <LoadingComponent />}

    </List>
  );
}



const mapDispatchToProps = (dispatch) => {
  return {
    selectNews: (newsObject) => dispatch(
      newsActions.selectNews(newsObject)
    )
  }
}

export default connect(null, mapDispatchToProps)(NewsListContainer)