import React from 'react';

export default function CompanyLogo(props) {

  return (
    <div className="CompanyLogo">
      {props.companyLogo != null
        ? <img src={props.companyLogo}
          alt={'Organização'} />
        : <h2>{props.companyName}</h2>
      }

    </div>
  );
}