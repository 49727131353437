
export function getApiVersion() {

    return fetch(
        process.env.REACT_APP_URL_API_GET_API_VERSION)
        .then(response => response.text());
}

export function getLanguageCookieValue(lang) {
    return localStorage.getItem("i18nextLng");
}

// This func sets the i18nextLng local storage key vlaue.
export function setLanguageCookieValue(lang) {

    localStorage.setItem("i18nextLng", lang);
}


