import React from 'react';
import { ReactComponent as MoodIconAwful } from '../../images/Icons/moodStroke1.svg';
import { ReactComponent as MoodIconBad } from '../../images/Icons/moodStroke2.svg';
import { ReactComponent as MoodIconOk } from '../../images/Icons/moodStroke3.svg';
import { ReactComponent as MoodIconGood } from '../../images/Icons/moodStroke4.svg';
import { ReactComponent as MoodIconFantastic } from '../../images/Icons/moodStroke5.svg';
import { navIconStyle } from '../../css/globalStyles.js';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as moodDetailActions from '../../redux/actions/moodDetailActions';

function MoodSelectionVertical(props) {

    let history = useHistory();

    const styles = {
        date: {
            color: 'white',
            fontSize: '12px',
            fontWeight: "300",
            fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
        quotes: {
            fontFamily: "'Amatic SC', 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize: '36px',
            textAlign: 'center',
            color: 'white',
            padding: '20px',
            lineHeight: '1',
        },
        buttons: {
            maxWidth: '300px',
            display: 'inline-block',
        },
        button: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: '0',
            cursor: 'pointer',
            display: 'block',
            textAlign: 'left',
            padding: '5px',
        },
        buttonText: {
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: '20px',
            textTransform: 'uppercase',
            fontSize: '14px',
            color: 'white',
            fontWeight: "300",
            fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
        moodIcon: {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        svgSize: {
            width: '64px',
            height: 'auto',
        }
    };

    function handleMoodClick(moodValue) {
        props.onMoodSelected(moodValue, props.currentDayName);
        history.push(process.env.REACT_APP_ROUTING_MOOD_DETAIL);
    }

    return (
        <div className="moodVertical">
            <div>
                {/* <div style={styles.date}>{props.currentDay}</div> */}
                <div style={styles.quotes}>
                    {props.translateFunc("MOOD_HOW_WAS_YOUR_DAY_PART1")}
                    <br />
                    {props.translateFunc("MOOD_HOW_WAS_YOUR_DAY_PART2")}
                </div>
                <div style={styles.buttons}>
                    <button style={styles.button} onClick={handleMoodClick.bind(this, 5)}>
                        <span style={styles.moodIcon}>
                            <MoodIconFantastic style={{ ...navIconStyle.whiteColorTransparency, ...styles.svgSize }} />
                        </span>
                        <span style={styles.buttonText}> {props.translateFunc("MOOD_TYPE_FANTASTIC")}</span>
                    </button>
                    <button style={styles.button} onClick={handleMoodClick.bind(this, 4)}>
                        <span style={styles.moodIcon}>
                            <MoodIconGood style={{ ...navIconStyle.whiteColorTransparency, ...styles.svgSize }} />
                        </span>
                        <span style={styles.buttonText}>{props.translateFunc("MOOD_TYPE_GOOD")}</span>
                    </button>
                    <button style={styles.button} onClick={handleMoodClick.bind(this, 3)}>
                        <span style={styles.moodIcon}>
                            <MoodIconOk style={{ ...navIconStyle.whiteColorTransparency, ...styles.svgSize }} />
                        </span>
                        <span style={styles.buttonText}>{props.translateFunc("MOOD_TYPE_OK")}</span>
                    </button>
                    <button style={styles.button} onClick={handleMoodClick.bind(this, 2)}>
                        <span style={styles.moodIcon}>
                            <MoodIconBad style={{ ...navIconStyle.whiteColorTransparency, ...styles.svgSize }} />
                        </span>
                        <span style={styles.buttonText}>{props.translateFunc("MOOD_TYPE_BAD")}</span>
                    </button>
                    <button style={styles.button} onClick={handleMoodClick.bind(this, 1)}>
                        <span style={styles.moodIcon}>
                            <MoodIconAwful style={{ ...navIconStyle.whiteColorTransparency, ...styles.svgSize }} />
                        </span>
                        <span style={styles.buttonText}>{props.translateFunc("MOOD_TYPE_AWFUL")}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {

    return {
        onMoodSelected: (moodValue, currentDayName) => dispatch(
            moodDetailActions.onMoodSelected(moodValue, currentDayName))
    };
};

export default connect(null, mapDispatchToProps)(MoodSelectionVertical);