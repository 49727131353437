import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography, Collapse } from '@material-ui/core';
import MoodInfluence from './MoodInfluence.jsx';
import { connect } from 'react-redux';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import { getNumberOfInfluencesFilled } from '../../api/influenceService';
import Badge from '@material-ui/core/Badge';
import { navIconStyle } from "../../css/globalStyles";

import ListItemIcon from '@material-ui/core/ListItemIcon';

function MoodInfluenceCategory(props) {

  const [hasLoadedInfluenceEntries, setHasLoadedInfluenceEntries] = React.useState(false);
  const [influenceEntries, setInfluenceEntries] = React.useState([]);
  const [numberOfInfluencesFilled, setNumberOfInfluencesFilled] = React.useState(0);
  const [isCategoryOpened, setIsCategoryOpened] = React.useState(false);  


  useEffect(() => {
    getInfluencEntriesFromRedux();    
  }, []);

  useEffect(() => {
    loadNumberOfInfluencesFilled(props.categoryId);
  }, [influenceEntries]);


  // Renders the influence list
  function mapFactorCategories() {
    if (props.factorCategories != null) {

      return props.factorCategories.map((factor, index) => {

        // The associated Inf Entry is fetched by comparing the influenceId
        // bacause one infEntry points to ONE influence
        let associatedInfluenceEntry =
          (influenceEntries != null && influenceEntries.length)
            ? influenceEntries
              .filter(infEntry => infEntry.influenceId === factor.influenceId)[0]
            : null;

        return (

          <MoodInfluence
            userName={props.currentUser.name}
            influenceTitle={factor.influence.title}
            influenceId={factor.influenceId}
            key={index}
            profilePictureFullPath={props.currentUser.profilePictureFullPath}
            associatedInfluenceEntry={associatedInfluenceEntry}
            influenceCategoryId={factor.influenceCategoryId}
            onThumbClickFunction={handleThumbClickFunction}
            onInfluenceEntryCommentChanged={onInfluenceEntryCommentChanged}
          />

        )
      });
    }
  }

  // The function that is called after clicking on the list item to expand
  // This is called from MoodInfluenceCategoryContainer.jsx
  function onClickExpandCallBack(hasLoadedInfluenceEntriesFlag, influenceEntries) {

    setIsCategoryOpened(!isCategoryOpened);

    if (hasLoadedInfluenceEntriesFlag != null) {
      setHasLoadedInfluenceEntries(hasLoadedInfluenceEntriesFlag);
    }

    if (influenceEntries != null && influenceEntries.length) {
      setInfluenceEntries(influenceEntries);
    }
  }

  const handleThumbClickFunction = (
    buttonType,
    influenceEntry,
    influenceId,
    influenceCategoryId) => {
    // buttonType --> "thumbsUp" or "thumbsDown"

    //If influence entry exists we update its state
    let infRating;

    if (influenceEntry != null &&
      (buttonType === "thumbsUp" && influenceEntry.rating === 1
        || buttonType === "thumbsDown" && influenceEntry.rating === -1)) {
      infRating = 0;
    }
    else {
      infRating = buttonType === "thumbsUp" ? 1 : -1;
    }


    if (influenceEntry != null) {

      let updatedInfluenceEntry = {
        id: influenceEntry.id,
        moodId: influenceEntry.moodId,
        influenceId: influenceEntry.influenceId,
        factorInfluenceCategoryId: influenceCategoryId,
        rating: infRating,
        commentText: influenceEntry.comment != null ? influenceEntry.comment.text : null
      }

      // update the influence in the array
      let newInfEntries = influenceEntries.map(i =>
        i.influenceId === influenceEntry.influenceId
          ? updatedInfluenceEntry
          : i);

      setInfluenceEntries(newInfEntries);
      props.updateInfluenceEntry(props.selectedDayOfTheWeekIndex, updatedInfluenceEntry);

    }
    else {
      //If influence entry is null we create a new one
      let updatedInfluenceEntry = {
        id: 0,
        moodId: props.currentWeekMoods[props.selectedDayOfTheWeekIndex].mood.id,
        influenceId: influenceId,
        factorInfluenceCategoryId: influenceCategoryId,
        rating: infRating,
        commentText: null
      }

      // Add the influence to the array
      let newInfEntries = influenceEntries.map(i => i);
      newInfEntries.push(updatedInfluenceEntry);
      setInfluenceEntries(newInfEntries);

      props.addInfluenceEntry(props.selectedDayOfTheWeekIndex, updatedInfluenceEntry);
    }
  }

  function loadNumberOfInfluencesFilled(categoryId) {

    // let totalInfEntryFilledCount = null;



    let currentMoodRedux = props.currentWeekMoods[props.selectedDayOfTheWeekIndex];

    if (currentMoodRedux != null
      && currentMoodRedux.mood != null
      && currentMoodRedux.mood.id != null
      && currentMoodRedux.mood.id !== 0) {
      //Fetcht the DB to see the number of InfEntries filled
      getNumberOfInfluencesFilled(
        categoryId,
        currentMoodRedux.mood.id,
        setNumberOfInfluencesCount)
        .then(count => {
          setNumberOfInfluencesCount(count, categoryId, currentMoodRedux.mood.id);
        });
    }

  }

  function setNumberOfInfluencesCount(count, categoryId, moodId) {
    // Here we check between the infEntries from the DB and from the redux
    // Because the user can have pending InfEntries changes and from the DB at the same time
    // So we prioritize the Redux InfEntries.

    let mergedInfEntryCountFromPreviousDay = 0;

    if (props.influenceEntriesFromPreviousDay != null
      && props.influenceEntriesFromPreviousDay.length) {

      let infEntriesFilled = influenceEntries
        .filter(infEntry => infEntry.factorInfluenceCategoryId === categoryId && infEntry.rating !== 0)
        .length;     

      for (let index = 0; index < props.influenceEntriesFromPreviousDay.length; index++) {
        const infEntry = props.influenceEntriesFromPreviousDay[index];

        if (infEntriesFilled != null && infEntriesFilled.length) {
          if (!infEntriesFilled.map(i => i.influenceId).includes(infEntry.influenceId)) {
            if (infEntry.factorInfluenceCategoryId === categoryId) {
              mergedInfEntryCountFromPreviousDay++;
            }
          }
        }
        else {
          // If we have no influence entries we count the ones from the previous day
          if (infEntry.factorInfluenceCategoryId === categoryId) {
            mergedInfEntryCountFromPreviousDay++;
          }
        }
      }
    }

    // If the count is 0 means we have not influences for this mood yet
    // in that case we use the count from the previous influences.
    if (count !== 0) {
      setNumberOfInfluencesFilled(count);
    } else {

      setNumberOfInfluencesFilled(mergedInfEntryCountFromPreviousDay);
    }
  }

  // Set the influence entries when this page loads
  function getInfluencEntriesFromRedux() {
    let currentMoodRedux = props.currentWeekMoods[props.selectedDayOfTheWeekIndex];

    var influenceEntriesToSet = [];

    if (props.influenceEntriesFromPreviousDay != null
      && props.influenceEntriesFromPreviousDay.length !== 0) {

      influenceEntriesToSet = props.influenceEntriesFromPreviousDay;
    }

    if (currentMoodRedux != null && currentMoodRedux.mood != null
      && currentMoodRedux.influenceEntries != null
      && currentMoodRedux.influenceEntries.length !== 0) {

      for (let index = 0; index < currentMoodRedux.influenceEntries.length; index++) {
        const infEntryFromRedux = currentMoodRedux.influenceEntries[index];

        if (!influenceEntriesToSet.map(i => i.influenceId).includes(infEntryFromRedux.influenceId)) {

          influenceEntriesToSet.push(infEntryFromRedux);
        }
      }
    }

    setInfluenceEntries(influenceEntriesToSet);

    props.updateInfluenceEntries(
      props.selectedDayOfTheWeekIndex,
      influenceEntriesToSet,
      props.currentWeekMoods[props.selectedDayOfTheWeekIndex].mood.id);
  }

  function onInfluenceEntryCommentChanged(influenceEntryId, textValue) {
    props.updateInfluenceEntryComment(props.selectedDayOfTheWeekIndex, influenceEntryId, textValue)
  }

  function handleOnListClick() {
    props.handleListItemExpandClick(
      props.isOpenned,
      hasLoadedInfluenceEntries,
      props.categoryId,
      onClickExpandCallBack,
      props.influenceEntriesFromPreviousDay);
  }

  return (
    <div className="moodList">
      <ListItem button onClick={handleOnListClick} >

        <ListItemText primary={props.categoryName} />
        <ListItemSecondaryAction className="moodCategories">
          <ListItemIcon>
            {isCategoryOpened ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemIcon>
            <Typography>
              <Badge color="primary" badgeContent={numberOfInfluencesFilled !== 0 ? numberOfInfluencesFilled : null} style={navIconStyle.iconMargin} overlap="circular">
              </Badge>
            </Typography>
          </ListItemIcon>
          <ListItemIcon edge="end" aria-label="name here">
            {/* <ComputerIcon /> */}
            <img style={{ width: "24px" }} src={props.imageUrl} alt="" />
          </ListItemIcon>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isCategoryOpened} timeout="auto">
        <List component="div" disablePadding>
          {mapFactorCategories()}
        </List>
      </Collapse>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedDayOfTheWeekIndex: state.moodDetailReducer.selectedDayOfTheWeekIndex,
    currentWeekMoods: state.moodDetailReducer.currentWeekMoods,
    currentUser: state.userReducer.currentUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

    updateInfluenceEntry: (selectedDayOfTheWeekIndex, influenceEntry) => dispatch(
      moodDetailActions.updateInfluenceEntry(selectedDayOfTheWeekIndex, influenceEntry)
    ),
    addInfluenceEntry: (selectedDayOfTheWeekIndex, influenceEntry) => dispatch(
      moodDetailActions.addInfluenceEntry(selectedDayOfTheWeekIndex, influenceEntry)
    ),
    updateInfluenceEntryComment: (selectedDayOfTheWeekIndex, influenceEntryId, influenceCommentText) => dispatch(
      moodDetailActions.updateInfluenceEntryComment(selectedDayOfTheWeekIndex, influenceEntryId, influenceCommentText)
    ),
    updateInfluenceEntries: (selectedDayOfTheWeekIndex, influenceEntries, moodId) => dispatch(
      moodDetailActions.updateInfluenceEntries(
        selectedDayOfTheWeekIndex,
        influenceEntries,
        moodId)
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoodInfluenceCategory);