import React from 'react';
import BarChart from '../../components/History/BarChart';

export default function HistoryChartCard(props) {

  return (
    <div className="HistoryChartCard">
      <BarChart maxValueYaxis={props.maxValueYaxis} chartColor={props.chartColor} data={props.data} />
      <div className="ChartDescription">
        {props.description}
      </div>
    </div>
  );
}