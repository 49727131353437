import React, { useEffect } from "react";
import Quote from '../../components/Home/Quote';
import Copy from '../../components/Home/Copy';
import * as quoteService from '../../api/quoteService';

export default function QuotesContainer(props) {
    const [networkQuote, setNetworkQuote] = React.useState(null);
    const [cachedQuote, setCachedQuote] = React.useState(null);

    const [quoteToUse, setQuoteTouse] = React.useState(null);


    useEffect(() => {
        quoteService.getRandomQuote(setCachedQuote).then(q => {
            setNetworkQuote(q);
        });

    }, [])

    useEffect(() => {
        // Cached quote should be overriden if is diferente only
        // this is to avoid the quote change flickering
        const handleSetQuote = () => {

            if (cachedQuote != null) {
                setQuoteTouse(cachedQuote);
                return;
            } else {
                setQuoteTouse(networkQuote);
                return;

            }
        }
        handleSetQuote();

    }, [networkQuote, cachedQuote])




    return (
        <div>
            <Quote quoteText={quoteToUse != null ? quoteToUse.text : ""} />
            <Copy author={quoteToUse != null ? quoteToUse.author : ""} />
        </div>
    );

}