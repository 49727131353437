import React, { useEffect } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { connect } from 'react-redux';
import * as medalActions from '../../redux/actions/medalActions';
import { useTranslation } from 'react-i18next';

function MedalsDetailBottomNav(props) {
  const [value, setValue] = React.useState(props.historyType === "GENERAL" ? 0 : 1);

  const { t, i18n } = useTranslation();

  let translateFunc = t;
  let i18nEngine = i18n;

  useEffect(() => {
    if (props.translateFunc == null || props.i18n == null) {
      translateFunc = t;
      i18nEngine = i18n;
    } else {
      translateFunc = props.translateFunc;
      i18nEngine = props.i18n;
    }
  }, [])

  function handleBottomNavHistoryClick(historyType) {
    props.setMedalNavHistory(historyType);
  }

  function renderBottomNavigation() {
    if (props.isMedalDetailComingFromLocalUser) {
      return null;
    }
    else {

      return (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            label={translateFunc("MEDALS_HISTORY_GENERAL")}
            icon={<BusinessIcon />}
            onClick={handleBottomNavHistoryClick.bind(this, "GENERAL")} />

          <BottomNavigationAction
            label={translateFunc("MEDALS_HISTORY_COMMON")}
            icon={<PeopleAltIcon />}
            onClick={handleBottomNavHistoryClick.bind(this, "COMMON")} />

        </BottomNavigation>
      );
    }
  }

  return (
    renderBottomNavigation()
  );
}

const mapDispatchToProps = dispatch => {
  return {
    setMedalNavHistory: (historyType) => dispatch(
      medalActions.setMedalNavHistory(historyType))
  };
}

export default connect(null, mapDispatchToProps)(MedalsDetailBottomNav);