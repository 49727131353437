import React, { useEffect } from 'react';
import MoodQtOne from '../../components/Mood/MoodQtOne';
import MoodQtMultiple from '../../components/Mood/MoodQtMultiple';
import MoodQtText from '../../components/Mood/MoodQtText';
import { connect } from 'react-redux';
import Carousel from 'react-elastic-carousel'
import * as questionActions from '../../redux/actions/questionActions';
import { Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import * as moodService from '../../api/moodService';
import * as moodDetailActions from '../../redux/actions/moodDetailActions';
import { submitQuestions } from '../../api/questionService';
import ModalsHeaderDailyObjective from '../../containers/_Global/ModalsHeaderDailyObjective';
import * as coinService from '../../api/coinService';
import * as modalUtils from '../../utils/modalUtils';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';

function QuestionCarouselContainer(props) {


    const [questionsMergedComponents, setQuestionsMergedComponents] = React.useState();
    const [canShowLoading, setCanShowLoading] = React.useState(false);
    //const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = React.useState(true);

    useEffect(() => {
        if (props.questionCollection) {
            renderQuestions(props.questionCollection);
        }
    }, [props.questionCollection])

    /// Function that will be called when an answer was clicked or free text inserted.
    function onAnswerGiven(answerObject, isToApplyPendingChanges) {
        props.insertOrUpdateQuestion(answerObject);
    }

    function renderQuestions(questions) {

        if (questions && questions.length) {

            let questionsArray = [];

            var questionsPromise = questions.map(q => {

                let questionType;

                if (q.questionPossibleAnswers[0] != null) {

                    questionType = q.questionPossibleAnswers[0].question.questionType.value;
                } else {
                    // Free text is the only one that does not have a "PossibleAnswer"
                    questionType = "FREE_TEXT";
                }

                // Since these question types only allow 1 answer we can group them
                if (questionType === 'SINGLE_CHOICE'
                    || questionType === 'YES_NO'
                    || questionType === 'LEVELS') {

                    questionsArray.push(
                        <MoodQtOne
                            key={q.id}
                            questionId={q.id}
                            questionText={q.text}
                            answerCallback={onAnswerGiven}
                            possibleAnswers={q.questionPossibleAnswers}
                            coinRewardForAnsweringQuestions={props.coinRewardForAnsweringQuestions}
                        />
                    );

                } else if (questionType === "MULTIPLE_CHOICE") {

                    questionsArray.push(
                        <MoodQtMultiple
                            key={q.id}
                            questionId={q.id}
                            questionText={q.text}
                            answerCallback={onAnswerGiven}
                            possibleAnswers={q.questionPossibleAnswers}
                            coinRewardForAnsweringQuestions={props.coinRewardForAnsweringQuestions}
                        />
                    );
                } else if (questionType === "FREE_TEXT") {

                    questionsArray.push(
                        <MoodQtText
                            key={q.id}
                            questionId={q.id}
                            questionText={q.text}
                            answerCallback={onAnswerGiven}
                            coinRewardForAnsweringQuestions={props.coinRewardForAnsweringQuestions}
                        />);
                }
                return questionsArray;

            });

            Promise.all([questionsPromise]).then(() => {

                setQuestionsMergedComponents(questionsArray);
            });
        }
    }

    function renderQuestionsSection() {


        if (questionsMergedComponents && questionsMergedComponents.length) {
            return (questionsMergedComponents);
        }
        else {
            return (<div></div>);
        }
    }


    function submitMoodData(isToSubmitQuestions) {
        setCanShowLoading(true);
        if (props.ignoreMoodSubmit) {
            props.goToHomeFunction();
            return;
        }

        moodService.buildMoodDataAndSubmit(
            isToSubmitQuestions,
            props.questionAnswers,
            props.currentWeekMoods,
            props.goToHomeFunction,
            props.clearMoodState,
            props.clearQuestionData);
    }

    function submitQuestionsAndAwardCoins() {
        let flatAnswersToQuestions = props.questionAnswers.flat();

        setCanShowLoading(true);
        submitQuestions(flatAnswersToQuestions)
            .then(() => {
                coinService.giveCoinsToUserFromQuestionAnswered(flatAnswersToQuestions, null)
                    .then(response => {
                        props.goToHomeFunction();
                        if (props.updateCallback) {
                            props.updateCallback(props.questionAnswers);
                        }
                        return response;

                    }).then(coinTransaction => {

                        // Here we check if the user has received coins
                        // If he did, it means that he submited a new mood and will be shown a modal
                        if (coinTransaction != null && coinTransaction.quantity > 0) {
                            
                            var subTitleText = props.translateFunc(
                                "HAPPYCOIN_REWARD",
                                { count: coinTransaction.quantity })

                            modalUtils.displayModalImageSimpleConfirm(
                                <ModalsHeaderDailyObjective />,
                                props.translateFunc("GLOBAL_THANKS_FOR_PARTICIPATING"), //modalTitle
                                props.translateFunc("PARTICIPATION_THANKS_MSG_EXTENDED"), //modalMessage
                                props.translateFunc("GLOBAL_OK"),// buttonText
                                null, // button function
                                subTitleText); // sub title
                        }

                    });
            });
        props.clearQuestionData();
    }

    return (
        <div className="MoodQt MoodModal">
            <Carousel
                pagination={props.showIndexPagination}
                itemsToShow={1}
                className="MedalsHighlights">
                {renderQuestionsSection()}
            </Carousel>

            {canShowLoading ? <LoadingComponent /> : null}

            <DialogActions className="btQuestions">
                {props.showNotNowButton
                    ? <Button color="primary"
                        onClick={() => { submitMoodData(false); }}>
                        {props.translateFunc("GLOBAL_NOT_NOW")}
                    </Button>
                    : null}


                <Button
                    color="primary"
                    //autoFocus
                    //disabled={isConfirmButtonDisabled}
                    onClick={() => {
                        props.isComingFromHomeScreen
                            ? submitQuestionsAndAwardCoins()
                            : submitMoodData(true);
                    }}>
                    {props.translateFunc("GLOBAL_SUBMIT")}
                </Button>

            </DialogActions>


        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        currentWeekMoods: state.moodDetailReducer.currentWeekMoods,
        questionAnswers: state.questionsReducer.questionAnswers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        insertOrUpdateQuestion: (questionData) => dispatch(
            questionActions.insertOrUpdateQuestion(questionData)),
        clearMoodState: () => dispatch(
            moodDetailActions.clearMoodState()),
        clearQuestionData: () => dispatch(
            questionActions.clearQuestionData())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCarouselContainer);