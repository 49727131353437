import React from 'react';
import { ReactComponent as MoodIconGood } from '../../images/Icons/moodStroke4.svg';

export default function HistoryIcon(props) {

  return (
      <div className="HistoryIcon">
        {props.cardIcon}
      </div>
  );
}
