// Contains the mood calls
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import * as coinService from './coinService';
import * as modalUtils from '../utils/modalUtils';
import { fetchWithBearerToken, stringFormat } from '../utils/utils';
import ModalsHeaderDailyObjective from '../containers/_Global/ModalsHeaderDailyObjective';
import i18n from "i18next";


export function getMoodAverage(beginDate, endDate, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(
      process.env.REACT_APP_URL_API_MOOD_AVERAGE, beginDate, endDate),
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getMoodsForCurrentWeek(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_MOOD_GETMOODSFORCURRENTWEEK,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function hasUserFilledMoodToday(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_MOOD_HAS_USER_FILLED_MOOD_TODAY,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getUnfilledMoodsForCurrentWeek(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_MOOD_GET_UNFILLED_MOODS_FOR_CURRENT_WEEK,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}


export function getMoodsSubmissionLastDaysAmount(lastDaysAmount, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_MOOD_GET_SUBMISSIONS_LAST_DAYS, lastDaysAmount),
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getLastWeekAmountMoodAverage(numberOfWeeksToGet, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_MOOD_GET_LAST_WEEKS_AVERAGE, numberOfWeeksToGet),
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}




export function putSubmitMoodData(moodData) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_MOOD_SUBMITMOODS,
    "PUT",
    moodData);
}

export function buildMoodDataAndSubmit(
  isToSubmitQuestions,
  questionAnswers,
  currentWeekMoods,
  goToHomeFunction,
  clearMoodState,
  clearQuestionData) {

  if (questionAnswers != null) {
    questionAnswers = questionAnswers.flat();
  }

  let moodQuestionAnswerData = { moodData: [], questionAnswerEntries: [] };
  moodQuestionAnswerData.questionAnswerEntries = isToSubmitQuestions
    ? questionAnswers
    : null;

  // Build Json to match DTO format
  currentWeekMoods.map(element => {
    if (element.mood.id !== null) {

      let influenceEntries;
      influenceEntries = element.influenceEntries.map(inf => ({
        id: inf.id,
        moodId: inf.moodId,
        influenceId: inf.influenceId,
        rating: inf.rating,
        commentText: inf.commentText
      }));

      moodQuestionAnswerData.moodData.push(
        {
          id: element.mood.id,
          selectedMoodRating: element.mood.selectedMoodRating,
          diaryDate: element.mood.diaryDate,
          moodCommentText: element.mood.moodCommentData != null ? element.mood.moodCommentData.textValue : null,
          isCommentAnonymous: element.mood.moodCommentData != null ? element.mood.moodCommentData.isCommentAnonymous : false,
          influenceEntries: influenceEntries,
          workplace: element.mood.workPlace
        });
    }
  });



  trackPromise(putSubmitMoodData(moodQuestionAnswerData)
    .then(() => {

      coinService.giveCoinsToUserFromMoodFill(isToSubmitQuestions ? questionAnswers : [])
        .then(response => {
          goToHomeFunction();
          clearMoodState();
          return response;

        }).then(coinTransaction => {

          // Here we check if the user has received coins
          // If he did, it means that he submited a new mood and will be shown a modal
          if (coinTransaction != null && coinTransaction.quantity > 0) {

            // var subTitleText = coinTransaction.quantity === 1
            //   ? "Ganhaste " + 1 + " Happycoin"
            //   : "Ganhaste " + coinTransaction.quantity + " Happycoins";

            var subTitleText = i18n.t(
              "HAPPYCOIN_REWARD",
              { count: coinTransaction.quantity })


            modalUtils.displayModalImageSimpleConfirm(
              <ModalsHeaderDailyObjective />,
              i18n.t("GLOBAL_THANKS_FOR_PARTICIPATING"), //modalTitle
              i18n.t("PARTICIPATION_THANKS_MSG_EXTENDED"), //modalMessage              
              i18n.t("GLOBAL_OK"),// buttonText
              null, // button function
              subTitleText); // sub title
          }
          clearMoodState();
          if (clearQuestionData != null) {
            clearQuestionData();
          }
        });
    }));
}
