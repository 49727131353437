import React, { useEffect } from 'react';
import MedalsUserRowElement from '../../components/Medals/MedalsUserRowElement';
import * as userService from '../../api/userService';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as medalActions from '../../redux/actions/medalActions';
import SearchBar from '../../components/_Global/SearchBar';
import { removeDiacritics } from '../../utils/utils';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import InfiniteScroll from 'react-infinite-scroller';

function MedalsUsersListContainer(props) {

    const history = useHistory();

    const [userRowElements, setUserRowElements] = React.useState();
    const [searchUserRowElements, setSearchUserRowElements] = React.useState();

    const [canUpdateListFromPreviousSearchValue, setCanUpdateListFromPreviousSearchValue] = React.useState(true);

    // The total user count will be used to see if we reached the end of the list
    const [totalUserCount, setTotalUserCount] = React.useState(0);
    const [infiniteScrollHasMoreItems, setInfiniteScrollHasMoreItems] = React.useState(true);

    useEffect(() => {

        if (!canUpdateListFromPreviousSearchValue) {
            return;
        }

        if (userRowElements == null || userRowElements.length === 0) {
            return;
        }

        if (totalUserCount === null || totalUserCount === 0) {
            userService.getTotalUserCount(setTotalUserCount)
                .then(count => {
                    setTotalUserCount(count);
                });
        }

        // If we came back from another screen and we still have search value
        // we make filter by that value and disable that search to avoid an infinite loop
        if (props.medalListSearchValue != null && props.medalListSearchValue !== "") {
            getUsersBySearch(props.medalListSearchValue);
            setCanUpdateListFromPreviousSearchValue(false);
        }

    }, [userRowElements]);

    useEffect(() => {
        if (searchUserRowElements != null && searchUserRowElements.length) {
            setInfiniteScrollHasMoreItems(false);
        } else {
            if (userRowElements != null) {
                totalUserCount == userRowElements.length
                    ? setInfiniteScrollHasMoreItems(false)
                    : searchUserRowElements == null || searchUserRowElements.length == 0
                        ? setInfiniteScrollHasMoreItems(true)
                        : setInfiniteScrollHasMoreItems(false);
            }
        }

    }, [userRowElements, searchUserRowElements])

    function getUsersBySearch(searchValue) {

        props.setMedalListSearchValue(searchValue);

        if (searchValue == null || searchValue === "" || !searchValue.length
            || searchValue.length <= 1) {
            setSearchUserRowElements([]);
            return;
        }

        setInfiniteScrollHasMoreItems(false);

        userService.getAllUsersForCompanyWithSearchTerm(searchValue, mapSearchResults)
            .then(searchResults => {
                mapSearchResults(searchResults);
            });
    }

    function mapSearchResults(searchResults) {
        let searchElementsMapped = [];
        searchResults.map(user => {
            searchElementsMapped.push(
                <MedalsUserRowElement
                    searchNameArray={user.name.toLowerCase().split(" ")}
                    key={user.id}
                    profilePictureFullPath={user.profilePictureFullPath}
                    profilePictureThumbnailPath={user.profilePictureThumbnailPath}
                    isDisabled={props.currentUser.id === user.id}
                    userName={user.name}
                    currentAssociatedCompany={user.currentAssociatedCompany}
                    totalUserCount={totalUserCount}
                    userId={user.id}
                    userRanking={user.medalRankPosition}
                    isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser}
                    receivedMedalCount={user.receivedMedalCount}
                    assignedMedalCount={user.assignedMedalCount}
                    handleArrowDetailClick={handleArrowDetailClick}
                    hasUserGivenAppraisalsThisWeek={props.currentUser.hasUserGivenAppraisalsThisWeek} />);
        });

        setSearchUserRowElements(searchElementsMapped);
        setCanUpdateListFromPreviousSearchValue(false);
    }

    async function loadUsers(pageToLoad) {
        try {
            if (props.isSelected === true) {

                userService.getAllUsersForCompanyByPage(
                    pageToLoad,
                    mapUserListElements)
                    .then(userList => {
                        mapUserListElements(userList);
                    });
            }
            else {
                return (<div></div>);
            }
        } catch (e) {
            history.push("/");
            console.error("ERROR ORCCURRED - REDIRECTING TO HOMEPAGE: " + e);
        }
    }

    function mapUserListElements(userList) {

        let elements = userRowElements != null ? [...userRowElements] : [];

        userList.map(user => {
            elements.push(
                <MedalsUserRowElement
                    lastUserIdSelected={props.selectedUserMedalDetailId} // this id is used to scroll to the previous selected user
                    searchNameArray={user.name.toLowerCase().split(" ")}
                    key={user.id}
                    profilePictureFullPath={user.profilePictureFullPath}
                    profilePictureThumbnailPath={user.profilePictureThumbnailPath}
                    isDisabled={props.currentUser.id === user.id}
                    userName={user.name}
                    currentAssociatedCompany={user.currentAssociatedCompany}
                    totalUserCount={totalUserCount}
                    userId={user.id}
                    userRanking={user.medalRankPosition}
                    isMedalDetailComingFromLocalUser={props.isMedalDetailComingFromLocalUser}
                    receivedMedalCount={user.receivedMedalCount}
                    assignedMedalCount={user.assignedMedalCount}
                    handleArrowDetailClick={handleArrowDetailClick}
                    hasUserGivenAppraisalsThisWeek={props.currentUser.hasUserGivenAppraisalsThisWeek} />);
        });

        setUserRowElements(elements);

        if (props.medalListSearchValue) {
            setCanUpdateListFromPreviousSearchValue(true);
            getUsersBySearch(props.medalListSearchValue);
        }

    }

    function renderUserRowElements() {
        if (searchUserRowElements != null && searchUserRowElements.length) {
            return searchUserRowElements;
        }

        if (userRowElements != null && userRowElements.length) {
            return userRowElements;
        } else { return (<div></div>) }
    }

    function handleArrowDetailClick(userDetails) {
        props.onMedalUserDetailClick(userDetails);
        if (props.isMedalDetailComingFromLocalUser) {
            history.push(process.env.REACT_APP_ROUTING_MEDALS_ASSIGN);
        }
        else {
            props.setMedalDetailComingFromThisUser(false);
            history.push(process.env.REACT_APP_ROUTING_MEDALS_DETAIL);
        }
    }

    function infiniteScrollLoadMore(pageToLoad) {
        loadUsers(pageToLoad);
    }

    return (
        <div>
            <SearchBar
                translateFunc={props.translateFunc}
                i18n={props.i18n}
                previousSearchValue={props.medalListSearchValue}
                onChangeSearchBar={getUsersBySearch} />

            <div className="MedalsList">

                <InfiniteScroll
                    pageStart={0}
                    loadMore={infiniteScrollLoadMore}
                    hasMore={infiniteScrollHasMoreItems}
                    loader={<LoadingComponent key={0} />}
                    useWindow={false}
                >
                    {renderUserRowElements()}


                </InfiniteScroll>

            </div>

        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.currentUser,
        isMedalDetailComingFromLocalUser: state.medalsReducer.isMedalDetailComingFromLocalUser,
        medalListSearchValue: state.medalsReducer.medalListSearchValue,
        selectedUserMedalDetailId: state.medalsReducer.selectedUserMedalDetail.id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMedalUserDetailClick: (userDetails) => dispatch(
            medalActions.onMedalUserDetailSelected(userDetails)
        ),
        setMedalDetailComingFromThisUser: (isComingFromUser) => dispatch(
            medalActions.setMedalDetailComingFromThisUser(isComingFromUser)
        ),
        setMedalListSearchValue: (value) => dispatch(
            medalActions.setMedalListSearchValue(value)
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedalsUsersListContainer)