import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function PersonalInfoRelations(props) {

  return (
    <div className="PersonalInfoRelations">
      {props.leftImage}
      <ChevronRightIcon />
      {props.rightImage}
    </div >
  );
}