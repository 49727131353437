import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import HomeContainer from './containers/Home/HomeContainer';
import MoodContainer from './containers/Mood/MoodContainter';
import MoodDetailContainer from './containers/Mood/MoodDetailContainer';
import WallpaperContainer from "./containers/_Global/WallpaperContainer";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './css/globalStylesMUI'
import './css/globalStyles.css';
import { ReactComponent as ModalIcon } from './images/Icons/quiz.svg';
import MyAuthCall from './containers/MyAuthCall'
import MedalsContainer from './containers/Medals/MedalsContainer';
import MedalsDetailContainer from './containers/Medals/MedalsDetailContainer';
import MedalsAssignContainer from './containers/Medals/MedalsAssignContainer';
import MedalsCategoryDetailContainer from './containers/Medals/MedalsCategoryDetailContainer';
import UserChartsContainer from './containers/UserCharts/UserChartsContainer';
import HistoryMedalsDetail from './containers/UserCharts/HistoryMedalsDetail';
import HistoryMoodDetail from './containers/UserCharts/HistoryMoodDetail';
import HistoryCoinsDetail from './containers/UserCharts/HistoryCoinsDetail';
import AchievementsContainer from './containers/Home/AchievementsContainer';
import PersonalDataContainer from './containers/Home/PersonalDataContainer';
import UserPhotoEditorContainer from './containers/Home/UserPhotoEditorContainer';
import HelpContainer from './containers/Home/HelpContainer';
import ConfigurationsContainer from './containers/Home/ConfigurationsContainer';
import StoreContainer from './containers/Home/StoreContainer';
import AboutContainer from './containers/Home/AboutContainer';
import NewsContainer from './containers/News/NewsContainer';
import NewsDetail from './containers/News/NewsDetail';
import CoinsContainer from './containers/Coins/CoinsContainer';
import PWAPrompt from 'react-ios-pwa-prompt'
import Modals from "./containers/_Global/Modals";
import { usePromiseTracker } from "react-promise-tracker";
import Loading from './components/_Global/Loading';
import { connect } from 'react-redux';
import * as notificationActions from './redux/actions/notificationActions';
import { loginOrCreateUser, savePlayerId } from './api/userService';
import * as userActions from './redux/actions/userActions';
import HappyShopItemDetailContainer from './containers/Coins/HappyShopItemDetailContainer';
import CoinTransactionHistory from './containers/Coins/CoinTransactionHistory';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _DevReportsContainer from './containers/_Global/_DevReportsContainer';

const ErrorComponent = () => (
  <div className="tempContent">
    <div>
      <span><ModalIcon /></span>
      <div className="title">Ooops...</div>
      <div className="text">A página que tentou aceder não existe. </div>
      <div className="soon"><a href="/">Voltar à primeira página</a></div>
    </div>
  </div>
);

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress &&
    <div>
      <Loading isToUseWhiteLoader={false} />
    </div>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: false,
      hasAcceptedTermsOfUse: false
    }

  }


  componentDidMount() {

    if (!this.state.isUserLoggedIn) {

      loginOrCreateUser(this.props.name, this.props.email).then(user => {

        this.props.setCurrentUser(user);
        this.setState({
          isUserLoggedIn: true,
          hasAcceptedTermsOfUse: user.hasAcceptedTermsOfUse
        });

        this.initOneSignal(user);
      })
    }
  }

  initOneSignal(user) {
    try {


      var OneSignal = window.OneSignal || [];

      OneSignal.push(function () {
        OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          //autoRegister: false,
          safari_web_id: "web.onesignal.auto.336e9d4c-289f-452d-8560-a451da3453b9",
          notifyButton: {
            enable: true,
          }
        });

        OneSignal.getUserId().then(playerId => {
          // Save the id to the respective user
          if (playerId) {
            savePlayerId(playerId, user.id);
          }
        });

        //https://documentation.onesignal.com/docs/web-push-sdk#user-ids
        OneSignal.on('subscriptionChange', function (isSubscribed) {
          if (isSubscribed) {
            OneSignal.getUserId().then(playerId => {
              // Save the id to the respective user
              if (playerId) {
                savePlayerId(playerId, user.id);
              }
            });
          }
        });
      });
    } catch (error) {
      console.log('OneSignal Worker registration failed', error);
    }
  }

  setAcceptedTermsOfUse = () => {
    this.setState({
      hasAcceptedTermsOfUse: true
    });
  }

  renderTermsOfUseHomeScreen() {
    const { t, i18n } = this.props;
    return (
      <MuiThemeProvider theme={theme} >

        <div id="idModalSection">
        </div>

        <WallpaperContainer >
          <LoadingIndicator />
          <div className="appWidth">
            <Switch>

              <Route path="/" >
                <HomeContainer
                  translateFunc={t}
                  i18n={i18n}
                  isToShowTermsOfUse={true}
                  logoutFunction={this.props.logoutFunction}
                  acceptTermsFunction={this.setAcceptedTermsOfUse} />
              </Route>
            </Switch>
          </div>
        </WallpaperContainer>
      </MuiThemeProvider>
    );
  }

  render() {

    if (!this.state.isUserLoggedIn) {
      return (<Loading isToUseWhiteLoader={true} />);
    }
    else if (!this.state.hasAcceptedTermsOfUse) {
      return (
        this.renderTermsOfUseHomeScreen()
      );
    }
    else {
      const { t, i18n } = this.props;
      return (
        <MuiThemeProvider theme={theme} >

          <PWAPrompt
            copyTitle={t("APP_ADD_HOME_SCREEN_LABEL")}
            copyBody={t("APP_ADD_HOME_SCREEN_DESCRIPTION")} />

          {/* The section where the modals will be disaplyed */}

          <div id="idModalSection">
          </div>

          <WallpaperContainer >
            <LoadingIndicator />
            <div className="appWidth">
              <Switch>

                <Route exact path="/" >
                  <HomeContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MOOD} >
                  <MoodContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MOOD_DETAIL_MOODRATING_PARAM} >
                  <MoodDetailContainer translateFunc={t} i18n={i18n} />
                </Route>
                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS} >
                  <MedalsContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_HIGHLIGHTS} >
                  <MedalsContainer translateFunc={t} i18n={i18n} isToDisplayHighlights={true} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_HIGHLIGHTS_CATEGORY} >
                  {withRouter(MedalsContainer)}
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_DETAIL} >
                  <MedalsDetailContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_DETAIL_WITH_ID} >
                  {withRouter(MedalsDetailContainer)}

                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_ASSIGN} >
                  <MedalsAssignContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_ASSIGN_WITH_ID} >
                  {withRouter(MedalsAssignContainer)}
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MEDALS_CATEGORYDETAIL} >
                  <MedalsCategoryDetailContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path="/myauth" >
                  <MyAuthCall />
                </Route>

                <Route exact path="/modals" >
                  <Modals />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_USER_CHARTS} >
                  <UserChartsContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_HISTORY_MEDALS_DETAIL} >
                  <HistoryMedalsDetail />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_HISTORY_MOOD_DETAIL} >
                  <HistoryMoodDetail />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_HISTORY_COINS_DETAIL} >
                  <HistoryCoinsDetail />
                </Route>

                {/* MENU ITEMS */}

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_ACHIEVEMENTS} >
                  <AchievementsContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_PERSONAL_DATA} >
                  <PersonalDataContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_HELP} >
                  <HelpContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_CONFIGURATIONS} >
                  <ConfigurationsContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_STORE} >
                  <StoreContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_ABOUT} >
                  <AboutContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_MENU_NEWS} >
                  <NewsContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_NEWS_DETAIL} >
                  <NewsDetail translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_PAGE_COINS} >
                  <CoinsContainer translateFunc={t} i18n={i18n} />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_PAGE_HAPPY_SHOP_ITEM_DETAIL} >
                  <HappyShopItemDetailContainer />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_PAGE_HAPPY_SHOP_ITEM_DETAIL_WITH_ID} >
                  {withRouter(HappyShopItemDetailContainer)}
                </Route>

                <Route path={process.env.REACT_APP_ROUTING_NEWS_DETAIL_WITH_ID}>
                  {withRouter(NewsDetail)}
                </Route>

                {/* TODO JS: remove this, is for debug demo only */}
                <Route exact path={"/temp_userPhoto_backoffice_editor"} >
                  <UserPhotoEditorContainer />
                </Route>

                {/* DEV_ONLY*/}
                <Route exact path={"/dev/reportsData"} >
                  <_DevReportsContainer />
                </Route>

                <Route exact path={process.env.REACT_APP_ROUTING_PAGE_COINS_TRANSACTION_HISTORY} >
                  <CoinTransactionHistory translateFunc={t} i18n={i18n} />
                </Route>


                <Route component={ErrorComponent} />

              </Switch>
            </div>
          </WallpaperContainer>
        </MuiThemeProvider>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    lastMoodNotificationDate: state.notificationReducer.lastMoodNotificationDate,
    hasDisplayedMoodNotification: state.notificationReducer.hasDisplayedMoodNotification
  };
}


const mapDispatchToProps = dispatch => {

  return {
    setLastMoodNotificationDate: (date) => dispatch(
      notificationActions.setLastMoodNotificationDate(date)),

    setHasDisplayedMoodNotificationDate: (value) => dispatch(
      notificationActions.setHasDisplayedMoodNotificationDate(value)),

    setCurrentUser: (userDetails) => dispatch(
      userActions.setCurrentUser(userDetails)
    )
  };
};


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(App);