import React, { useEffect, useState } from 'react';
import HistoryHeader from '../../components/History/HistoryHeader';

import UserChartCardMood from './UserChartCardMood';
import UserChartCardMedals from './UserChartCardMedals';
import UserChartCardCoins from './UserChartCardCoins';
import * as diaryService from '../../api/diaryService';

export default function UserChartsContainer(props) {

  const [currentDayFormatted, setCurrentDayFormatted] = useState();

  let formatOptions = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };

  useEffect(() => {
    diaryService.getCurrentDay()
      .then(day => {
        var date = new Date(day);
        setCurrentDayFormatted(date.toLocaleDateString(props.i18n.language, formatOptions));
      });
  }, [])

  return (
    <div className="History">
      <HistoryHeader />
      <h1 className="MyDate">{currentDayFormatted}</h1>
      <UserChartCardMood translateFunc={props.translateFunc} i18n={props.i18n} />
      <UserChartCardMedals translateFunc={props.translateFunc} i18n={props.i18n} />
      <UserChartCardCoins translateFunc={props.translateFunc} i18n={props.i18n} />
    </div>
  );
}
