import React from 'react';
import CoinsCampaignParticipateSwitch from './CoinsCampaignParticipateSwitch';
import { useHistory } from 'react-router-dom';

export default function CoinsCampaignText(props) {
    const history = useHistory();

    function goToNews() {
        if (props.news && props.news.id) {
            history.push(process.env.REACT_APP_ROUTING_NEWS_DETAIL + '/' + props.news.id)
        }
    }

    function renderNewsLink() {

        if (props.news && props.news.id) {

            return (
                <div> <a href="#" onClick={goToNews}>{props.translateFunc("CAMPAIGN_CLICK_LEARN_MORE")}</a></div>);
        }
        else {
            return null;
        }
    }

    function switchToggleCallback(value) {
        props.switchToggleCallback(value);
    }

    return (
        <div className="CampaignText">
            {/* <div className="title">{props.title}</div> */}
            <div className="txt">{props.campaignDescription}</div>
            {renderNewsLink()}

            <CoinsCampaignParticipateSwitch
                i18n={props.i18n}
                translateFunc={props.translateFunc}
                isChecked={props.isParticipatingInCampaign}
                switchToggleCallback={switchToggleCallback} />
        </div>
    );
}