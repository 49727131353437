
import { createStore, combineReducers } from 'redux';
import moodDetailReducer from '../../redux/reducers/moodDetailReducer';
import medalsReducer from '../../redux/reducers/medalsReducer';
import userReducer from '../../redux/reducers/userReducer';
import newsReducer from '../../redux/reducers/newsReducer';
import questionsReducer from '../../redux/reducers/questionsReducer';
import notificationReducer from '../../redux/reducers/notificationReducer';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const allReducers = combineReducers({
    moodDetailReducer: moodDetailReducer,
    medalsReducer: medalsReducer,
    userReducer: userReducer,
    notificationReducer: notificationReducer,
    newsReducer: newsReducer,
    questionsReducer: questionsReducer
});

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
};


const pReducer = persistReducer(persistConfig, allReducers);

export const store = createStore(
    pReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


