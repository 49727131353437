import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MedalsAssignHeaderContainer from '../../containers/Medals/MedalsAssignHeaderContainer';
import MedalsAssignListContainer from './MedalsAssignListContainer';

export default function MedalsAssignContainer(props) {

    // The id comming from the routing, if exists.

    const [t, i18n] = useTranslation();
    let translateFunc = t;
    let i18nEngine = i18n;

    useEffect(() => {        
        if (props.translateFunc == null || props.i18n == null) {
            translateFunc = t;
            i18nEngine = i18n;
        } else {
            translateFunc = props.translateFunc;
            i18nEngine = props.i18n;
        }
    }, [])

    return (
        <div>
            <MedalsAssignHeaderContainer
                routingUserId={props.match != null
                    ? props.match.params.userId
                    : null} translateFunc={translateFunc} i18n={i18nEngine} />
            <MedalsAssignListContainer routingUserId={props.match != null ? props.match.params.userId : null} />
        </div>
    );
}