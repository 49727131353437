import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpIcon from '@material-ui/icons/Help';
import { showTutorialPopup } from '../../utils/modalUtils.js';
import TutorialCard from '../Tutorials/TutorialCard';
import tutorialImageStepSelf1 from '../../images/tutorialImages/0501.png';
import tutorialImageStepSelf2 from '../../images/tutorialImages/0502.png';
import tutorialImageStep1 from '../../images/tutorialImages/0301.png';
import tutorialImageStep2 from '../../images/tutorialImages/0302.png';
import tutorialImageStep3 from '../../images/tutorialImages/0303.png';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function MedalsDetailHeader(props) {
    const classes = useStyles();


    let tutorialCardLabel = props.isMedalDetailComingFromLocalUser
        ? props.translateFunc("TUTORIAL_USER_PROFILE_SELF_LABEL")
        : props.translateFunc("TUTORIAL_USER_PROFILE_LABEL");

    let tutorialCardsContent = [];

    if (props.isMedalDetailComingFromLocalUser) {
        tutorialCardsContent.push(
            <div key={1}>
                <TutorialCard
                    imageComponentToRender={<img src={tutorialImageStepSelf1} />}
                    title={""}
                    subTitle={""}
                    modalMessage={props.translateFunc("TUTORIAL_USER_PROFILE_SELF_1")} />
            </div>);

        tutorialCardsContent.push(
            <div key={2}>
                <TutorialCard
                    imageComponentToRender={<img src={tutorialImageStepSelf2} />}
                    title={""}
                    subTitle={""}
                    modalMessage={props.translateFunc("TUTORIAL_USER_PROFILE_SELF_2")} />
            </div>);
    } else {

        tutorialCardsContent.push(
            <div key={1}>
                <TutorialCard
                    imageComponentToRender={<img src={tutorialImageStep1} />}
                    title={""}
                    subTitle={""}
                    modalMessage={props.translateFunc("TUTORIAL_USER_PROFILE_1")} />
            </div>);

        tutorialCardsContent.push(
            <div key={2}>
                <TutorialCard
                    imageComponentToRender={<img src={tutorialImageStep2} />}
                    title={""}
                    subTitle={""}
                    modalMessage={props.translateFunc("TUTORIAL_USER_PROFILE_2")} />
            </div>);

        tutorialCardsContent.push(
            <div key={3}>
                <TutorialCard
                    imageComponentToRender={<img src={tutorialImageStep3} />}
                    title={""}
                    subTitle={""}
                    modalMessage={props.translateFunc("TUTORIAL_USER_PROFILE_3")} />
            </div>);
    }

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={props.onReturnClickFunction}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography className="titleHeader" variant="h6" noWrap>
                        {props.headerTitle}
                    </Typography>
                    <div className={classes.grow} />

                    <IconButton aria-label="info here" color="inherit" onClick={() => {
                        showTutorialPopup(tutorialCardsContent, tutorialCardLabel, props.i18n, props.translateFunc);
                    }}>
                        <HelpIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
        </div >
    );
}
