import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import Badge from '@material-ui/core/Badge';
import HelpIcon from '@material-ui/icons/Help';
import { showTutorialPopup } from '../../utils/modalUtils';
import TutorialCard from '../Tutorials/TutorialCard';
import tutorialImageStep1 from '../../images/tutorialImages/0401.png'
import tutorialImageStep2 from '../../images/tutorialImages/0402.png'
import tutorialImageStep3 from '../../images/tutorialImages/0403.png'

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function MedalsAssignHeader(props) {
    const classes = useStyles();

    let tutorialCardsContent = [];

    tutorialCardsContent.push(
        <div key={1}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep1} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_APPRAISAL_ASSIGN_1")} />
        </div>);

    tutorialCardsContent.push(
        <div key={2}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep2} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_APPRAISAL_ASSIGN_2")} />
        </div>);

    tutorialCardsContent.push(
        <div key={3}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep3} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_APPRAISAL_ASSIGN_3")} />
        </div>);

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        onClick={props.onClickReturnFunction}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography className="titleHeader" variant="h6" noWrap>
                        Atribuir Mérito
                    </Typography>
                    <div className={classes.grow} />
                    <IconButton disabled={!props.hasPendingChanges} aria-label="Cancelar" color="inherit" onClick={props.onClickDeleteFunction}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton disabled={!props.hasPendingChanges} aria-label="OK" color="inherit" onClick={props.onClickSubmit}>
                        <Badge color="secondary" invisible={!props.hasPendingChanges} variant={"dot"} overlap="circular">
                            <CheckIcon />
                        </Badge>
                    </IconButton>

                    <IconButton aria-label="info here" color="inherit" onClick={() => {
                        showTutorialPopup(tutorialCardsContent, props.translateFunc("TUTORIAL_APPRAISAL_ASSIGN_LABEL"), props.i18n, props.translateFunc);
                    }}>
                        <HelpIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
        </div>
    );
}
