import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import * as systemService from './api/systemService';

const languages = ['en', 'pt'];

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    lng: systemService.getLanguageCookieValue(),
    fallbackLng: "pt",
    supportedLngs: languages,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;