import React from 'react';

import { authProvider } from '../auth/authProvider';


let token = null;

// (async () => {
//     token = token ?? await authProvider.getAccessToken();
// })();

const request = url => {

    return fetch(
        url,
        {
            headers: {
                Authorization: 'Bearer ' + token.accessToken,
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.json());
};

export default class MyAuthCall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: 'no call was made yet.'
        }
    }

    async componentDidMount() {
        token = token ?? await authProvider.getAccessToken();
        console.log(token);


    }

    updateUI(txt) {
        this.setState(
            {
                result: txt
            }
        );
    }

    displayNotification() {
        if (Notification.permission === 'granted') {
            navigator.serviceWorker.getRegistration().then(function (reg) {
                reg.showNotification('Hello world!');
            });
        }
    }

    render() {
        return (
            <div>

                <button onClick={() =>
                    request('https://localhost:55888/Diary/GetCurrentDay')
                        .then(r => this.updateUI(r))}>Call /Diary/GetCurrentDay</button>
                <div>{this.state.result}</div>

                <button onClick={() => {
                    this.displayNotification();
                }
                }> displayNotification </button>

                
            </div>
        );

    }

}