
export const navIconStyle = {
  center: {
    textAlign: 'center',
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: '0',
    cursor: 'pointer'
  },
  blueColor: {
    stroke: '#00A6E4',
    fill: '#00A6E4'
  },
  greenColor: {
    stroke: '#9FC540',
    fill: '#9FC540'
  },
  orangeColor: {
    stroke: '#F5B827',
    fill: '#F5B827'
  },
  whiteColor: {
    stroke: '#fff',
    fill: '#fff'
  },
  whiteColorTransparency: {
    stroke: 'rgba(255, 255, 255, 0.3)',
    fill: 'rgba(255, 255, 255, 0.3)'
  },
  whiteColorTransparencyNoStroke: {
    fill: 'rgba(255, 255, 255, 0.3)'
  },
  svgSize: {
    width: '64px',
    height: 'auto',
  },
  svgSizeList: {
    width: '40px',
    height: 'auto',
  },
  iconMargin:{
    marginBottom: "10px"
  }
};

export const materialOverride = {
  transparentColor: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
        boxShadow: 'none',
        border: 'none'
  },
  whiteTransparencyColor: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
        boxShadow: 'none',
  },
  noMargin: {
    //margin: '0px',
  },
  noPadding: {
    padding: '0px',
    //backgroundColor: 'red',
  },
  fontListItem: {
    color: 'white',
  },
  
}