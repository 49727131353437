// Contains the mood calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';
import { navIconStyle } from "../css/globalStyles";
import * as oneSignalUtils from '../utils/oneSignalUtils';

export function getAcquiredMedals(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_MEDALS_ACQUIRED_MEDALS,
        "GET", null, cacheCallback)
        .then(response => response);
}

export function getReceivedMedalCountForUserAndCategory(userId, medalCategoryId) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_RECEIVED_MEDAL_COUNT_FOR_USER_AND_CATEGORY, userId, medalCategoryId),
        "GET")
        .then(response => response);
}

export function getAssignedMedalCountForUserAndCategory(userId, medalCategoryId) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_ASSIGNED_MEDAL_COUNT_FOR_USER_AND_CATEGORY, userId, medalCategoryId),
        "GET")
        .then(response => response);
}

export function calculateUserRanking(userPosition, totalUserCount) {
    // Starts at 1% - 25%
    // Then goes 25% - 50% etc...
    // If the current user's position is less than the number of people on the first group
    // we return 1 for first group(25%).
    // If the user's rank is higher we sum look for it in the second group.
    // 1 = First group (25% )
    // 2 = Second group (50% ) .... etc 75%           
    let numberOfPersonsPerGroup;

    if (totalUserCount < parseInt(process.env.REACT_APP_NUM_PEOPLE_COMPANY_COLOR_THRESHOLD)) {
        numberOfPersonsPerGroup = totalUserCount / 4;
    }
    else {
        numberOfPersonsPerGroup = parseInt(process.env.REACT_APP_NUM_PERSONS_COLOR_GROUP_RANKING);
    }

    let currentNumberOfPersonsPerGroup = numberOfPersonsPerGroup;
    for (let i = 0; i < 4; i++) {

        if (userPosition < currentNumberOfPersonsPerGroup) {
            return i;
        }
        currentNumberOfPersonsPerGroup += numberOfPersonsPerGroup;

        // Only x amount of people can have Orange, Green and Blue ranks
        // The others will be white, or transparent if they have no medals.        
        if (currentNumberOfPersonsPerGroup > numberOfPersonsPerGroup * 4) {
            // 3 is the last ranking possible (0,1,2,3)
            return 3;
        }

    }
}

export function getStartColorBasedOnRanking(rankingPosition, userAcquiredMedalCount) {

    if (userAcquiredMedalCount === 0) {
        return navIconStyle.whiteColorTransparency;
    }

    if (rankingPosition === 0) { // <25%

        return navIconStyle.orangeColor;

    } else if (rankingPosition === 1) { // <50%

        return navIconStyle.greenColor;
    }
    else if (rankingPosition === 2) { // <75%

        return navIconStyle.blueColor;
    }
    else if (rankingPosition === 3) { // >75%
        return navIconStyle.whiteColor;
    }
}

export function getAssignedMedalCountFromUserToUser(userIdSender, userIdReceiver) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_TOTAL_ASSIGNED_MEDAL_COUNT_FROM_USER_TO_USER, userIdSender, userIdReceiver),
        "GET")
        .then(response => response);
}


export function getAssignedMedalCountFromUserToUserForCategory(userIdSender, userIdReceiver, categoryId) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_TOTAL_ASSIGNED_MEDAL_COUNT_FROM_USER_TO_USER_FOR_CATEGORY,
            userIdSender,
            userIdReceiver,
            categoryId),
        "GET")
        .then(response => response);
}


export function getUserAppraisalsToSpend(userId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_APPRAISALS_TO_SPEND, userId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function submitAssignedMedalsData(assignedMedalsData) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_MEDALTRANSACTION_SUBMIT_ASSIGNED_MEDALS,
        "PUT",
        assignedMedalsData
    ).then(response => response);
}

export function awardCurrentUserWeeklySystemAppraisals() {
    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_MEDALTRANSACTION_AWARD_CURRENT_USER_WEEKLY_APPRAISALS,
        "POST");
}

export function getUserAcquiredMedalsInCompanyForCurrentWeek() {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_MEDALTRANSACTION_GET_ACQUIRED_MEDALS_CURRENT_WEEK,
        "GET")
        .then(response => response);
}

export function getUserAcquiredMedalsInCompanyForCurrentDay(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_MEDALTRANSACTION_GET_ACQUIRED_MEDALS_CURRENT_DAY,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getReceivedMedalCountForUserAndCategoryForCurrentWeek(medalCategoryId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_RECEIVED_MEDAL_COUNT_FOR_USER_AND_CATEGORY_CURRENT_WEEK, medalCategoryId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getMedalDetailData(currentUserId, selectedUserId, companyId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_GET_MEDAL_DETAIL_DATA, currentUserId, selectedUserId, companyId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getMedalDetailDataWithUserList(currentUserId, selectedUserId, companyId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_GET_DATA_WITH_USER_LIST, currentUserId, selectedUserId, companyId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getAcquiredMedalsLastDays(lastDaysAmount, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_GET_ACQUIRED_LAST_DAYS, lastDaysAmount),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getAcquiredMedalsLastWeeks(numberOfWeeksToGet, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(
            process.env.REACT_APP_URL_API_MEDALS_GET_ACQUIRED_LAST_WEEKS, numberOfWeeksToGet),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function sendNotificationAssignedMedal(medalNotifDto) {

    var headerEN = medalNotifDto.totalMedalsAssigned > 1
        ? `Congratulations, you have received ${medalNotifDto.totalMedalsAssigned} medals!`
        : `Congratulations, you have received ${medalNotifDto.totalMedalsAssigned} medal!`;

    var contentEN = medalNotifDto.totalMedalsAssigned > 1
        ? `'${medalNotifDto.originName} gave you ${medalNotifDto.totalMedalsAssigned} medals! Click here to see your medals.`
        : `${medalNotifDto.originName} gave you ${medalNotifDto.totalMedalsAssigned} medal! Click here to see your medal.`;

    var headerPT = medalNotifDto.totalMedalsAssigned > 1
        ? `Parabéns, recebeste ${medalNotifDto.totalMedalsAssigned} medalhas!`
        : `Parabéns, recebeste ${medalNotifDto.totalMedalsAssigned} medalha!`;

    var contentPT = medalNotifDto.totalMedalsAssigned > 1
        ? `${medalNotifDto.originName} atribuiu-te ${medalNotifDto.totalMedalsAssigned} medalhas! Clica para ver as medalhas que recebeste.`
        : `${medalNotifDto.originName} atribuiu-te ${medalNotifDto.totalMedalsAssigned} medalha! Clica para ver a medalha que recebeste.`



    const baseUrl = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split('/')[1];

    var messageBody = {
        app_id: process.env.REACT_APP_ONESIGNAL_APP_ID,
        headings: { "en": headerEN, "pt": headerPT },
        contents: { "en": contentEN, "pt": contentPT },
        url: baseUrl + "?l=medals", // TODO JS: send to homepage with modal
        chrome_web_badge: "https://happycorpindex.blob.core.windows.net/images/badge_medal.png",
        big_picture: "https://happycorpindex.blob.core.windows.net/images/nova_medalha.png",
        chrome_web_image: "https://happycorpindex.blob.core.windows.net/images/nova_medalha.png",
        chrome_big_picture: "https://happycorpindex.blob.core.windows.net/images/nova_medalha.png",
        ios_attachments: { "id1": "https://happycorpindex.blob.core.windows.net/images/nova_medalha.png" },
        include_player_ids: medalNotifDto.destinyPlayerIds
    };

    oneSignalUtils.sendNotification(messageBody);
}

