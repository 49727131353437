import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import SelectedUserAvatar from '../../images/Icons/NoPhoto.svg';
import { calculateUserNameHashColor } from '../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function ModalsComments(props) {

  const [isOpenned, setIsOpenned] = React.useState(true);
  let userNameHashColor = calculateUserNameHashColor(props.userName ?? "");
  const [textValue, setTextValue] = React.useState(props.preFilledValue ?? "");
  const [isAnonymous, setIsAnonymous] = React.useState(props.isCommentAnonymous ?? false);

  const subtitle = props.subtitle ?? "insira aqui um comentário";

  const handleClose = (isConfirmOperation) => {
    // "isConfirmOperation" is to know if the user clicked OK or CANCEL

    // If the user clicked cancel we dont want to update the comment
    if (isConfirmOperation) {
      props.onModalClosed(textValue, isAnonymous);
    }
    else {
      props.onModalClosed(props.preFilledValue, isAnonymous);
    }

    setIsOpenned(false);
  };

  const handleChange = event => {
    setTextValue(event.target.value);
  };

  function deleteComment() {
    setTextValue("");
    props.onModalClosed("", isAnonymous);
  }

  function renderAvatar() {
    if (props.pictureToDisplay == null) {
      return (
        <Avatar
          className="NoPhotoAvatar avatarComments"
          alt={props.userName}
          src={SelectedUserAvatar}
          style={{
            background:
              'linear-gradient(135deg, hsl(' + userNameHashColor + ', 25%, 80%) 0%, hsl(' + userNameHashColor + ', 30%, 50%) 100%)'
          }} />
      );
    } else {
      return (
        <Avatar
          className="avatarComments"
          alt={props.userName}
          src={props.pictureToDisplay} />
      );
    }
  }

  return (

    <Dialog
      open={isOpenned}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modals"
    >

      {/* MODAL COMMENTS CONTENT */}

      <div className="ModalsComments">
        {renderAvatar()}
      </div>

      {/* MODAL COMMENTS CONTENT */}

      <DialogTitle id="alert-dialog-title">{subtitle}</DialogTitle>
      <DialogContent>
        <TextField
          id="multiline"
          multiline
          rows="6"
          value={textValue}
          onChange={handleChange}
        />
      </DialogContent>


      <FormControlLabel
        checked={isAnonymous}
        control={<Checkbox
          color="primary"
          onClick={e => setIsAnonymous(e.target.checked)}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />}
        label="Comentário anónimo"
        labelPlacement="bottom"
      />

      {/* MODAL COMMENTS ACTION BUTTONS */}
      <DialogActions>
        <Button color="primary" onClick={deleteComment}>
          <DeleteIcon />
        </Button>
        <Button onClick={handleClose.bind(this, false)} color="primary">
          Cancelar
          </Button>
        <Button onClick={handleClose.bind(this, true)} color="primary" autoFocus>
          OK
          </Button>
      </DialogActions>
    </Dialog>
  );
}
