import React, { useEffect } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import { materialOverride } from "../../css/globalStyles.js";
import { showTutorialPopup } from '../../utils/modalUtils.js';
import TutorialCard from '../Tutorials/TutorialCard.jsx';
import tutorialImageStep1 from '../../images/tutorialImages/0101.png'
import tutorialImageStep2 from '../../images/tutorialImages/0102.png'
import tutorialImageStep3 from '../../images/tutorialImages/0103.png'
import tutorialImageStep4 from '../../images/tutorialImages/0104.png'
import tutorialImageStep5 from '../../images/tutorialImages/0105.png'
import { getCoinRewardByCode } from '../../api/coinService.js';


const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        //display: 'none',
        //[theme.breakpoints.up('md')]: {
        //    display: 'flex',
        //},
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function MoodHeader(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [coinRewardMood, setCoinRewardMood] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        getCoinRewardByCode("MOOD_FILL", setCoinRewardMood).then(r => {
            setCoinRewardMood(r);            
        });
    }, [])

    let tutorialCardsContent = [];

    tutorialCardsContent.push(
        <div key={1}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep1} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MOOD_1")} />
        </div>);

    tutorialCardsContent.push(
        <div key={2}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep2} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MOOD_2")} />
        </div>);

    tutorialCardsContent.push(
        <div key={3}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep3} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc('TUTORIAL_MOOD_3', { moodReward: coinRewardMood })} />
        </div>);

    tutorialCardsContent.push(
        <div key={4}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep4} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MOOD_4")} />
        </div>);

    tutorialCardsContent.push(
        <div key={5}>
            <TutorialCard
                imageComponentToRender={<img src={tutorialImageStep5} />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_MOOD_5")} />
        </div>);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="static" style={materialOverride.whiteTransparencyColor}>
                <Toolbar>
                    <IconButton
                        onClick={props.onReturnClick}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography className="titleHeader" variant="h6" noWrap>
                        {props.translateFunc("MOOD_DETAIL_HOW_ARE_YOU")}
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton disabled={!props.hasPendingChanges} onClick={props.onDeleteClick} aria-label="cancel" color="inherit">
                            <DeleteIcon />
                        </IconButton>
                        <IconButton disabled={!props.hasPendingChanges || !props.canSubmit} onClick={props.onSubmitClick} aria-label="confirm" color="inherit">
                            <Badge color="secondary" invisible={props.isBadgeInvisible} variant={"dot"} overlap="circular">
                                <CheckIcon />
                            </Badge>
                        </IconButton>

                        <IconButton aria-label="info here" color="inherit" onClick={() => {
                            showTutorialPopup(tutorialCardsContent, props.translateFunc("TUTORIAL_HOMEPAGE_MOOD_TITLE"), props.i18n, props.translateFunc);
                        }}>
                            <HelpIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}
