import React from 'react';
import MedalsCategoryHeader from '../../components/Medals/MedalsCategoryHeader'

export default function CategoryHeaderContainer(props) {

    return (
        <div>
            <MedalsCategoryHeader />            
        </div>
    );
}