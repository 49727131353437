import React from 'react';

export default function Wallpaper(props) {

    return (
        <div>
            <div className="bgImage altBgImage" style={{ backgroundImage: "url(" + props.imageToRender + ")"}} />
            <div className="bgImageDark bgImageDarkBlur" />
            {props.children}
        </div>
    );
}