// Action names to use in the reducers
//--------------------------------------------------------------------//

// MoodDetail REDUCER
export const UPDATE_MOOD = "UPDATE_MOOD";
export const MOOD_CLEAR_STATE = "MOOD_CLEAR_STATE";
export const SET_CURRENTDAYDATE = "SET_CURRENTDAYDATE";
export const ADD_INFLUENCEENTRY = "ADD_INFLUENCEENTRY";
export const UPDATE_INFLUENCEENTRY = "UPDATE_INFLUENCEENTRY";
export const SET_SELECTEDMOODRATING = "SET_SELECTEDMOODRATING";
export const UPDATEINFLUENCEENTRIES = "UPDATEINFLUENCEENTRIES";
export const SET_SELECTEDDAYOFTHEWEEK = "SET_SELECTEDDAYOFTHEWEEK";
export const SET_SELECTED_INFLUENCECATEGORY = "SET_SELECTED_INFLUENCECATEGORY";
export const UPDATE_INFLUENCE_ENTRY_COMMENT = "UPDATE_INFLUENCE_ENTRY_COMMENT";
export const UPDATE_MOOD_COMMENT = "UPDATE_MOOD_COMMENT";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_WORKPLACE = "UPDATE_WORKPLACE";


// MEDALS REDUCER
export const SET_MEDAL_NAVHISTORYTYPE = "SET_MEDAL_NAVHISTORYTYPE";
export const SET_SELECTED_MEDALUSERDETAIL = "SET_SELECTED_MEDALUSERDETAIL";
export const UPDATE_MEDAL_CATEGORY_ASSIGNED_ROW = "UPDATE_MEDAL_CATEGORY_ASSIGNED_ROW";
export const SET_MEDALDETAIL_COMINGFROMUSER_FLAG = "SET_MEDALDETAIL_COMINGFROMUSER_FLAG";
export const RESET_STATE_MEDAL_CATEGORY_ASSIGNED_ROWS = "RESET_STATE_MEDAL_CATEGORY_ASSIGNED_ROWS";
export const SET_MEDAL_SEARCH_VALUE = "SET_MEDAL_SEARCH_VALUE";
export const SET_MEDAL_HISTORY_BACK_SETTINGS = "SET_MEDAL_HISTORY_BACK_SETTINGS";
export const SET_DIRECT_CLICK_CATEGORY_ID = "SET_DIRECT_CLICK_CATEGORY_ID";



// USER REDUCER
export const SET_CURRENTUSER = "SET_CURRENTUSER";
export const SET_PENDING_CHANGES_FLAG = "SET_PENDING_CHANGES_FLAG";
export const SET_USER_GIVEN_APPRAISALS_THIS_WEEK = "SET_USER_GIVEN_APPRAISALS_THIS_WEEK";
export const SET_USER_AVAILABLE_APPRAISALS_BEFORE_ASSIGN = "SET_USER_AVAILABLE_APPRAISALS_BEFORE_ASSIGN";
export const UPDATE_CURRENT_AVAILABLE_APPRAISALS_TO_SPEND = "UPDATE_CURRENT_AVAILABLE_APPRAISALS_TO_SPEND";
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";

// NEWS
export const SELECT_NEWS = "SELECT_NEWS";


// AAD (user reducer)
export const AAD_LOGIN_SUCCESS = "AAD_LOGIN_SUCCESS";
export const AAD_LOGOUT_SUCCESS = "AAD_LOGOUT_SUCCESS";

// NOTIF REDUCER
export const SET_LAST_MOOD_NOTIFICATION_DATE = "SET_LAST_MOOD_NOTIFICATION_DATE";
export const SET_HAS_DISPLAYED_MOOD_NOTIFICATION = "SET_HAS_DISPLAYED_MOOD_NOTIFICATION";

// QUESTIONS

export const INSERT_UPDATE_QUESTION = "INSERT_UPDATE_QUESTION";
export const CLEAR_QUESTION_DATA = "CLEAR_QUESTION_DATA";
