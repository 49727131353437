import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import MedalsNumber from '../../components/Medals/MedalsNumber';
import MedalsStar from '../../components/Medals/MedalsStar';

export default function CategoryListContainer() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="DetailHistory"
      >
        <ListItem className="noBg">
          <ListItemText secondary="Segunda, 10/02/2020" />
          <MedalsNumber medalCount={10} />
          <MedalsStar medalStyle={1} />
        </ListItem>

        <ListItem button onClick={handleClick} className="detail">
          <ListItemIcon>
            <Avatar alt="Nome 1" src={require("../../images/avatars/2.jpg")} />
            <ChatBubbleIcon className="svgChat" />
          </ListItemIcon>
          <ListItemText primary="Ana Paula" secondary="Responsabilidade, + 2" />
          {open ? <ExpandLess /> : <ExpandMore />}
          <MedalsNumber medalCount={5} />
          <MedalsStar medalStyle={1} />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button>
                <ListItemText primary="Responsabilidade" secondary="10:32" />
                <MedalsNumber medalCount={2} />
                <MedalsStar medalStyle={1} />
                <div className="ListBorderSpacer" />
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin eget libero sed elementum. Nunc vitae mattis justo.
                </Typography>
              </ListItem>
              <ListItem button>
                <ListItemText primary="Responsabilidade" secondary="10:32" />
                <MedalsNumber medalCount={3} />
                <MedalsStar medalStyle={1} />
                <div className="ListBorderSpacer" />
                <Typography className="commentText">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin eget libero sed elementum. Nunc vitae mattis justo.
                </Typography>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>

        <ListItem button className="detail">
          <ListItemIcon>
            <Avatar alt="Nome 1" src={require("../../images/avatars/3.jpg")} />
          </ListItemIcon>
          <ListItemText primary="Ana Paula" secondary="Cometência" />
          <MedalsNumber medalCount={5} />
          <MedalsStar medalStyle={2} />
        </ListItem>

        <ListItem className="noBg">
          <ListItemText secondary="Segunda, 10/02/2020" />
          <MedalsNumber medalCount={8} />
          <MedalsStar medalStyle={1} />
        </ListItem>

        <ListItem button onClick={handleClick} className="detail">
          <ListItemIcon>
            <Avatar alt="Nome 1" src={require("../../images/avatars/2.jpg")} />
            <ChatBubbleIcon className="svgChat" />
          </ListItemIcon>
          <ListItemText primary="Ana Paula" secondary="Responsabilidade, + 2" />
          {open ? <ExpandLess /> : <ExpandMore />}
          <MedalsNumber medalCount={3} />
          <MedalsStar medalStyle={1} />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button>
                <ListItemText primary="Responsabilidade" secondary="10:32" />
                <MedalsNumber medalCount={2} />
                <MedalsStar medalStyle={1} />
                <div className="ListBorderSpacer" />
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin eget libero sed elementum. Nunc vitae mattis justo.
                </Typography>
              </ListItem>
              <ListItem button>
                <ListItemText primary="Responsabilidade" secondary="10:32" />
                <MedalsNumber medalCount={1} />
                <MedalsStar medalStyle={1} />
                <div className="ListBorderSpacer" />
                <Typography className="commentText">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin eget libero sed elementum. Nunc vitae mattis justo.
                </Typography>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>

        <ListItem button className="detail">
          <ListItemIcon>
            <Avatar alt="Nome 1" src={require("../../images/avatars/3.jpg")} />
          </ListItemIcon>
          <ListItemText primary="Ana Paula" secondary="Simpatia, + 1" />
          <MedalsNumber medalCount={5} />
          <MedalsStar medalStyle={2} />
        </ListItem>

      </List>
    </div>

  );
}