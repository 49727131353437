import React from 'react';
import { navIconStyle } from "../../css/globalStyles";
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

export default function NavNumber(props) {

    const styles = {
        margin: {
            margin: "2px"
        },
        padding: {
            padding: "0px 2px"
        },
        typography: {
            fontSize: '46px',
            color: 'white',
            fontWeight: "300",
            fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: '1',
        },
        button: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: '0',
            cursor: 'pointer'
        },
    };

    return (
        <div style={navIconStyle.center}>
            
            <Badge color="secondary" badgeContent={props.badgeContent} style={styles.button} overlap="circular">
                <button style={styles.button} onClick={props.onClickFunction}>
                    <Typography style={styles.typography}>
                        {props.numberToDisplay}
                    </Typography>
                </button>
            </Badge>


        </div>
    );
}