import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function Quote(props) {
    const [isAuthorVisible, setIsAuthorVisible] = React.useState(false);

    function toggleAuthor() {
        setIsAuthorVisible(!isAuthorVisible);
    }

    return (
        <div className="copy">
            <IconButton onClick={toggleAuthor}>
                <InfoOutlinedIcon />
            </IconButton>
            {isAuthorVisible ? <span>{props.author}</span> : null}

        </div>
    );
}