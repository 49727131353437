import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { calculateUserNameHashColor } from '../../utils/utils';
import SelectedUserAvatar from '../../images/Icons/NoPhoto.svg';
import { getSpecificUserDetails } from '../../api/userService';



export default function MedalsHighlightsCategoryBlock(props) {

    function goToUserProfile() {

        getSpecificUserDetails(props.userId).then(details => {

            const userDetails = ({
                userId: details.id,
                userName: details.name,
                currentAssociatedCompany: details.currentAssociatedCompany,
                receivedMedalCount: details.receivedMedalCount,
                assignedMedalCount: details.assignedMedalCount,
                profilePictureFullPath: details.profilePictureFullPath
            });

            props.handleUserProfileClick(userDetails);
        })
    }

    function renderAvatar() {
        if (props.profilePictureFullPath == null) {
            let hashColor = calculateUserNameHashColor(props.name);
            return (
                // If the user has no photo, use the smile icon
                // Same as medal list
                <Avatar
                    className="NoPhotoAvatar"
                    alt={props.name}
                    src={SelectedUserAvatar}
                    style={{
                        background:
                            'linear-gradient(135deg, hsl(' + hashColor + ', 25%, 80%) 0%, hsl(' + hashColor + ', 30%, 50%) 100%)'
                    }} />);
        } else {
            return (
                <Avatar alt={props.name} src={props.profilePictureFullPath} className="Avatar">
                </Avatar>
            );
        }
    }
    return (
        <div className="block" role="button" onClick={goToUserProfile}>

            <div className="badge blueColorBg">{props.receivedMedalCount}</div>
            {renderAvatar()}


            <div className="name">{props.name}</div>
        </div >
    );
}