import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavOptionContainer from "./NavOptionContainer";
import Copy from '../../components/Home/Copy';

const classesPage = makeStyles(theme => ({
    centerElement: {
        textAlign: 'center',
    },
}));

export default function FooterContainer(props) {

    const classes = classesPage();

    return (

        <div className={classes.centerElement}>

            <NavOptionContainer
                translateFunc={props.translateFunc}
                i18n={props.i18n}
                navType={process.env.REACT_APP_NAVTYPE_COINS} />

            <NavOptionContainer
                translateFunc={props.translateFunc}
                i18n={props.i18n}
                navType={process.env.REACT_APP_NAVTYPE_MEDALS} />

            <NavOptionContainer
                translateFunc={props.translateFunc}
                i18n={props.i18n}
                navType={process.env.REACT_APP_NAVTYPE_MOOD} />

            <Copy />

        </div>
    );
}

