import * as actionNames from '../actionNames';


const initialState = {
    lastMoodNotificationDate: null,
    hasDisplayedMoodNotification: false

}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionNames.SET_LAST_MOOD_NOTIFICATION_DATE:
            return {
                ...state,
                lastMoodNotificationDate: action.date
            }

        case actionNames.SET_HAS_DISPLAYED_MOOD_NOTIFICATION:
            return {
                ...state,
                hasDisplayedMoodNotification: action.value
            }


        default:
            return state;

    }

};

export default notificationReducer;